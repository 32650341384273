import React, { useState } from "react";
import "./Edit.css";
import { Flip, toast, ToastContainer } from "react-toastify";
import api from "../../../axiosConfig";
import Buttons from "../../../common-component/Buttons";
interface Props {
  id: string | undefined;
  Name: string;
}

const DeletedCategory: React.FC<Props> = ({ id, Name }) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(true);
  const Deletecategory = () => {
    api.delete(`/app/v1/pos/category/${id}`).then((res) => {
      toast.success("Category deleted successfully", {
        position: "top-right",
        autoClose: 2100,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Flip,
      });
      // setIsModalVisible(false);
      setTimeout(() => {
        window.location.reload();
      }, 100);
    });
  };
  const WButton = {
    Blue: "blue",
    name: "Confirm",
  };
  const PButton = {
    Cancel: "red",
    name: "Cancel",
  };
 
  return (
    <div className="modals">
      <div className="overlays">
        <div className="modal-contents-product">
          <div className="edit-popupscreen">
            <div className="addcategory-edit" style={{ marginTop: "-20px" }}>
              <h1 style={{ marginTop: "20px" }}>Confirm to delete</h1>
              {Name && (
                <p style={{ fontWeight: "bold", marginTop: "15px" }}>
                  Category name : {Name}
                </p>
              )}
              <p style={{ marginTop: "30px" }}>
                Are you sure you want to delete this category?
              </p>
              <p
                style={{
                  marginTop: "20px",
                  backgroundColor: "#d6dbdf",
                  borderRadius: "3px",
                }}
              >
                Note: When you delete the category, the added products are also
                deleted
              </p>
              <div className="btnconfirm-edit">
                <div onClick={() => setIsModalVisible(false)}>
                  <Buttons Buttondata={PButton} />
                </div>
                <div onClick={() => Deletecategory()}>
                  <Buttons Buttondata={WButton} />
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default DeletedCategory;
