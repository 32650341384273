import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Flip, toast, ToastContainer } from 'react-toastify';
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Tooltip } from '@mui/material';
import api from '../../../axiosConfig';
import Buttons from '../../../common-component/Buttons';

// interface Props {
//   id: string;
// }
interface Business {
  _id: { $oid: string };
  organization_name: string;
  email_id: string;
  address_line_1: string;
  business_type: string;
}
const EditBusiness = () => {
  const [business, setBusiness] = useState<Business | null>()
  const [name, setName] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [type, settype] = useState<string>("")
  const [address, setAddress] = useState<string>("")
  const [visible, setvisible] = useState<boolean>(false);

  useEffect(() => {
    api
      .get(`/pos/profile`)
      .then((res) => {
        const businessData: Business = res.data.message;
        setBusiness(businessData);
        console.log('Business updated successfully', res);
        const busname = businessData?.organization_name
        setName(busname)
        setEmail(businessData?.email_id)
        setAddress(businessData?.address_line_1)
        settype(businessData?.organization_name)
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (
      business?.organization_name === name &&
      business?.email_id === email &&
      business?.address_line_1 === address
    ) {
      setvisible(false);
    } else {
      if (
        name &&
        email &&
        address
      ) {
        setvisible(true);
      } else {
        setvisible(false);
      }
    }
  }, [address, business?.address_line_1, business?.organization_name, business?.email_id, email, name]);

  const handleSubmit = () => {
    const id = business?._id?.$oid; 
    const updates = {
      organization_name: name,
      email_id: email,
      address_line_1: address,
    };
  
    if (!id) {
      console.error("ID is not defined");
      return;
    }
  
    api
      .put(`/pos/add/${id}`, updates, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("API call successful:", res); 
  
        toast.success("User updated successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Flip,
        });
  
        setTimeout(() => {
          console.log("Reloading page...");
          window.location.reload(); 
        }, 2000);
      })
      .catch((err) => {
        console.error("Error in API call:", err); 
      });
  };
  
  const confirm = () => {
    if (visible === true) {
      return (<div onClick={handleSubmit}>
        <Buttons Buttondata={GButton} />
      </div>)
    } else {
      return (<div id="confirm_btn">
        <Buttons Buttondata={KButtonerr} />
      </div>);
    }
  }
  const GButton = {
    Blue: 'blue',
    name: 'Update',
  };
  const FButton = {
    Cancel: 'red',
    name: 'Cancel',
  };
  const KButtonerr = {
    error: "blue",
    name: "update",
  };

  return (
    <div className="modals">
      <div className="overlays"></div>
      <div className="modal-contents">
        <div className="edit-popupscreen">
          <div className="Edit_business_overall">
            <h2 className="Edit_head">Edit Business</h2>
            <div>
              <button
                onClick={() => window.location.reload()}
                className="cancel-icon-Editbusiness"
              >
                <Tooltip title="Close" placement="bottom" arrow>
                  <IconButton>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </button>
            </div>
            <TextField
              sx={{ marginLeft: '20px', marginTop: '10px' }}
              id="organization_name"
              name="organization_name"
              label="Business Name"
              variant="outlined"
              value={name}
              // onChange={(e) => setName(e.target.value)}
              disabled
            />
            <TextField
              sx={{ marginLeft: '70px', marginTop: '8px' }}
              id="address_line_1"
              name="address_line_1"
              label="Address"
              variant="outlined"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <TextField
              sx={{ marginTop: '20px', marginLeft: '20px' }}
              id="email_id"
              name="email_id"
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              sx={{ marginLeft: '70px', marginTop: '20px' }}
              id="Business Type"
              name="Business Type"
              label="Business Type"
              variant="outlined"
              value={type}
              disabled
              onChange={(e) => settype(e.target.value)}
            />
          </div>
          <div className="business_btn">
            <div onClick={() => window.location.reload()}>
              <Buttons Buttondata={FButton} />
            </div>
            {/* <div onClick={handleSubmit}>
              <Buttons Buttondata={GButton} />
            </div> */}
            {confirm()}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default EditBusiness;
