import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  NativeSelect,
  TextField,
  Tooltip,
  styled,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { Flip, ToastContainer, toast } from "react-toastify";
import Deleteproducts from "./DeleteProducts";
import Editmenu from "./EditMenu";
import CloseIcon from "@mui/icons-material/Close";
import { InsertEmoticon } from "@mui/icons-material";
import api from "../../../axiosConfig";
import Buttons from "../../../common-component/Buttons";
import Header from "../../../components/laundry/Header";
import Menu from "../../../components/laundry/Menu";
import Tables from "../../../common-component/Table";
import EditProduct from "./EditProduct";

interface CategoryButton {
  _id: { $oid: string };
  CategoryName: string;
}
interface Productbtn {
  _id: { $oid: string };
  Amount: string;
  ArabicName: string;
  ProductName: string;
  category_id: string;
}
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#f1f1f1",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 22px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdf",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

function AddProduct() {
  const [errormsg, setErrormsg] = useState<boolean>(false); 
  const [buttonNames, setButtonNames] = useState<CategoryButton[]>([]);
  const [SelectedProducts, setSelectedProducts] = useState<Productbtn[]>([]);
  const [modal, setModal] = useState<boolean>(false);
  const [Edit, setEdit] = useState<boolean>(false);
  const [productdata, setProductdata] = useState<any>("");
  const [arabic, setarabic] = useState<any>("");
  const [amount, setAmount] = useState<any>("");
  const [id, setId] = useState<any>();
  const [Name, setName] = useState<any>();
  const [Amountedit, setAmountedit] = useState<any>();
  const [Categorytype, setCategorytype] = useState<any>();
  const [Delete, setDelete] = useState<boolean>(false);
  const [visible, setvisible] = useState<boolean>(false);
  const [selectcategory, setselectcategory] = React.useState("");
  const [empty, setempty] = useState<boolean>(false);
  const productnamesref = useRef<HTMLInputElement>(null);
  const selectcategorys = useRef<HTMLInputElement>(null);

  const handleproductnamesKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      productnamesref.current?.focus();
    }
  };
  const handleproductamountsKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      selectcategorys.current?.focus();
    }
  };
  const handlecategoryselectKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      if (productdata && amount && selectcategory) {
        updateproduct();
      } else {
        toast.error("Please fill in required fields", {
          position: "top-right",
          autoClose: 2000,
          theme: "colored",
          transition: Flip,
        });
      }
    }
  };

  useEffect(() => {
    document.title = "Products - Settings - iMetanic";
    if (empty === false) {
      category();
      product();
    }
    if (productdata && amount && arabic&& selectcategory) {
      setvisible(true);
    } else {
      setvisible(false);
    }
  }, [amount, arabic, empty, productdata, selectcategory]);
  const category = () => {
    api
      .get(`/app/v1/pos/category`)
      .then((res) => {
        const categoryData: CategoryButton[] = res.data.result;
        setButtonNames(categoryData);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const product = () => {
    api
      .get(`/app/v1/pos/product`)
      .then((res) => {
        const Products: Productbtn[] = res.data.result;
        setSelectedProducts(Products);
      })
      .catch((error) => { });
  };
  const updateproduct = () => {
    const updates = {
      ProductName: productdata,
      Amount: amount,
      ArabicName: arabic,
      category_id: selectcategory,
    };
    if (productdata && amount && selectcategory) {
      api
        .post(`/app/v1/pos/product`, updates)
        .then((res) => {
          console.log(res);
          toast.success("Product added successfully", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setProductdata("");
          // setModal(false);
          product();
          setTimeout(() => {
            window.location.reload();
          }, 100);
        })
        .catch((error) => {
          const errormsg = error.response.data.message === "Product already exists!";
          if (errormsg) {
            setErrormsg(true);
            toast.error("Product already exists", {
              position: "top-right",
              autoClose: 2000,
              theme: "colored",
            });
          } else if (error.response.data.message === "Arabic name already exists!") {
            toast.error("Arabic name already exists!", {
              position: "top-right",
              autoClose: 2000,
              theme: "colored",
            });
          }else if(error.response.data.message ==="Arabic name not provided"){
            toast.error("Arabic name not provided!", {
              position: "top-right",
              autoClose: 2000,
              theme: "colored",
            });
          }
        });
    } else {
      toast.error("Please fill in required fields.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
};
  const Edits = (
    id: string,
    Name: string,
    Amount: string,
    Category: string,
    arabic:string
  ) => {
    setId(id);
    setEdit(!Edit);
    setName(Name);
    setAmountedit(Amount);
    const categoryObj = buttonNames.find(
      (button) => button._id.$oid === Category
    );
    setarabic(arabic);
    if (categoryObj) {
      setCategorytype(categoryObj.CategoryName);
      return categoryObj.CategoryName;
    } else {
      return "Unknown";
    }
  };
  const Products = {
    titlesno: "S.No",
    titlename1: "Product name",
    titlename2: "Arabic word",
    titlename3: "Product price (QAR)",
    titlename4: "Category type",
    titlename5: "Actions",

    S_no: SelectedProducts.map((item, index) => index + 1),
    bodyname1: SelectedProducts.map((item, index) => item.ProductName),
    bodyname2: SelectedProducts.map((item) => item.ArabicName),
    bodyname3: SelectedProducts.map((item, index) => item.Amount),
    bodyname4: SelectedProducts.map((item) => {
      const category_id = item.category_id;
      const category = buttonNames.find(
        (button) => button._id.$oid === category_id
      );
      return category ? category.CategoryName : "Unknown";
    }),
    bodyname5: SelectedProducts.map((item, index) => (
      <>
        <button
          className="icon-btn-edit"
          onClick={() =>
            Edits(
              item._id.$oid,
              item.ProductName,
              item.Amount,
              item.category_id,
              item.ArabicName
            )
          }
        >
          <Tooltip title="Edit" placement="bottom" arrow>
            <IconButton>
              <EditIcon style={{ color: "black" }} />
            </IconButton>
          </Tooltip>
        </button>
        <button
          className="icon-btn-edit"
          onClick={() => deleteproduct(item._id.$oid, item.ProductName)}
        >
          <Tooltip title="Delete" placement="bottom" arrow>
            <IconButton>
              <DeleteOutlineIcon style={{ color: "red" }} />
            </IconButton>
          </Tooltip>
        </button>
      </>
    )),
  };
  const deleteproduct = (id: string, Name: string) => {
    setId(id);
    setDelete(!Delete);
    setName(Name);
  };
  const MButton = {
    Blue: "blue",
    name: "Add Product",
  };
  const HButton = {
    Blue: "blue",
    name: "Confirm",
  };
  const GButton = {
    Cancel: "red",
    name: "Cancel",
  };
  const KButtonerr = {
    error: "blue",
    name: "Confirm",
  };
  const getCategoryInitials = (categoryName: string): string => {
    return categoryName
      .split(' ')
      .map(word => word.charAt(0).toUpperCase())
      .join('');
  };

  const handleChange = (event: { target: { value: string } }) => {
    const selectedCategoryId = event.target.value;
    setselectcategory(selectedCategoryId);

    const selectedCategory = buttonNames.find(
      (button) => button._id.$oid === selectedCategoryId
    );
    if (selectedCategory) {
      const categoryInitials = getCategoryInitials(selectedCategory.CategoryName);
      setCategorytype(categoryInitials);
      setProductdata((prevProductData: string) => {
        const nameWithoutOldInitials = prevProductData.replace(/\[\w+\]$/, '');
        return `${nameWithoutOldInitials.trim()} [${categoryInitials}]`;
      });
    }
  };

  const handlesearch = (event: { target: { value: string } }) => {
    const term = event.target.value;
    if (Number(term) >= 0) {
      setAmount(term);
    }
  };
  const handleBlur = () => {
    if (!isNaN(Number(amount)) && amount.trim() !== '') {
      let formattedAmount = amount.trim();
      let [integerPart = '', decimalPart = ''] = formattedAmount.split('.');

      if (decimalPart === undefined || decimalPart === '') {
        decimalPart = '00';
      } else if (decimalPart.length === 1) {
        decimalPart = decimalPart + '0';
      } else if (decimalPart.length > 2) {
        decimalPart = decimalPart.slice(0, 2);
      }

      formattedAmount = `${integerPart}.${decimalPart}`;
      setAmount(formattedAmount);
    }
  };

  const changedata = () => {
    setProductdata("");
    setAmount("");
    setselectcategory("");
    setErrormsg(false);
  };
  const menu = {
    Product: "product",
  };
  const confirm = () => {
    if (visible === true) {
      return (
        <div onClick={updateproduct}>
          <Buttons Buttondata={HButton} />
        </div>
      );
    } else {
      return (
        <div id="confirm_btn">
          <Buttons Buttondata={KButtonerr} />
        </div>
      );
    }
  };
  return (
    <div>
      <Header Header="Settings - Products" />
      <div style={{ display: "flex" }}>
        <Menu />
        <div className="Edit-overall">
          <Editmenu editmenu={menu} />
          <div className="category-add" style={{marginRight:"105px"}}>
            <div onClick={() => { setModal(!modal); setempty(true) }}>
              <Buttons Buttondata={MButton} />
            </div>
          </div>
          {SelectedProducts.length === 0 ? (
            <p>No data available</p>
          ) : (
            <div className="customer_Table">
              <Tables product={Products} />
            </div>
          )}
          {modal && (
            <div className="modals">
              <div className="overlays"></div>
              <div className="modal-contenters">
                <div className="edit-popup
                screens">
                  <div className="addcategory-edit-p">
                    <h2>
                      Add product

                      <button
                        onClick={() => setModal(false)}
                        className="cancel-icon-AddProduct"
                      >
                        <CloseIcon  />
                      </button>
                    </h2>
                    <div className="edit_productsty">
                      {errormsg ? (
                        <Box
                          component="form"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "500px",
                            marginTop: "20px",
                            borderBlockWidth: "20px",
                            marginLeft: "20px",
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <TextField
                            error
                            label={
                              <span>
                                Product Name <span style={{ color: '#f04141', fontSize: "15px" }}>*</span>
                              </span>
                            }
                            value={productdata}
                            onClick={changedata}
                            onChange={(e) => setProductdata(e.target.value)}
                          />
                          <TextField
                            label={
                              <span>
                                Arabic <span style={{ color: '#f04141', fontSize: "15px" }}>*</span>
                              </span>
                            }
                            sx={{ width: "500px" ,marginTop:"18px"}}
                            value={arabic}
                            onChange={(e) => setarabic(e.target.value)}
                            onKeyPress={handleproductnamesKeyPress}
                          />
                          <TextField
                            label={
                              <span>
                                Product price  <span style={{ color: '#f04141', fontSize: "14px" }}>*</span>
                              </span>
                            }
                            value={amount}
                            onClick={changedata}
                            onChange={handlesearch}
                            onBlur={handleBlur}
                            type="number"
                            sx={{ marginTop: "20px" }}
                          />
                          <FormControl sx={{ m: 1 }} variant="standard">
                            <InputLabel htmlFor="demo-customized-select-native">
                              Category Type
                            </InputLabel>
                            <NativeSelect
                              onClick={() => setErrormsg(false)}
                              error
                              id="demo-customized-select-native"
                              value={selectcategory}
                              onChange={handleChange}
                              input={<BootstrapInput />}
                            >
                              <option onClick={() => setCategorytype(null)}>
                                Select category
                              </option>
                              {buttonNames.map((item) => (
                                <option
                                  key={item._id.$oid}
                                  value={item._id.$oid}
                                >
                                  {item.CategoryName}
                                </option>
                              ))}
                            </NativeSelect>
                          </FormControl>
                        </Box>
                      ) : (
                        <Box
                          component="form"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "510px",
                            marginTop: "20px",
                            borderBlockWidth: "20px",
                            marginLeft: "20px",
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <TextField
                            label={
                              <span>
                                Product name <span style={{ color: '#f04141', fontSize: "15px" }}>*</span>
                              </span>
                            }
                            sx={{ width: "485px", marginLeft: "10px" }}
                            value={productdata}
                            onChange={(e) => setProductdata(e.target.value)}
                            onKeyPress={handleproductnamesKeyPress}
                          />
                          <TextField
                            label={
                              <span>
                                Arabic name<span style={{ color: '#f04141', fontSize: "15px" }}>*</span>
                              </span>
                            }
                            sx={{ width: "485px", marginLeft: "10px",marginTop:"18px" }}
                            value={arabic}
                            onChange={(e) => setarabic(e.target.value)}
                            onKeyPress={handleproductnamesKeyPress}
                          />
                          <TextField
                            label={
                              <span>
                                Product price <span style={{ color: '#f04141', fontSize: "15px" }}>*</span>
                              </span>
                            }
                            value={amount}
                            sx={{
                              width: "485px",
                              marginLeft: "10px",
                              marginTop: "20px",
                            }}
                            onChange={handlesearch}
                            onBlur={handleBlur}
                            type="number"
                            onKeyPress={handleproductamountsKeyPress}
                            inputRef={productnamesref}
                          />
                          <FormControl sx={{ m: 1 }} variant="standard">
                            <InputLabel htmlFor="demo-customized-select-native">
                              Category type <span style={{ color: '#f04141', fontSize: "15px" }}>*</span>
                            </InputLabel>
                            <NativeSelect
                              id="demo-customized-select-native"
                              value={selectcategory}
                              onChange={handleChange}
                              onKeyPress={handlecategoryselectKeyPress}
                              inputRef={selectcategorys}
                              input={<BootstrapInput />}
                            >
                              <option value={""} disabled>
                                <span>
                                  Select category <span style={{ color: '#f04141', fontSize: "15px" }}>*</span>
                                </span>
                              </option>
                              {buttonNames.map((item) => (
                                <option
                                  key={item._id.$oid}
                                  value={item._id.$oid}
                                >
                                  {item.CategoryName}
                                </option>
                              ))}
                            </NativeSelect>
                          </FormControl>
                        </Box>
                      )}
                    </div>
                    <div className="confirm_btn_edit">
                      <div onClick={() => setModal(false)}>
                        <Buttons Buttondata={GButton} />
                      </div>
                      {confirm()}
                      <ToastContainer />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {Edit && (
            <EditProduct
              id={id}
              Name={Name}
              Amount={Amountedit}
              Category={Categorytype}
              arabic={arabic}
            />
          )}
          {Delete && <Deleteproducts id={id} Name={Name} />}
        </div>
      </div>
    </div>
  );
}

export default AddProduct;
