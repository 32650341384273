import { Box, TextField } from "@mui/material";
import "./Edit.css";
import React, { useEffect, useState } from "react";
import { Flip, ToastContainer, toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import api from "../../../axiosConfig";
import Buttons from "../../../common-component/Buttons";
import LoadingScreen from "../../../common-component/LoadingScreen";
// import LoadingScreen from "../../../common-component/LoadingScreen";
interface Props {
  id: string | undefined;
  Name: string;
}

const EditCategory: React.FC<Props> = ({ id, Name }) => {
  const [errormsg, setErrormsg] = useState<boolean>();
  const [Loading, setLoading] = useState<boolean>(false);
  const [categoryname, setCategoryname] = useState<any>();
  const [update, setUpdate] = useState<boolean>(false);
  const [visible, setvisible] = useState<Boolean>(false);
  const [modal, setModal] = useState<Boolean>(true);
  const handlecategorynamesKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      if (categoryname === Name) {
      } else {
        if (categoryname) {
          categoryupdate();
        } else {
          toast.error("Please fill in required fields.");
        }
      }
    }
  };

  useEffect(() => {
    setCategoryname(Name);
  }, [Name]);
  useEffect(() => {
    if (categoryname === Name) {
      setvisible(false);
      console.log("false");
    } else {
      if (categoryname) {
        setvisible(true);
        console.log("true");
      } else {
        console.log("false");
      }
    }
  }, [Name, categoryname]);

  const categoryupdate = () => {
    if (categoryname) {
      const categorynamechange = {
        CategoryName: categoryname,
      };
      api.put(`/app/v1/pos/category/${id}`, categorynamechange).then((res) => {
        console.log('res',res.status);
        toast.success("Category updated successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      // setCategoryname("");
       //setModal(false);
       //setErrormsg(false);
       setTimeout(() => {
        window.location.reload();
      }, 2000);
      setLoading(false);
      })
      .catch((error) => {
        const errormsgs =
          error.message === "Request failed with status code 403";
        setErrormsg(errormsgs);
        if (errormsgs) {
          toast.error("Category name already exist", {
            position: "top-right",
            autoClose: 2000,
            theme: "colored",
          });
        }
        setLoading(false);
      });
    } else {
      toast.error("Please fill in required fields.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  const GButton = {
    Blue: "blue",
    name: "update",
  };
  const FButton = {
    Cancel: "red",
    name: "Cancel",
  };
  const KButtonerr = {
    error: "blue",
    name: "update",
  };
  const confirm = () => {
    if (visible === true && categoryname.length > 0) {
      return (
        <div onClick={categoryupdate}>
          <Buttons Buttondata={GButton} />
        </div>
      );
    } else {
      return (
        <div id="confirm_btn">
          <Buttons Buttondata={KButtonerr} />
        </div>
      );
    }
  };
  return (
    <div className="addcategory-editadd">
      <h2>
        Edit category
        <button
          onClick={() => window.location.reload()}
          className="cancel-icon-Editcategory"
        >
          <CloseIcon />
        </button>
      </h2>
      <div className="addcategory-edit">
        <Box
          component="form"
          sx={{
            "& > :not(style)": {
              m: 1,
              width: "50ch",
              marginTop: "40px",
              borderBlockWidth: "20px",
            },
          }}
          noValidate
          autoComplete="off"
        >
          {errormsg ? (
            <TextField
            label="Category Name"
            error
            value={categoryname}
            onChange={(e) => setCategoryname(e.target.value.toUpperCase())}
            onClick={() => setUpdate(!update)}
            onKeyPress={handlecategorynamesKeyPress}
          />
        ) :(
            <TextField
              label="Category name*"
              value={categoryname}
              onChange={(e) =>setCategoryname(e.target.value.toUpperCase())}
              onKeyPress={handlecategorynamesKeyPress}
            />
          )}
        </Box>
        <div className="btnconfirm-edit">
          <div onClick={() => window.location.reload()}>
            <Buttons Buttondata={FButton} />
          </div>
          {confirm()}
        </div>
        <ToastContainer />
      </div>
      {Loading ? <LoadingScreen /> : ""}
    </div>
  );
};

export default EditCategory;
