import {Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Flip, toast, ToastContainer } from "react-toastify";

function ForgotPasswordNew() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const navigate = useNavigate();

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    console.log(value);    
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(value)) {
      setValidEmail(true);
      setEmailError("");
    } else {
      setValidEmail(false);
      setEmailError("Invalid email address");
    }
  };
  

  const handleSubmit = async () => {
    if (validEmail) {
      try {
        await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/forgot-password`,
          { email: email }
        );
        toast.success("Password reset link sent successfully", {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Flip,
        });
        setTimeout(() => {
          navigate("/");
        }, 2000);
      } catch (error: any) {
        const errorMessage = error.response?.data?.error || 'Failed to send password reset link';
        if (error.response?.status === 404 && errorMessage === "User not found") {
          toast.error("User not found. Please check the email address and try again.", {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Flip,
          });
        } else {
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Flip,
          });
        }
        setEmailError(errorMessage);
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        backgroundColor: "#EEF4F7",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "550px",
          height: "300px",
          display: "grid",
          backgroundColor: "white",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "20px",
        }}
      >
        <h1>Forgot Your Password?</h1>
        <p>Enter your registered email address to receive a password reset link.</p>
        <TextField
          id="forgotpassword-email"
          label="Email Address"
          variant="outlined"
          type={email}
          value={email}
          onChange={handleEmailChange}
          error={!!emailError}
          helperText={emailError}
        />
        <div style={{ marginLeft:'150px' }} onClick={handleSubmit}>
        <Button
           type="submit"
           style={{
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
               background: "none",
               color: "white",
               fontWeight: "bold",
               backgroundColor: "#007BFF",
               border: `2px solid`,
               borderRadius: "40px",
               height: "40px",
               width: "190px",
               fontSize: "medium",
           }}
        >
          Send Reset Link
        </Button>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}

export default ForgotPasswordNew;
