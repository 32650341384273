import React, { ChangeEvent, lazy, useEffect, useState } from "react";
// import "./Home.css";
import "../laundry/Home.css"
// import User from "../../assets/Images/user1.png";
import User from '../../../assets/Images/user1.png';
// import Name from "../../assets/Images/Name.png";
import Name from '../../../assets/Images/name-icon.png';
// import Address from "../../assets/Images/Address.png";
import Address from '../../../assets/Images/address-icon.png';
// import date from "../../assets/Images/date.png";
import date from '../../../assets/Images/date.png'
// import phone from "../../assets/Images/phone.png";
import phone from '../../../assets/Images/phone.png';
import PublicIcon from "@mui/icons-material/Public";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import FiberPinIcon from '@mui/icons-material/FiberPin';
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import {
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  styled,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Flip, ToastContainer, toast } from "react-toastify";
import { City, Country, State } from "country-state-city";
import { grey } from "@mui/material/colors";
import api from "../../axiosConfig";
import LoadingScreen from "../../common-component/LoadingScreen";
// import LoadingScreen from "../../common-component/LoadingScreen";
export const TextFieldStyled = styled(TextField)(
  ({ theme }) => `
    & .MuiOutlinedInput-root {
      border: 1px solid ${theme.palette.grey[500]};
    }
  `
);
interface AddressDetails {
  addressLine1: string;
  state: string;
  country: string;
  pincode: string;
}
const Signup = () => {
  const navigate = useNavigate();
  const [Loading, setLoading] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [organization_name, setorganization_name] = useState<string>("");
  const [business_type, setBusinessType] = useState<string>("");
  const [dob, setDob] = useState<string>(" ");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [address] = useState<AddressDetails>({
    addressLine1: "",
    state: "",
    country: "",
    pincode: "",
  });
  const [erroruser, setErroruser] = useState<any>();
  const [error, setError] = useState<any>();
  const [visible, setvisible] = useState<boolean>(false);
  const [addressLine1, setAddressLine1] = useState<string>("");
  const [pincode, setPincode] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [, setconfirmemail] = useState("");
  const [emailerror, setemailerror] = useState<boolean>();
  const [errorbusinessname, seterrorbusinessname] = useState<any>();
  const [emailiderror, setemailiderror] = useState<any>();
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [selectedState, setSelectedState] = useState<string>('');
  const [phonenumbererror, setphonenumbererror] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>('');
  const [doberror, setdobError] = useState<string | null>(null);
  const [dayError, setDayError] = useState<string | null>(null);
  const [monthError, setMonthError] = useState<string | null>(null);

  useEffect(() => {
    document.title = "Signup - iMetanic";
  });

  useEffect(() => {
    if (
      username &&
      organization_name &&
      business_type &&
      email
    ) {
      setvisible(true);
    } else {
      setvisible(false);
    }
  }, [organization_name, username, email, business_type]);
  const handleRegister = async () => {
    setLoading(true);
    if (
      username &&
      email 
    ) {
      try {
        api.post('/pos/register', {
          user_name: username,
          name,
          organization_name,
          business_type,
          date_of_birth: dob,
          phone_number: phoneNumber,
          address_line_1: addressLine1,
          email_id: email,
          state: selectedState,
          postal_code: pincode,
          country: selectedCountry,
          role: "Super Admin",
          filename: "",
        }).then((response) => {
          const responseData = response.data;
          const userId = responseData.Id;
          toast.success('Account created successfully', {
            position: 'top-right',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
            transition: Flip,
          });
          emailmsg(userId, username);
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }).catch((error) => {
          console.log('error', error.response.data);
          const username = error.response.data.message
          if (username === 'Username Already Exists') {
            setErroruser("Username already exists.");
          } else if (username === 'Organization name Already Exists') {
            seterrorbusinessname("Organization name already exists");
          } else if (username === 'Email Already Exists') {
            setemailiderror("Email already exists");
          } else if (username === 'Phone Number Already Exists') {
            setphonenumbererror("Phone number already exists");
          } else {
            setError("Unknown error occurred.");
          }
        });
      } catch (error) {
        console.error("Error during registration:", error);
      }
    } else {
      toast.error("Please fill in all the fields.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Flip,
      });
    }
    setLoading(false);
  };
  const countries = Country.getAllCountries().map((country) => ({
    label: country.name,
    value: country.isoCode,
  }));
  const states = selectedCountry
    ? State.getStatesOfCountry(selectedCountry).map((state) => ({
      label: state.name,
      value: state.isoCode,
    }))
    : [];
  const businessTypes = [
    { label: "Laundry", value: "Laundry" },
    { label: "Restaurant", value: "Restaurant" },
    { label: "Carwash", value: "Carwash" },
  ];
  const expiryTime = new Date().getTime();
  const emailmsg = (id: string, name: string) => {
    const url = `${process.env.REACT_APP_WEB_URL}/imetanic/setpassword/${id}/${expiryTime}/2580`;
    const sendmail = {
      receiver_email: email,
      subject: "Set your new password",
      welcome_message: `${name}`,
      success_message: "Your account has been successfully created.",
      res_message: "We got a request to set your POS Loundary Login password.",
      description: "Your password will not be created if you ignore this message. If you didn't ask for a create password.",
      setPassword_link: `${url}`,
      user_name: `${username}`,
    };
    console.log("sendmail", sendmail);
    api
      .post(`/app/v1/pos/user_send_email`, sendmail)
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.error("Error sending mail:", error);
      });
  };
  const customeremailid = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const isValidEmail = (value: string): boolean => {
      if (value.trim() === "") return false;
      if (!value.includes("@")) return false;
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z]+(\.[a-zA-Z]{2,})+$/;
      const validDomains = [".com", ".in", ".co", ".net", ".org", ".co.in", ".shine.com", ".me"];
      const hasValidDomain = validDomains.some((domain) => value.endsWith(domain));
      const isPatternValid = emailPattern.test(value);
      const domainPart = value.split('@')[1];
      if (!domainPart) return false;
      const isNameValid = /^[a-zA-Z]+$/.test(domainPart.split('.')[0]);
      return hasValidDomain && isPatternValid && isNameValid;
    };
    const emails = isValidEmail(value);
    if (emails) {
      setEmail(value);
      setconfirmemail(value);
      setemailerror(false);
    } else {
      setEmail(value);
      setemailerror(true);
    }
  };
  const isValidDate = (day: number, month: number, year: number): boolean => {
    if (month < 1 || month > 12) return false;
    const daysInMonth = [
      31, // January
      isLeapYear(year) ? 29 : 28, // February
      31, // March
      30, // April
      31, // May
      30, // June
      31, // July
      31, // August
      30, // September
      31, // October
      30, // November
      31  // December
    ];
    return day >= 1 && day <= daysInMonth[month - 1];
  };
  const handleDobChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let newValue = value.replace(/[^0-9-]/g, '');
    const parts = newValue.split('-');
    setDayError(null);
    setMonthError(null);
    setdobError(null);
    let day = 0;
    let month = 0;
    let year = 0;
    let validDate = true;
    if (parts[0]) {
      day = parseInt(parts[0], 10);
      if (day > 31) {
        parts[0] = '31';
        setDayError('Day cannot be more than 31');
        validDate = false;
      }
    }
    if (parts[1]) {
      month = parseInt(parts[1], 10);
      if (month > 12) {
        parts[1] = '12';
        setMonthError('Month cannot be more than 12');
        validDate = false;
      }
    }
    if (parts[2]) {
      year = parseInt(parts[2], 10);
      if (parts[2].length > 4) {
        parts[2] = parts[2].slice(0, 4);
      }
    }
    newValue = parts.join('-');
    if (parts.length === 3) {
      day = parseInt(parts[0], 10);
      month = parseInt(parts[1], 10);
      year = parseInt(parts[2], 10);
      if (validDate && !isValidDate(day, month, year)) {
        setDob('');
        setdobError('Invalid date');
        validDate = false;
      }
      if (dayError || monthError) {
        validDate = false;
        if (day > 31) {
          setdobError('Day is invalid');
        }
        if (month > 12) {
          setdobError('Month is invalid');
        }
      }
      if (validDate) {
        setDob(newValue);
        setdobError(null);
      } else {
        setDob('');
      }
    } else {
      setDob('');
    }
    if (newValue === '') {
      setDob('');
      setdobError(null);
    } else {
      const formattedDate = formatDate(newValue);
      if (formattedDate) {
        setDob(formattedDate);
        setdobError(null);
      } else {
        setDob('');
      }
    }
    setInputValue(newValue);
  };
  const formatDate = (dateString: string): string => {
    const parsedDate = parseDate(dateString);
    if (parsedDate) {
      const day = String(parsedDate.getDate()).padStart(2, '0');
      const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
      const year = parsedDate.getFullYear();
      return `${day}-${month}-${year}`;
    }
    return '';
  };
  const parseDate = (dateString: string): Date | null => {
    const numericDateRegex = /^(0?[1-9]|[12][0-9]|3[01])-(0?[1-9]|1[0-2])-(\d{4})$/;
    const numericMatch = dateString.match(numericDateRegex);
    if (numericMatch) {
      const [dayStr, monthStr, yearStr] = dateString.split('-');
      const month = parseInt(monthStr, 10);
      const day = parseInt(dayStr, 10);
      const year = parseInt(yearStr, 10);
      if (yearStr.length !== 4 || isNaN(year)) {
        return null;
      }
      if (month < 1 || month > 12) {
        return null;
      }
      if (day < 1 || day > 31 || !isValidDayForMonth(day, month, year)) {
        return null;
      }
      return new Date(year, month - 1, day);
    }
    return null;
  };
  const isValidDayForMonth = (day: number, month: number, year: number): boolean => {
    const daysInMonth = [31, (isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    return day >= 1 && day <= daysInMonth[month - 1];
  };
  const isLeapYear = (year: number): boolean => {
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
  };


  const handlePhoneChange = (e: { target: { value: string; }; }) => {
    let value = e.target.value.replace(/\D/g, "");
    if (value.length > 8) {
      value = value.slice(0, 8);
    }
    setPhoneNumber(value);
    if (value.length === 0) {
      setError("");
    }
  };
  const handlePhoneBlur = () => {
    const unformattedPhoneNumber = phoneNumber.replace(/\s/g, "");
    if (unformattedPhoneNumber.length === 0) {
      setError("");
    } else if (unformattedPhoneNumber.length !== 8) {
      setError("Your phone number is incomplete");
    } else {
      setError("");
    }
  };

  const signbtn = () => {
    if (visible === true) {
      return (
        <button className="btns1" onClick={handleRegister}>
          Signup
        </button>
      );
    } else {
      return (
        <button
          className="btns1"
          style={{ backgroundColor: "#96bbe8" }}
          disabled
        >
          Signup
        </button>
      );
    }
  };
  return (
    <div className="signall">
      <ToastContainer />
      <div className="colarcontainer-1"></div>
      <div className="Container1">
        <div className="overview">
          <h1>Get’s Started</h1>
          {/* <div style={{ display: "flex" }}>
             <p style={{ fontSize: "large" }}>Already have Account ?</p>
            <a
              href="/"
              style={{
                color: "#1678f3",
                textDecoration: "none",
                marginLeft: "5px",
              }}>
              <p style={{ fontSize: "large" }}>Login</p>
            </a> 
          </div> */}
          <div className="inbox">
            {erroruser ? <FormControl sx={{ m: 1, marginBottom: "2px" }} variant="standard">
              <InputLabel
                htmlFor="standard-adornment-password"
                style={{ display: "flex", marginLeft: "-37px" }}
              >
                <img src={User} className="imgs" alt="" />
                <p>Username</p>
              </InputLabel>
              <Input
                error
                id="outlined-basic"
                className="inputuser"
                value={username}
                onClick={() => setErroruser('')}
              />
              <p style={{ color: 'red' }}>{erroruser}</p>
            </FormControl> :
              <FormControl sx={{ m: 1, marginTop: "2px" }} variant="standard">
                <InputLabel
                  htmlFor="standard-adornment-password"
                  style={{ display: "flex", marginLeft: "-37px" }}
                >
                  <img src={User} className="imgs" alt="" />
                  <p>Username</p>
                  <span style={{ color: '#f04141', fontSize: "18px" }}>*</span>
                </InputLabel>
                <Input
                  id="outlined-basic"
                  className="inputuser"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </FormControl>}
          </div>
          <div className="inbox">
            <FormControl sx={{ m: 1, marginTop: "2px" }} variant="standard">
              <InputLabel
                htmlFor="standard-adornment-password"
                style={{ display: "flex", marginLeft: "-37px" }}
              >
                <img src={Name} className="imgs" alt="" />
                <p>Name</p>
              </InputLabel>
              <Input
                id="outlined-basic"
                className="inputuser"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>
          </div>
          <div className="inbox">
            {errorbusinessname ? <FormControl sx={{ m: 1, marginTop: "2px" }} variant="standard">
              <InputLabel
                htmlFor="standard-adornment-password"
                style={{ display: "flex", marginLeft: "-37px" }}
              >
                <img src={Address} className="imgs" alt="" />
                <p>Organization name</p>
              </InputLabel>
              <Input
                error
                id="outlined-basic"
                className="inputuser"
                value={organization_name}
                onClick={() => seterrorbusinessname('')}
              />
              <p style={{ color: 'red' }}>{errorbusinessname}</p>
            </FormControl> : <FormControl sx={{ m: 1, marginTop: "2px" }} variant="standard">
              <InputLabel
                htmlFor="standard-adornment-password"
                style={{ display: "flex", marginLeft: "-37px" }}
              >
                <img src={Address} className="imgs" alt="" />
                <p>Organization name</p>
                <span style={{ color: '#f04141', fontSize: "18px" }}>*</span>
              </InputLabel>
              <Input
                id="outlined-basic"
                className="inputuser"
                value={organization_name}
                onChange={(e) => setorganization_name(e.target.value)}
              />
            </FormControl>}
          </div>
          <div className="inbox" >
            <FormControl fullWidth sx={{ m: 1, marginTop: "12px", marginLeft: '-16px' }} variant="standard">
              <InputLabel id="business-type-label" style={{ display: "flex", marginTop: '-8px' }}>
                <AddBusinessIcon sx={{ color: grey[600] }} />
                <p style={{ marginTop: "2px", marginLeft: '6px' }}>Business Type</p>
                <span style={{ color: '#f04141', fontSize: "18px" }}>*</span>
              </InputLabel>
              <Select
                labelId="business-type-label"
                id="business-type"
                value={business_type}
                onChange={(e) => setBusinessType(e.target.value)}
              >
                {businessTypes.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="inbox">
            {emailiderror ? <FormControl sx={{ m: 1, marginTop: "2px" }} variant="standard">
              <InputLabel
                htmlFor="standard-adornment-password"
                style={{ display: "flex", marginLeft: "-37px" }}>
                <MailOutlineIcon sx={{ marginTop: '5px' }} />
                <p style={{ marginTop: "5px", marginLeft: '10px' }}>
                  Email
                </p>
              </InputLabel>
              <Input
                error={!!emailerror}
                id="outlined-basic"
                className="inputuser"
                value={email}
                type="email"
                onChange={customeremailid}
                onClick={() => setemailiderror('')}
              />
              <p style={{ color: 'red' }}>{emailiderror}</p>
            </FormControl> : <FormControl sx={{ m: 1, marginTop: "2px" }} variant="standard">
              <InputLabel
                htmlFor="standard-adornment-password"
                style={{ display: "flex", marginLeft: "-37px" }} >
                <MailOutlineIcon sx={{ marginTop: '5px' }} />
                <p style={{ marginTop: "5px", marginLeft: '10px' }}>Email</p>
                <span style={{ color: '#f04141', fontSize: "18px" }}>*</span>
              </InputLabel>
              <Input
                id="outlined-basic"
                className="inputuser"
                value={email}
                type="email"
                onChange={customeremailid}
                error={emailerror}
              />
              {emailerror && (
                <p style={{ color: 'red', marginTop: '5px', marginLeft: '10px' }}>
                  Please enter a valid email address, excluding '@', '.co', and '.com'.
                </p>
              )}
            </FormControl>}
          </div>
          <div style={{ display: "flex" }}>
            <div className="inbox1">
              <div style={{ display: "flex" }}>
                <FormControl sx={{ m: 1, marginTop: "2px", marginLeft: "15px" }} variant="standard">
                  <InputLabel
                    htmlFor="standard-adornment-password"
                    style={{ display: "flex", marginLeft: "-30px" }}
                  >
                    <img src={date} alt="" className="imgs" />
                    <p> Date of Birth (dd-mm-yyyy)</p>
                  </InputLabel>
                  <Input
                    id="outlined-basic"
                    className="inputusers"
                    value={inputValue}
                    type="text"
                    onChange={handleDobChange}
                    error={!!dayError || !!monthError || !!doberror}
                  />
                  {doberror && <p style={{ color: 'red' }}>{doberror}</p>}
                  {dayError && <p style={{ color: 'red' }}>{dayError}</p>}
                  {monthError && <p style={{ color: 'red' }}>{monthError}</p>}
                </FormControl>
              </div>
            </div>
            <div className="inbox1">
              <div style={{ display: "flex", marginLeft: "25px" }}>
                <FormControl sx={{ m: 1, marginTop: "2px" }} variant="standard">
                  <InputLabel
                    htmlFor="standard-adornment-password"
                    style={{ display: "flex", marginLeft: "-37px" }}
                  >
                    <img src={phone} alt="" className="imgs" />
                    <p>Phone Number</p>
                  </InputLabel>
                  <Input
                    id="outlined-basic"
                    className="inputusers"
                    value={phoneNumber === "" ? "" : phoneNumber.toString()}
                    onChange={handlePhoneChange}
                    onBlur={handlePhoneBlur}
                    error={!!phonenumbererror}
                  />
                  {phonenumbererror && (
                    <p style={{ color: 'red' }}>{phonenumbererror}</p>
                  )}
                  {error && !phonenumbererror && (
                    <p style={{ color: 'red' }}>{error}</p>
                  )}
                </FormControl>
              </div>
            </div>
          </div>
          <div className="inbox">
            <FormControl sx={{ m: 1, marginTop: "2px" }} variant="standard">
              <InputLabel
                htmlFor="standard-adornment-password"
                style={{ display: "flex", marginLeft: "-37px" }}
              >
                <img src={Address} className="imgs" alt="" />
                <p style={{ marginTop: "3px" }}>Address (e.g., Door number, Street name, Village/City name)</p>
              </InputLabel>
              <Input
                id="outlined-basic"
                className="inputuser"
                value={addressLine1}
                onChange={(e) => setAddressLine1(e.target.value)}
              />
            </FormControl>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth sx={{ m: 1, marginTop: "2px", marginLeft: '-10px' }} variant="standard">
                <InputLabel id="country-label" style={{ display: "flex", marginTop: '-8px' }}>
                  <PublicIcon />
                  <p style={{ marginTop: "1px", marginLeft: '6px' }}>Country</p></InputLabel>
                <Select
                  labelId="country-label"
                  id="country"
                  value={selectedCountry}
                  onChange={(e) => {
                    setSelectedCountry(e.target.value);
                    setSelectedState('');
                  }}
                >
                  {countries.map((country) => (
                    <MenuItem key={country.value} value={country.value}>
                      {country.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth sx={{ m: 1, marginTop: "2px", marginLeft: '-10px' }} variant="standard">
                <InputLabel id="state-label" style={{ display: "flex", marginTop: '-8px' }}>
                  <PersonPinCircleIcon />
                  <p style={{ marginTop: "1px", marginLeft: '6px' }}>State</p></InputLabel>
                <Select
                  labelId="state-label"
                  id="state"
                  value={selectedState}
                  onChange={(e) => {
                    setSelectedState(e.target.value);
                  }}
                  disabled={!selectedCountry}
                >
                  {states.map((state) => (
                    <MenuItem key={state.value} value={state.value}>
                      {state.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth sx={{ m: 1, marginTop: "2px", marginLeft: '-10px' }} variant="standard">
                <InputLabel id="pincode" style={{ display: "flex", marginTop: '-8px', marginLeft: '-12px' }} >
                  <FiberPinIcon />
                  <p style={{ marginTop: "2px", marginLeft: '10px' }}>Postal code</p>
                </InputLabel>
                <Input
                  fullWidth
                  id="pincode"
                  value={pincode}
                  type="number"
                  onChange={(e) => setPincode(e.target.value)}
                  sx={{ m: 1, marginTop: "2px", marginLeft: '-10px' }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <div>
            <div className="signbtns">{signbtn()}</div>
          </div>
        </div>
      </div>
      <ToastContainer />
      {Loading ? <LoadingScreen /> : ""}
      <div className="Colorcontainer"></div>
    </div>
  );
};

export default Signup;
