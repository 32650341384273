import React from 'react';
import './CarWashHome.css';
import { Link } from 'react-router-dom';
import EventNoteIcon from '@mui/icons-material/EventNote';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import Brightness7Icon from '@mui/icons-material/Brightness7';
// import CarHeaderBox from './CarHeaderBox';
import CarMenuBox from './CarMenuBox';
import CarHeaderBox from './CarHeaderBox';

const CarWashHome = () => {
  const CarmenuItems = [
    { path: "/v1/new-order", icon: <EventNoteIcon className="menu-icon" />, label: "New Order" },
    { path: "/v1/order-list", icon: <FormatListNumberedIcon className="menu-icon" />, label: "Current Order" },
    { path: "/v1/car-status-list", icon: <Brightness7Icon className="menu-icon" />, label: "Light" },
    { path: "/v1/car-setting/car-products", icon: <SettingsIcon className="menu-icon" />, label: "Settings" },
    { path: "/v1/car-profile", icon: <PersonIcon className="menu-icon" />, label: "Profile" },
  ];

  return (
    <div className="mainbody_home">
      <CarHeaderBox Carheader="POS Carwash Home" />
      <div style={{display:"flex"}}>
      <CarMenuBox />
      <div className="body_alignment">
        {CarmenuItems.map((item, index) => (
          <Link key={index} to={item.path} aria-label={item.label}>
            <div className="content" role="menuitem">
              <div className="icon-container">
                {item.icon}
              </div>
              <p className="Order_list_tag">{item.label}</p>
            </div>
          </Link>
        ))}
      </div>
      </div>
    </div>
  );
};
export default CarWashHome;