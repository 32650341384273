import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import Editcustomer from "./EditCustomer";
import Deletescreen from "./DeleteScreen";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import Editmenu from "./EditMenu";
import api from "../../../axiosConfig";
import Header from "../../../components/laundry/Header";
import Menu from "../../../components/laundry/Menu";

interface Customer_data {
  _id: {
    $oid: string;
  };
  Name: string;
  phone_number: string;
  Email: string;
  Address: {
    customeraddress1: string;
    customeraddress2: string;
    customeraddress3: string;
  };
}
const CustomerData = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [customerdata, setcustomerdata] = useState<Customer_data[]>([]);
  const [filteredData, setFilteredData] = useState<Customer_data[]>([]);
  const [openscreen, setopenscreen] = useState<boolean>(false);
  const [deletescreen, setdeletescreen] = useState<boolean>(false);
  const [openid, setopenid] = useState("");
  const [Name, setName] = useState("");
  const [phonenumber, setphonenumber] = useState("");
  const [email, setemail] = useState("");
  const [address, setaddress] = useState("");
  const [address2, setaddress2] = useState("");
  const [address3, setaddress3] = useState("");
  const [data, setdata] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [error, setError] = useState<string>("");

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    getcustomer();
  }, []);

  const getcustomer = () => {
    document.title = "Customers - Settings - iMetanic";
    api.get(`/app/v1/pos/customer`).then((res) => {
      const customerdatas: Customer_data[] = res.data.result;
      setcustomerdata(customerdatas);
      setFilteredData(customerdatas);
    });
  };
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    value = value.replace(/[!@#$%^&?*<>|/{}():_+=";'.,[\]`-]/g, '');
    setSearchTerm(value);
    if (value.trim() === "") {
      setFilteredData(customerdata);
      setError("");
    } else {
      const formattedValue = value.toLowerCase().trim();
      const filtered = customerdata.filter(
        (item) =>
          (item.phone_number &&
            item.phone_number.toLowerCase().includes(formattedValue)) ||
          (item.Name &&
            item.Name.toLowerCase().includes(formattedValue))
      );
      if (filtered.length === 0) {
        setError("No results found.");
      }else{
        setError("");
      }
      setFilteredData(filtered);
    }
  };
  
  const tableformet = () =>{
    if (filteredData === null) {
      return <h3> No result found</h3>
    }
    return(
      <div className="customer_Table">
            <Paper sx={{ width: "87%", zIndex:"0" }} id="Table">
              <TableContainer sx={{ maxWidth: 2050 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{ color: "white", fontSize: "large", zIndex:"0"  }}>
                        S.No
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ color: "white", fontSize: "large", zIndex:"0"  }}>
                        Customer name
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ color: "white", fontSize: "large" , zIndex:"0" }}>
                        Email ID
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ color: "white", fontSize: "large", zIndex:"0"  }}>
                        Phone number
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ color: "white", fontSize: "large", zIndex:"0"  }}>
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData.length ===0 ? (
                      <TableRow>
                        <TableCell
                          colSpan={6}
                          align="center"
                          sx={{ color: "black", fontSize: "large" }}>
                          <p>No data available</p>
                        </TableCell>
                      </TableRow>
                    ) : (
                    (rowsPerPage > 0
                      ? filteredData.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      : filteredData
                    ).map((row, index: number) => (
                      <TableRow>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center" >{row.Name}</TableCell>
                        <TableCell align="center">{row.Email}</TableCell>
                        <TableCell align="center">{row.phone_number}</TableCell>
                        <TableCell align="center">
                          <>
                            <button
                              className="icon-btn-edit"
                              onClick={() =>
                                editname(
                                  row._id.$oid,
                                  row.Name,
                                  row.phone_number,
                                  row.Email,
                                  row.Address.customeraddress1,
                                  row.Address.customeraddress2,
                                  row.Address.customeraddress3
                                )
                              }>
                              <Tooltip title="Edit" placement="bottom" arrow>
                                <IconButton>
                                  <EditIcon style={{ color: "black" }} />
                                </IconButton>
                              </Tooltip>
                            </button>
                            <button
                              className="icon-btn-edit"
                              onClick={() =>
                                deletename(row._id.$oid, row.Name ? row.Name.toString() : '')
                              }>
                              <Tooltip title="Delete" placement="bottom" arrow>
                                <IconButton>
                                  <DeleteOutlineIcon style={{ color: "red" }} />
                                </IconButton>
                              </Tooltip>
                            </button>
                          </>
                        </TableCell>
                      </TableRow>
                       )
                    ))}
                 
                  </TableBody>
                </Table>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: "All", value: -1 },
                      ]}
                      colSpan={4}
                      count={filteredData.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      slotProps={{
                        select: {
                          inputProps: {
                            "aria-label": "rows per page",
                          },
                          native: true,
                        },
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </TableContainer>
            </Paper>
          </div>
    )
  }
  const editname = (
    id: string,
    name: string,
    phonenumber: string,
    Email: string,
    Address1: string,
    Address2: string,
    Address3: string
  ) => {
    setopenid(id);
    setName(name);
    setphonenumber(phonenumber);
    setemail(Email);
    setaddress(Address1);
    setaddress2(Address2);
    setaddress3(Address3);
    setdata(!data);
    setopenscreen(!openscreen);
  };
  const deletename = (id: string, name: string) => {
    setopenid(id);
    setName(name);
    setdata(!data);
    setdeletescreen(!deletescreen);
  };
  // const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   let value = event.target.value
  //   value = value.replace(/\D/g, '');
  //   if (value.length > 10) {
  //     value = value.slice(0, 10);
  //   }
  //   if (value.length > 5) {
  //     value = value.slice(0, 5) + ' ' + value.slice(5);
  //   }
  //   event.target.value = value;
  //   setPhonenumber(value);
  // }
  const menu = {
    Customer: "customer",
  };

  return (
    <div>
      <Header Header="Settings - Customers" />
      <div style={{ display: "flex" }}>
        <Menu />
        <div className="Customer_data">
          <Editmenu editmenu={menu} />
          <div className="category-add">
            <TextField
              value={searchTerm}
              id="Search-Phone-number"
              placeholder="Search by Name or Phone number"
              // label={ "Search by Name or Phone number"}
              variant="outlined"
              sx={{ width: 400, }}
              type="telphone"
              onChange={handleSearch}
            />
          </div>
          {tableformet()}
          {openscreen && (
            <Editcustomer
              id={openid}
              phone={phonenumber}
              name={Name}
              email={email}
              address={address}
              address2={address2}
              address3={address3}
            />
          )}
          {deletescreen && <Deletescreen name={Name} id={openid} />}
        </div>
      </div>
    </div>
  );
};
export default CustomerData;
