import React, { useEffect, useState } from "react";
import "./CarMessage.css";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PrintIcon from "@mui/icons-material/Print";
import Button from "@mui/material/Button";
import { useLocation } from "react-router-dom";
import { log } from "node:console";
import { useNavigate } from "react-router-dom";
// import CarHeaderBox from "./CarHeaderBox";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import api from "../../axiosConfig";
import CarMenuBox from "./CarMenuBox";
import CarHeaderBox from "./CarHeaderBox";

interface CarDetail {
  _id: { $oid: string };
  business_type: string;
  car_number: string;
  createdBy: string;
  createdOn: { $date: string };
  modifiedBy: string;
  modifiedOn: { $date: string };
  organization_id: { $oid: string };
  organization_name: string;
  payment: {
    paymentMethod: string;
  };
  customerDedails: {
    Date: string;
    cusCar: string[];
    cusName: string;
    cusPhone: string | number;
    totalPrice: string;
    Discount?: number;
    Balence?: number;
    productTotal: string[];
  };
  customername: string;
  customerphone: string | number;
  date: string;
  serviceDetails: Array<{
    carNumber: string;

    productDetails: Array<{
      cName: string;
      price: number;
      product: string;
    }>;
    totalPrice: number | string;
  }>;
}

const CarPrinter = () => {
  const [orderdata, setOrderdata] = useState<CarDetail | null>(null);
  const [showPrintContent, setShowPrintContent] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const categoryId = searchParams.get("categoryId");
  const navigate = useNavigate();

  useEffect(() => {
    console.log("it is fine", id);
    responce();
  }, []);

  const responce = () => {
    api.get(`/app/v1/pos/customer/${id}`).then((res) => {
      setOrderdata(res.data.result);
      console.log("printyeee", res);
    });
  };

  const printerdetails = () => (
    <div>
      <div className="Print_popup_carwash">
        <h3 style={{ fontFamily: "cursive" }}>Invoice</h3>
        {/* <hr style={{width:"99%",}}/> */}
        <div
          style={{
            padding: "3px",
            display: "grid",
            gridAutoFlow: "dense",
            gridTemplateRows: "auto auto auto",
            direction: "rtl",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "10px",
          }}
        >
          <div style={{ display: "flex" }}>
            <span>{orderdata?.date}</span>
            <p>:Date</p>
          </div>
          <div style={{ display: "flex" }}>
            <span>12569844</span>
            <p>:Order ID</p>
          </div>
        </div>
        <hr style={{ width: "99%", border: "1px dotted black" }} />
        <div
          style={{
            padding: "3px",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "5px",
          }}
        >
          <div style={{ display: "flex" }}>
            <p>Name:</p>
            <span>{orderdata?.customerDedails.cusName}</span>
          </div>
          <div style={{ display: "flex" }}>
            <p>Type:</p>
            <span>Cash</span>
          </div>
        </div>
        <div
          style={{
            padding: "3px",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div style={{ display: "flex" }}>
            <p>No.Of.Cars:</p>
            <span>
              {orderdata?.serviceDetails ? orderdata.serviceDetails.length : 0}
            </span>
          </div>
          <div style={{ display: "flex" }}>
            <p>Ph.No:</p>
            <span>{orderdata?.customerDedails.cusPhone}</span>
          </div>
        </div>
        <hr style={{ width: "99%" }} />
        <div style={{ width: "100%", marginTop: "5px" }}>
          <div
            style={{
              backgroundColor: "#e9eaea",
              display: "flex",
              justifyContent: "space-around",
            }}
          ></div>
          <hr style={{ width: "99%" }} />
          <div
            style={{
              display: "grid",
              width: "100%",
            }}
          >
            {orderdata?.serviceDetails.map((item) => (
              <div key={item.carNumber}>
                <h4 className="car_structure"> Car No: {item.carNumber}</h4>
                <div
                  style={{
                    backgroundColor: "#f4f4f4",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  ></div>
                </div>
                <div
                  style={{
                    backgroundColor: "#f4f4f4",
                    padding: "10px",
                    marginBottom: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#e9eaea",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <h4 style={{ position: "relative", right: "7px" }}>
                      Item(s)
                    </h4>
                    <h4 style={{ position: "relative", left: "30px" }}>Tax</h4>
                    <h4 style={{ position: "relative", left: "10px" }}>
                      Price
                    </h4>
                  </div>
                  <div>
                    {item.productDetails.map((product, index) => (
                      <div>
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div>{product.product}</div>
                          <div>{product.price}</div>
                        </div>
                      </div>
                    ))}
                    <hr style={{ width: "100%", marginTop: "10px" }} />
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>Sub Total</div>
                      <div>{item.totalPrice}</div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-around",
                    marginTop: "10px",
                  }}
                ></div>
              </div>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            <b style={{ width: "60%" }}>Total Price</b>
            <b>{orderdata?.customerDedails.totalPrice}</b>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            <b style={{ width: "60%" }}>Received Price</b>
            <b>{orderdata?.customerDedails.Discount}</b>
          </div>
          <hr style={{ width: "99%" }} />
          <div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around",
                marginTop: "5px",
              }}
            >
              <h2 style={{ width: "60%" }}>Grand Total</h2>
              <h2>{orderdata?.customerDedails.Balence}</h2>
            </div>
          </div>
          <hr style={{ width: "99%" }} />
        </div>
        <div className="footer">
          <i>Thank you !!! & Visit Again !!!</i>
          <b>Powered by iMetanic</b>
        </div>
      </div>
    </div>
  );

  const handlePrint = () => {
    setShowPrintContent(true);
    setTimeout(() => {
      window.print();
      setShowPrintContent(false);
      navigate("/v1/new-order");
    }, 0);
  };
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <CarHeaderBox Carheader={"Order Details"} />
      <div className="overbody-cash-payment-print">
        <div className="inside_image">
          <div style={{ display: "flex", width: "100%" }}>
            <div>
              <CarMenuBox />
            </div>
            <div style={{ margin: "0px 0px 0px 320px" }}>
              <div className="inside_image-message">
                <div className="pay-process-flow-check-message">
                  <div>
                    <div
                      onClick={handleBack}
                      style={{
                        position: "relative",
                        right: "240px",
                        top: "35px",
                      }}
                    >
                      <ArrowBackIcon
                        style={{ width: "70px", height: "30px" }}
                      />
                    </div>
                    <div>
                      <h1>Verify Your Preferred Option</h1>
                    </div>
                  </div>
                  <p className="description">
                    Choose how you would like to proceed with your order
                    details. You can either share them via WhatsApp or print
                    them directly.
                  </p>
                  <div className="icon-options">
                    <div className="icon-wrapper">
                      <WhatsAppIcon
                        className="whattsapp-icon"
                        style={{ height: "150px", width: "150px" }}
                      />
                    </div>
                    <div className="icon-wrapper">
                      <PrintIcon
                        className="print-icon"
                        style={{ height: "150px", width: "150px" }}
                      />
                    </div>
                  </div>
                  <div className="action-buttons">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => alert("Sharing via WhatsApp")}
                    >
                      Send via WhatsApp
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handlePrint}
                    >
                      Print Invoice
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showPrintContent && (
        <div className="print-section">{printerdetails()}</div>
      )}
    </div>
  );
};

export default CarPrinter;
