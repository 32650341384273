import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../axiosConfig';
import "./Ledger.css";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import { toast, ToastContainer } from 'react-toastify';
import Buttons from '../../common-component/Buttons';
import Header from '../../components/laundry/Header';
import Menu from '../../components/laundry/Menu';

interface Customerdata {
    customer: {
        _id: {
            $oid: any;
        }
        Email: string;
        Name: string;
        phone_number: string;
        Address: {
            customeraddress1: string;
            customeraddress2: string;
            customeraddress3: string;
        }
    }
    ledger: Array<{
        OrderDetails: Array<{
            Orderamount: string;
            Orderdate: string;
            ReceivedAmount: string;
            RemainingAmount: string;
            Total_price: string;
            Totalamount: string;
            order_status: string;
            orderid: string;
            discount: string;
            Ordertime: string;
        }>
        customer_id: string;
        customer_name: string;
        customer_phone_number: string;
        new_order_amount: string;
        total_outstanding_balance: string;
        outstanding_balance: string;
        pay_amount: string;
    }>
}

const LedgerData: React.FC = () => {
    const { CustomerId } = useParams();
    const [customerData, setCustomerData] = useState<Customerdata | null>(null);
    const [customersData, setcustomersData] = useState<Customerdata[]>([]);
    const [openpayment, setOpenpayment] = useState<boolean>(false);
    const [PayConfirm, setPayConfirm] = useState<string>('');
    const [amount, setamount] = useState<number>(0);
    const [page, setPage] = useState(0);
    const [payMethodOptions, setPayMethodOptions] = useState<{ cash: boolean, card: boolean }>({ cash: false, card: false });
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [time, setTime] = useState({
        hours: "",
        minutes: "",
        seconds: "",
        day: "",
        month: "",
        year: ""
    })
    const navigate = useNavigate();
    const [inputValue, setInputValue] = useState('0');
    useEffect(() => {
        date()
        console.log(CustomerId);
        api.get(`/pos/ledger/${CustomerId}`)
            .then((res) => {
                console.log(res);
                const customersDats: Customerdata[] = res.data;
                setcustomersData(customersDats);
                const customerData: Customerdata = res.data;
                setCustomerData(customerData);
                if (Array.isArray(customerData.ledger.map((item) => item.OrderDetails))) {
                    setcustomersData([customerData]);
                } else {
                    console.error("Order_Details is not an array", customerData.ledger.map((item) => item.OrderDetails));
                }
            })
            .catch((error) => {
                console.error("Error fetching ledger data:", error);
            });
        if (customerData && customerData.ledger.length > 0) {
            const lastOrder = customerData.ledger.flatMap(item => item.OrderDetails).slice(-1)[0];
            if (lastOrder) {
                const canPayCash = lastOrder.orderid === 'cash';
                const canPayCard = lastOrder.orderid === 'card';
                setPayMethodOptions({ cash: canPayCash, card: canPayCard });
            }
        }
    }, [CustomerId])
    useEffect(() => {
        document.title = "Outstanding Balance Details of Customer - Ledgers - iMetanic"
    })
    const Payment = (data: string) => {
        if (data === "Cash") {
            setPayConfirm('Cash');
        } else if (data === "Card") {
            setPayConfirm('Card');
        }
    }
    const PaymentConfirm = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setInputValue(value);
        if (value === '') {
            setInputValue('0');
            setamount(0);
        } else {
            const cleanedValue = value.startsWith('0') && value.length > 1
                ? value.replace(/^0+/, '')
                : value;
            const numericValue = Number(cleanedValue);
            const totalOutstandingBalance = customerData?.ledger.reduce(
                (sum, item) => sum + (parseFloat(item.total_outstanding_balance) || 0),
                0
            ) || 0;
            if (numericValue > totalOutstandingBalance) {
                setamount(totalOutstandingBalance);
                setInputValue(totalOutstandingBalance.toString());
            } else {
                setamount(numericValue);
                setInputValue(cleanedValue);
            }
        }
    };
    // if (amount >= 0 && amount <= Number(customerData?.ledger.map((item) => item.outstanding_balance))) {// }
    const handleFocus = () => {
        if (inputValue === '0') {
            setInputValue('');
        }
    };
    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const roundValue = (value: number | undefined) => {
        if (value === undefined) return 0;
        const num = Number(value);
        const thirdDecimal = Math.floor(num * 1000) % 10;
        return thirdDecimal >= 5
            ? (Math.ceil(num * 100) / 100).toFixed(2)
            : num.toFixed(2);
    };

    const handleChangeRowsPerPage = (event: { target: { value: string; }; }) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };
    const BButton = {
        Blue: "Blue",
        name: "Print",
    };
    const MButton = {
        Blue: "Blue",
        name: "pay",
    };
    const NButton = {
        Blue: "Blue",
        name: "pay",
    };
    const flattenedOrderDetails = useMemo(() => {
        if (!customerData) return [];
        return customerData.ledger.flatMap((ledgerItem) => ledgerItem.OrderDetails);
    }, [customerData]);
    const navigationprint = () => {
        navigate(`/v1/print/${CustomerId}`)
    }
    const handlePrint = () => {
        window.print();
    };


    // const oldreminingprice = (Res: any, order: any, discount: any) => {
    //     return (Res + discount - order)
    // }
    const date = () => {
        const currentDate = new Date();
        const year = String(currentDate.getFullYear());
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");
        const hours = String(currentDate.getHours()).padStart(2, "0");
        const minutes = String(currentDate.getMinutes()).padStart(2, "0");
        const seconds = String(currentDate.getSeconds()).padStart(2, "0");

        setTime({
            hours: hours,
            minutes: minutes,
            seconds: seconds,
            day: day,
            month: month,
            year: year,
        })
    }

    // const statusupdate = customersData?.map(i=>i.ledger.map(i=>i.OrderDetails.map(i=>i.order_status)))

    const updateamount = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");

        // Get the most recent order or any specific order from the ledger
        const latestOrder = customerData?.ledger.flatMap((item) => item.OrderDetails).slice(-1)[0];
        const osbs = () => {
            let osb = Number(customerData?.ledger.map((item) => item.total_outstanding_balance));
            return osb;
        }

        if (latestOrder && latestOrder.orderid) {
            const customerupdate = {
                id: customerData?.customer._id.$oid,
                outstanding_balance: osbs(),
                orderamount: "0",
                pay_amount: amount,
                order_id: PayConfirm || "Payment only",
                payment_method: PayConfirm, Orderdate: `${time.year}-${time.month}-${time.day}`,
                Ordertime: `${time.hours}:${time.minutes}`,
            }
            api.post(`/pos/ledger`, customerupdate)
                .then((res) => {
                    console.log(res);
                    toast.success("Price received successfully", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored"
                    });
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                })
                .catch((error) => {
                    console.error("Error updating price:", error);
                });
        }
    };
    const displayedRows = rowsPerPage === -1
        ? flattenedOrderDetails
        : flattenedOrderDetails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    return (
        <div style={{ width: "100%", height: '80%' }}>
            <Header Header='Ledger - Ledger details' />
            <div style={{ display: 'flex' }}>
                <Menu />
                <div className='ledgerdisplay'>
                    <h1 className='balance_customer' style={{ color: "gray" }}> Outstanding Balance Details of Customer</h1>
                    <div className='ledger_first'>
                        <div className='ledger_second'>
                            <h2 style={{ display: "flex", justifyContent: "center", height: "30px" }}>Customer details</h2>
                            <div>
                                {customerData && (
                                    <ul className='Name_details'>
                                        <li>
                                            <h3>Name:</h3><h3>{customerData.customer.Name}</h3>
                                        </li>
                                        <li>
                                            <h3>Phone number:</h3><h3>{customerData.customer.phone_number}</h3>
                                        </li>
                                        <li>
                                            <h3>Email:</h3><h3>{customerData.customer.Email}</h3>
                                        </li>
                                        <li>
                                            <h3>Address:</h3>
                                            <h3>
                                                {customerData.customer.Address.customeraddress1}
                                                {customerData.customer.Address.customeraddress2 && `, ${customerData.customer.Address.customeraddress2}`}
                                                {customerData.customer.Address.customeraddress3 && `, ${customerData.customer.Address.customeraddress3}`}
                                            </h3>
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </div>
                        <div className='ledger_third'>
                            <div className='balance_structure'>
                                <div className='out_bala'>
                                    <h3 className='outstanding_alignment'>Outstanding balance (QAR)</h3> <h2 style={{ marginLeft: '45px', fontSize: '50px' }}>
                                        {roundValue(customerData?.ledger.reduce((sum, item) => sum + (parseFloat(item.total_outstanding_balance ?? '0') || 0), 0))}</h2>
                                </div>
                            </div>
                            <div className='button_alignment'>
                                {/* <div onClick={handlePrint}>
                                    <Buttons Buttondata={BButton} />
                                </div> */}
                                {/* <button className='pay_button_ledger' onClick={() => setOpenpayment(true)}>Pay</button> */}
                                <div onClick={() => setOpenpayment(true)}>
                                    <Buttons Buttondata={MButton} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='customer_Table_ledger'>
                        <Paper sx={{ width: "93%", marginLeft: "40px", }}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center"
                                                sx={{ color: "white", fontSize: "large" }}>Order ID</TableCell>
                                            <TableCell align='center'
                                                sx={{ color: "white", fontSize: "large" }}>Order status</TableCell>
                                            <TableCell align="center"
                                                sx={{ color: "white", fontSize: "large" }}>Date & Time</TableCell>
                                            <TableCell align="center"
                                                sx={{ color: "white", fontSize: "large" }}>Old Remaining price (QAR)</TableCell>
                                            <TableCell align="center"
                                                sx={{ color: "white", fontSize: "large" }}>Order price (QAR)</TableCell>

                                            {/* <TableCell align="center"
                                                sx={{ color: "white", fontSize: "large" }}>Old Remaining price (QAR)</TableCell> */}
                                            <TableCell align="center"
                                                sx={{ color: "white", fontSize: "large" }}>Discount (QAR)</TableCell>
                                            <TableCell align="center"
                                                sx={{ color: "white", fontSize: "large" }}>Total price (QAR)</TableCell>
                                            <TableCell align="center"
                                                sx={{ color: "white", fontSize: "large" }}>Received price (QAR)</TableCell>
                                            <TableCell align="center"
                                                sx={{ color: "white", fontSize: "large" }}>Remaining price (QAR)</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className='table_body'>
                                        {displayedRows
                                            .map((orderDetail, index) => (
                                                <TableRow key={index}>
                                                    <TableCell
                                                        align="center"
                                                        style={{
                                                            color: orderDetail?.order_status === "Canceled" ? "red" :
                                                                (orderDetail?.orderid !== 'Cash' && orderDetail?.orderid !== 'Card' ? 'blue' : 'black'),
                                                            cursor: orderDetail?.orderid !== 'Cash' && orderDetail?.orderid !== 'Card' ? 'pointer' : 'default',
                                                        }}
                                                        onClick={() => {
                                                            if (orderDetail.orderid === "Card" || orderDetail.orderid === "Cash") {
                                                                //navigate(`/v1/order-details/${orderDetail.orderid}`);
                                                            } else {
                                                                navigate(`/v1/order-details/${orderDetail.orderid}`);
                                                            }
                                                        }}
                                                    >
                                                        {orderDetail.orderid}
                                                    </TableCell>
                                                    <TableCell
                                                        align="center"
                                                        style={{ color: orderDetail?.order_status === "Canceled" ? "red" : "auto" }}
                                                    >
                                                        {orderDetail?.order_status}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ color: orderDetail?.order_status === "Canceled" ? "red" : "auto" }}>{orderDetail?.Orderdate}</TableCell>
                                                    <TableCell align="center" style={{ color: orderDetail?.order_status === "Canceled" ? "red" : "auto" }}>{orderDetail?.Ordertime}</TableCell>
                                                    <TableCell align="center" style={{ color: orderDetail?.order_status === "Canceled" ? "red" : "auto" }}>{roundValue(orderDetail?.Orderamount ? parseFloat(orderDetail.Orderamount) : undefined)}</TableCell>
                                                    {/* <TableCell align="center" style={{ color: orderDetail?.order_status === "Canceled" ? "red" : "auto" }}>{roundValue(oldreminingprice(orderDetail.Total_price, orderDetail.Orderamount, orderDetail.discount))}</TableCell> */}
                                                    <TableCell align="center" style={{ color: orderDetail?.order_status === "Canceled" ? "red" : "auto" }}>{roundValue(orderDetail?.discount ? parseFloat(orderDetail.discount) : undefined)}</TableCell>
                                                    <TableCell align="center" style={{ color: orderDetail?.order_status === "Canceled" ? "red" : "auto" }}>{roundValue(orderDetail?.Total_price ? parseFloat(orderDetail.Total_price) : undefined)}</TableCell>
                                                    <TableCell align="center" style={{ color: orderDetail?.order_status === "Canceled" ? "red" : "auto" }}>{roundValue(orderDetail?.ReceivedAmount ? parseFloat(orderDetail.ReceivedAmount) : undefined)}</TableCell>
                                                    <TableCell align="center" style={{ color: orderDetail?.order_status === "Canceled" ? "red" : "auto" }}>{roundValue(orderDetail?.RemainingAmount ? parseFloat(orderDetail.RemainingAmount) : undefined)}</TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                                count={flattenedOrderDetails.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                                labelRowsPerPage="Rows per page:"
                                labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}
                            />
                        </Paper>
                    </div>
                    <div>
                        {openpayment && <div style={{ width: "400px" }}></div>}
                    </div>
                    {openpayment && <div className='payment'>
                        <div>
                            <h2 > <ArrowBackIcon onClick={() => { setOpenpayment(false); setInputValue('0'); setamount(0); }} />Repayment</h2>
                            <ul>
                                <h3>Payment methods: </h3>
                                <li>
                                    <p>
                                        <input type="radio" name="payment" value="card" onClick={() => Payment("Card")} />
                                        <label>Card</label>
                                    </p>
                                    <p>
                                        <input type="radio" name="payment" value="cash" onClick={() => Payment("Cash")} />
                                        <label>Cash</label>
                                    </p>
                                </li>
                            </ul>
                            {customerData?.ledger.map((i) =>
                                parseFloat(i.total_outstanding_balance) > 0 ? (
                                    PayConfirm && (
                                        <dd className='Payment_section'>
                                            <h1>{PayConfirm} :</h1>
                                            <ul>
                                                <li>
                                                    <h3>Received price :</h3>
                                                    <input value={inputValue} type="number" onChange={PaymentConfirm} onFocus={handleFocus} style={{ borderRadius: '10px',width:'100px'}} />
                                                </li>
                                                <li>
                                                    <button onClick={() => { setOpenpayment(false); setInputValue('0'); setamount(0); }}
                                                        style={{ backgroundColor: '#719df3', borderRadius: '20px', width: '120px', height: '40px', fontSize: 'larger', color: 'white', cursor: 'pointer', border: 'none' }}
                                                    >CANCEL</button>
                                                    <button onClick={updateamount}
                                                        style={{ backgroundColor: '#719df3', borderRadius: '20px', width: '120px', height: '40px', fontSize: 'larger', color: 'white', cursor: 'pointer', border: 'none' }}
                                                    >PAY</button>
                                                </li>
                                            </ul>
                                        </dd>
                                    )
                                ) : (
                                    <p className="no-balance-message">You have no outstanding balance</p>
                                )
                            )}
                        </div>
                    </div>}
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default LedgerData