import React, { useEffect, useRef, useState } from "react";
import "./App.css"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { IconButton } from "@mui/material";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ViewListIcon from '@mui/icons-material/ViewList';
import InventoryIcon from "@mui/icons-material/Inventory";
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Logo from "../../common-component/Logo";
import LoadingScreen from "../../common-component/LoadingScreen";
// import LoadingScreen from "../../common-component/LoadingScreen";

function Menu() {
  const [clickmenu, setClickMenu] = useState(false);
  const [Resclickmenu, setResClickMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [employees, setEmployees] = useState<boolean>(false);
  const openMenu = useRef<HTMLDivElement>(null);
  const [background, setbackground] = useState<any>("");
  const [overlayMenu, setOverlayMenu] = useState<boolean>(false);
  const overlayRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setLoading(true);
    const business = sessionStorage.getItem("organization_name")
    const storedColor = sessionStorage.getItem(`background_color${business}`);
    setData(business)
    const employee_login = sessionStorage.getItem("employee")
    if (employee_login) {
      console.log('employee_login');
      setEmployees(true)
    } else {
      setEmployees(false)
      console.log('not employee_login');
    }
    
    if (storedColor) {
      setbackground(storedColor);
      document.documentElement.style.setProperty('--color', storedColor);
    }
    const fontfamily = sessionStorage.getItem(`font_family${business}`);
    if (fontfamily) {
      document.documentElement.style.setProperty('--fontfamily', fontfamily);
    }
    setLoading(!true);
  }, [background])

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (openMenu.current && !openMenu.current.contains(event.target as Node)) {
        setClickMenu(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    function handleClickOutsideOverlay(event: MouseEvent) {
      if (overlayRef.current && !overlayRef.current.contains(event.target as Node)) {
        setOverlayMenu(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutsideOverlay);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideOverlay);
    };
  }, []);
  const handleDrawerToggle = () => {
    console.log("drawer toggle");
    setOverlayMenu(true);
  }

  return (
    <div style={{ marginTop: "-60px" }}>
      <div className="menu-container" id="menu_bars">
        <div
          style={{
            width: clickmenu ? "70px" : "120px",
            height: "100vh",
            display: "flex",
            backgroundColor: "#e1e1e1",
            borderTopRightRadius: clickmenu ? "30px" : "25px",
            borderBottomRightRadius: clickmenu ? "30px" : "25px"
          }}
        >
          {clickmenu ? (
            <menu
            style={{ width: "60px", display: "flex", flexDirection: "column", marginTop: '10px', alignItems: "center" }}
          >
            <div style={{ width: '70px', height: '70px', borderRadius: '60px',marginLeft: "10px" }}>
              <Logo />
            </div>
            {employees ? <Link to={"/v1/employee/home"} className="menu_selected">
              <Tooltip title="Home" placement="left" arrow>
                <IconButton>
                  <HomeIcon
                    className="menu-icon"
                    id="Home"
                    style={{ fontSize: "30px" }}
                  />
                </IconButton>
              </Tooltip>
            </Link> : <Link to={"/v1/home"} className="menu_selected">
              <Tooltip title="Home" placement="left" arrow>
                <IconButton >
                  <HomeIcon
                    className="menu-icon"
                    id="Home"
                    style={{ fontSize: "30px" }}
                  />
                </IconButton>
              </Tooltip>
            </Link>}
            {employees ? '' : <Link to={"/v1/dashboard"} className="menu_selected" >
              <Tooltip title="Dashboard" placement="left" arrow>
                <IconButton >
                  <DashboardRoundedIcon
                    className="menu-icon"
                    id="Inventory_icon"
                    style={{ fontSize: "30px" }}
                  />
                </IconButton>
              </Tooltip>
            </Link>}
            {employees ? <Link to={"/v1/employee/order"} className="menu_selected">
              <Tooltip title="orders" placement="left" arrow>
                <IconButton>
                  <ViewListIcon
                    className="menu-icon"
                    style={{ fontSize: "30px" }}
                  />
                </IconButton>
              </Tooltip>
            </Link> : <Link to={"/v1/order"} className="menu_selected">
              <Tooltip title="orders" placement="left" arrow>
                <IconButton>
                  <ViewListIcon
                    className="menu-icon"
                    style={{ fontSize: "30px" }}
                  />
                </IconButton>
              </Tooltip>
            </Link>}
            {/* {employees ? <Link to={"/v1/employee/cart"} id="Shoppingcart_icon" className="menu_selected">
              <Tooltip title="Shopping Cart" placement="left" arrow>
                <IconButton>
                  <ShoppingCartIcon
                    className="menu-icon"
                    style={{ fontSize: "30px" }}
                  />
                </IconButton>
              </Tooltip>
            </Link> : <Link to={"/v1/cart"} id="Shoppingcart_icon" className="menu_selected">
              <Tooltip title="Shopping Cart" placement="left" arrow>
                <IconButton>
                  <ShoppingCartIcon
                    className="menu-icon"
                    style={{ fontSize: "30px" }}
                  />
                </IconButton>
              </Tooltip>
            </Link>} */}
            {employees ? <Link to={"/v1/employee/ledger"} id="Ledger_icon" className="menu_selected">
              <Tooltip title="Ledger" placement="left" arrow>
                <IconButton>
                  <ImportContactsIcon
                    className="menu-icon"
                    style={{ fontSize: "30px" }}
                  />
                </IconButton>
              </Tooltip>
            </Link> : <Link to={"/v1/ledger"} id="Ledger_icon" className="menu_selected">
              <Tooltip title="Ledger" placement="left" arrow>
                <IconButton>
                  <ImportContactsIcon
                    className="menu-icon"
                    style={{ fontSize: "30px" }}
                  />
                </IconButton>
              </Tooltip>
            </Link>}
            {/* {employees ? '' : <Link to={"/v1/stock"} id="stock_icon" className="menu_selected">
              <Tooltip title="Stock" placement="left" arrow>
                <IconButton>
                  <InventoryIcon
                    className="menu-icon"
                    style={{ fontSize: "30px" }}
                  />
                </IconButton>
              </Tooltip>
            </Link>} */}
            {employees ? '' : <Link to={"/v1/edit/location"} id="settings_icon" className="menu_selected">
              <Tooltip title="Settings" placement="left" arrow>
                <IconButton>
                  <SettingsSuggestIcon
                    className="menu-icon"
                    style={{ fontSize: "30px" }}
                  />
                </IconButton>
              </Tooltip>
            </Link>}
          </menu>
          ) : (
            <menu style={{ width: '110px', marginTop: '10px', display: 'flex', flexDirection: 'column' }} ref={openMenu}>
            {data && (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                <div style={{ width: '70px', height: '70px', borderRadius: '60px',marginRight: "5px" }}>
                  <Logo />
                </div>
                <h3 style={{ marginBottom: '10px', width: '100px', paddingLeft: "5px", marginRight: '-10px' }}><i>{data}</i></h3>
                {employees ? <Link to={"/v1/employee/home"} className="menu_selecteds" >
                  <IconButton >
                    <HomeIcon
                      className="menu-icons"
                      style={{ fontSize: "30px", color: background }}
                      id="Inventory_icon"
                    />
                  </IconButton>
                  <p >Home</p>
                </Link> : <Link to={"/v1/home"} className="menu_selecteds" >
                  <IconButton >
                    <HomeIcon
                      className="menu-icons"
                      style={{ fontSize: "30px", color: background }}
                      id="Inventory_icon"
                    />
                  </IconButton>
                  <p >Home</p>
                </Link>}
                {employees ? '' : <Link to={"/v1/dashboard"} className="menu_selecteds" >
                  <IconButton >
                    <DashboardRoundedIcon
                      className="menu-icons"
                      style={{ fontSize: "30px", color: background }}
                      id="Inventory_icon"
                    />
                  </IconButton>
                  <p >Dashboard</p>
                </Link>}
                {employees ? <Link to={"/v1/employee/order"} className="menu_selecteds">
                  <IconButton>
                    <ViewListIcon
                      className="menu-icons"
                      style={{ fontSize: "30px" }}
                    />
                  </IconButton>
                  <p>Orders</p>
                </Link> : <Link to={"/v1/order"} className="menu_selecteds">
                  <IconButton>
                    <ViewListIcon
                      className="menu-icons"
                      style={{ fontSize: "30px" }}
                    />
                  </IconButton>
                  <p>Orders</p>
                </Link>}
                {/* {employees ? <Link to={"/v1/employee/cart"} id="Shoppingcart_icon" className="menu_selecteds">
                  <IconButton>
                    <ShoppingCartIcon
                      className="menu-icons"
                      style={{ fontSize: "30px" }}
                    />
                  </IconButton>
                  <p>Shopping Cart</p>
                </Link> : <Link to={"/v1/cart"} id="Shoppingcart_icon" className="menu_selecteds">
                  <IconButton>
                    <ShoppingCartIcon
                      className="menu-icons"
                      style={{ fontSize: "30px" }}
                    />
                  </IconButton>
                  <p>Shopping Cart</p>
                </Link>} */}
                {employees ? <Link to={"/v1/employee/ledger"} id="Ledger_icon" className="menu_selecteds">
                  <IconButton>
                    <ImportContactsIcon
                      className="menu-icons"
                      style={{ fontSize: "30px" }}
                    />
                  </IconButton>
                  <p>Ledger</p>
                </Link> : <Link to={"/v1/ledger"} id="Ledger_icon" className="menu_selecteds">
                  <IconButton>
                    <ImportContactsIcon
                      className="menu-icons"
                      style={{ fontSize: "30px" }}
                    />
                  </IconButton>
                  <p>Ledger</p>
                </Link>}
                {/* {employees ? '' : <Link to={"/v1/stock"} id="stock_icon" className="menu_selecteds">
                  <IconButton>
                    <InventoryIcon
                      className="menu-icons"
                      style={{ fontSize: "30px" }}
                    />
                  </IconButton>
                  <p>Stock Maintenance</p>
                </Link>} */}
                {employees ? '' : <Link to={"/v1/edit/location"} id="settings_icon" className="menu_selecteds">
                  <IconButton>
                    <SettingsSuggestIcon
                      className="menu-icons"
                      style={{ fontSize: "30px" }}
                    />
                  </IconButton>
                  <p>Settings</p>
                </Link>}
              </div>
            )}
          </menu>
          )}
          <button
            style={{
              marginTop: "50px",
              width: "20px",
              height: "20px",
              marginLeft: clickmenu ? "-2px" : '-5px',
              backgroundColor: "white",
              borderRadius: "20px",
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              border: 'none'
            }}
            id="Expandbtn"
            onClick={() => setClickMenu(!clickmenu)}
          >
            {clickmenu ? <ArrowForwardIosIcon style={{ fontSize: "20px",marginLeft: "-2px"}} /> : <ArrowBackIosIcon style={{ fontSize: "20px",marginLeft: "5px"}} />}
          </button>
        </div>
      </div>
      <div className="menubaricon">
        <IconButton onClick={handleDrawerToggle}>
          {overlayMenu ? (
            <CloseIcon style={{ fontSize: "30px",marginLeft: "-20px" }} />
          ) : (
            <MenuIcon style={{ fontSize: "30px" }} />
          )}
        </IconButton>
        {overlayMenu && (
          <div className="menuicons" ref={overlayRef}>
            <div className="overlay-content" id="menu_bars">
              <div
                style={{
                  width: clickmenu ? "60px" : "123px",
                  height: "160vh",
                  display: "flex",
                  backgroundColor: "#e1e1e1",
                  borderTopRightRadius: clickmenu ? "30px" : "30px",
                  borderBottomRightRadius: clickmenu ? "30px" : "30px",
                  borderRadius: clickmenu ? "30px" : "30px",
                  marginLeft:clickmenu ? "-6px" : "25px"
                }}
              >
                {clickmenu ? (
                  <menu
                  style={{ width: "60px", display: "flex", flexDirection: "column", marginTop: '-20px', alignItems: "center",borderRadius: "10px"}}
                 >
                  <div style={{ width: '70px', height: '70px', borderRadius: '60px',marginLeft:"18px" }}>
                    <Logo />
                  </div>
                  {employees ? <Link to={"/v1/employee/home"} className="menu_selected">
                    <Tooltip title="Home" placement="left" arrow>
                      <IconButton>
                        <HomeIcon
                          className="menu-icon"
                          id="Home"
                          style={{ fontSize: "30px" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Link> : <Link to={"/v1/home"} className="menu_selected">
                    <Tooltip title="Home" placement="left" arrow>
                      <IconButton >
                        <HomeIcon
                          className="menu-icon"
                          id="Home"
                          style={{ fontSize: "30px" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Link>}
                  {employees ? '' : <Link to={"/v1/dashboard"} className="menu_selected" >
                    <Tooltip title="Dashboard" placement="left" arrow>
                      <IconButton >
                        <DashboardRoundedIcon
                          className="menu-icon"
                          id="Inventory_icon"
                          style={{ fontSize: "30px" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Link>}
                  {employees ? <Link to={"/v1/employee/order"} className="menu_selected">
                    <Tooltip title="orders" placement="left" arrow>
                      <IconButton>
                        <ViewListIcon
                          className="menu-icon"
                          style={{ fontSize: "30px" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Link> : <Link to={"/v1/order"} className="menu_selected">
                    <Tooltip title="orders" placement="left" arrow>
                      <IconButton>
                        <ViewListIcon
                          className="menu-icon"
                          style={{ fontSize: "30px" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Link>}
                  {/*employees ? <Link to={"/v1/employee/cart"} id="Shoppingcart_icon" className="menu_selected">
                    <Tooltip title="Shopping Cart" placement="left" arrow>
                      <IconButton>
                        <ShoppingCartIcon
                          className="menu-icon"
                          style={{ fontSize: "30px" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Link> : <Link to={"/v1/cart"} id="Shoppingcart_icon" className="menu_selected">
                    <Tooltip title="Shopping Cart" placement="left" arrow>
                      <IconButton>
                        <ShoppingCartIcon
                          className="menu-icon"
                          style={{ fontSize: "30px" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Link>*/}
                  {employees ? <Link to={"/v1/employee/ledger"} id="Ledger_icon" className="menu_selected">
                    <Tooltip title="Ledger" placement="left" arrow>
                      <IconButton>
                        <ImportContactsIcon
                          className="menu-icon"
                          style={{ fontSize: "30px" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Link> : <Link to={"/v1/ledger"} id="Ledger_icon" className="menu_selected">
                    <Tooltip title="Ledger" placement="left" arrow>
                      <IconButton>
                        <ImportContactsIcon
                          className="menu-icon"
                          style={{ fontSize: "30px" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Link>}
                  {/* employees ? '' : <Link to={"/v1/stock"} id="stock_icon" className="menu_selected">
                    <Tooltip title="Stock" placement="left" arrow>
                      <IconButton>
                        <InventoryIcon
                          className="menu-icon"
                          style={{ fontSize: "30px" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Link>*/}
                  {employees ? '' : <Link to={"/v1/edit/location"} id="settings_icon" className="menu_selected">
                    <Tooltip title="Settings" placement="left" arrow>
                      <IconButton>
                        <SettingsSuggestIcon
                          className="menu-icon"
                          style={{ fontSize: "30px" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Link>}
                </menu>
                ) : (
                  <menu style={{ width: '120px', marginTop: '10px', display: 'flex', flexDirection: 'column', }} ref={openMenu}>
                    {data && (
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                        <div style={{ width: '70px', height: '70px', borderRadius: '60px' }}>
                          <Logo />
                        </div>
                        <h3 style={{ marginBottom: '10px', width: '100px', paddingLeft: "5px", marginRight: '-10px' }}><i>{data}</i></h3>
                        {employees ? <Link to={"/v1/employee/home"} className="menu_selecteds" >
                          <IconButton >
                            <HomeIcon
                              className="menu-icons"
                              style={{ fontSize: "30px", color: background }}
                              id="Inventory_icon"
                            />
                          </IconButton>
                          <p >Home</p>
                        </Link> : <Link to={"/v1/home"} className="menu_selecteds" >
                          <IconButton >
                            <HomeIcon
                              className="menu-icons"
                              style={{ fontSize: "30px", color: background }}
                              id="Inventory_icon"
                            />
                          </IconButton>
                          <p >Home</p>
                        </Link>}
                        {employees ? '' : <Link to={"/v1/dashboard"} className="menu_selecteds" >
                          <IconButton >
                            <DashboardRoundedIcon
                              className="menu-icons"
                              style={{ fontSize: "30px", color: background }}
                              id="Inventory_icon"
                            />
                          </IconButton>
                          <p >Dashboard</p>
                        </Link>}
                        {employees ? <Link to={"/v1/employee/order"} className="menu_selecteds">
                          <IconButton>
                            <ViewListIcon
                              className="menu-icons"
                              style={{ fontSize: "30px" }}
                            />
                          </IconButton>
                          <p>Orders</p>
                        </Link> : <Link to={"/v1/order"} className="menu_selecteds">
                          <IconButton>
                            <ViewListIcon
                              className="menu-icons"
                              style={{ fontSize: "30px" }}
                            />
                          </IconButton>
                          <p>Orders</p>
                        </Link>}
                        {/*employees ? <Link to={"/v1/employee/cart"} id="Shoppingcart_icon" className="menu_selecteds">
                          <IconButton>
                            <ShoppingCartIcon
                              className="menu-icons"
                              style={{ fontSize: "30px" }}
                            />
                          </IconButton>
                          <p>Shopping Cart</p>
                        </Link> : <Link to={"/v1/cart"} id="Shoppingcart_icon" className="menu_selecteds">
                          <IconButton>
                            <ShoppingCartIcon
                              className="menu-icons"
                              style={{ fontSize: "30px" }}
                            />
                          </IconButton>
                          <p>Shopping Cart</p>
                        </Link>*/}
                        {employees ? <Link to={"/v1/employee/ledger"} id="Ledger_icon" className="menu_selecteds">
                          <IconButton>
                            <ImportContactsIcon
                              className="menu-icons"
                              style={{ fontSize: "30px" }}
                            />
                          </IconButton>
                          <p>Ledger</p>
                        </Link> : <Link to={"/v1/ledger"} id="Ledger_icon" className="menu_selecteds">
                          <IconButton>
                            <ImportContactsIcon
                              className="menu-icons"
                              style={{ fontSize: "30px" }}
                            />
                          </IconButton>
                          <p>Ledger</p>
                        </Link>}
                        {/*employees ? '' : <Link to={"/v1/stock"} id="stock_icon" className="menu_selecteds">
                          <IconButton>
                            <InventoryIcon
                              className="menu-icons"
                              style={{ fontSize: "30px" }}
                            />
                          </IconButton>
                          <p>Stock Maintenance</p>
                        </Link>*/}
                        {employees ? '' : <Link to={"/v1/edit/location"} id="settings_icon" className="menu_selecteds">
                          <IconButton>
                            <SettingsSuggestIcon
                              className="menu-icons"
                              style={{ fontSize: "30px" }}
                            />
                          </IconButton>
                          <p>Settings</p>
                        </Link>}
                      </div>
                    )}
                  </menu>
                )}
                <button
                  style={{
                    marginTop: "30px",
                    width: "20px",
                    height: "20px",
                    marginLeft: clickmenu ? "-3px" : '-5px',
                    backgroundColor: "white",
                    borderRadius: "20px",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    border: 'none',
                  }}
                  id="Expandbtn"
                  onClick={() => setClickMenu(!clickmenu)}
                >
                  {clickmenu ? <ArrowForwardIosIcon style={{ fontSize: "20px", marginLeft: '8px' }} /> : <ArrowBackIosIcon style={{ fontSize: "20px", marginLeft: "5px" }} />}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {loading ? <LoadingScreen /> : ''}
    </div>
  );
}

export default Menu;
