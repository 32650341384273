import React,{ useState, useEffect } from "react";
import { Flip, toast, ToastContainer } from "react-toastify";
import api from "../../../axiosConfig";
import Buttons from "../../../common-component/Buttons";

interface Props {
  id: string | undefined;
  name: string;
}

const DeleteScreen: React.FC<Props> = ({ id, name }) => {
  const [isDeleted, setIsDeleted] = useState(false);
  const [customer, setCustomer] = useState<any>(null);
  useEffect(() => {
    if (id){
      setCustomer({ id, name });
    }
  },[id, name]);
  const Deletescreen = () => {
    api.delete(`/app/v1/pos/customer/${id}`).then((res) => {
      toast.success("Customer deleted successfully", { 
        position: "top-right",
        autoClose: 2100,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Flip,
      });
      // setIsDeleted(true);
      setTimeout(() => {
        window.location.reload();
      }, 500);
      setCustomer(null);
    });
  };
  if (isDeleted) return null;
  const GButton = {
    Blue: "blue",
    name: "confirm",
  };
  const PButton = {
    Cancel: "red",
    name: "Cancel",
  };
  return (
    <div className="modals">
      <div className="overlays"></div>
      <div className="modal-contents">
        <div className="edit-popupscreen">
          <div className="addcategory-edit-product" style={{ marginTop: "-20px" }}>
            <h1 style={{ marginTop: "20px" }}>Confirm to delete</h1>
            {name && <p style={{ fontWeight: 'bold', marginTop: "15px" }}>Customer name : {name}</p>}
            <p style={{ marginTop: "20px" }}>Are you sure you want to delete this customer?</p>
            <div style={{ display: "flex", width: "400px" }}>
              <div className="btnconfirm-edit">
              <div
                onClick={() => window.location.reload()}
                style={{ marginRight: "5px" }}>
                <Buttons Buttondata={PButton} />
              </div>
              <div onClick={() => Deletescreen()}>
                <Buttons Buttondata={GButton} />
              </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default DeleteScreen;
