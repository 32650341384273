import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import IconButton from "@mui/material/IconButton";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import "./Home.css";
import { InputBase, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { useCallback } from 'react';
import api from "../../axiosConfig";

interface Props {
  Header: string;
}
interface Customerdata {
  customer: {
    Address: {
      customeraddress1: string;
      customeraddress2: string;
      customeraddress3: string;
    };
    Email: string;
    Name: string;
    phone_number: string;
    _id: {
      $oid: any;
    }
  }
  ledger: Array<{
    OrderDetails: Array<{
      Orderamount: string;
      Orderdate: string;
      ReceivedAmount: string;
      RemainingAmount: string;
      Totalamount: string;
      orderid: string;
    }>;
    customer_name: string;
    customer_phone_number: string;
    Email: string;
    total_outstanding_balance: string | null;
    pay_amount: string;
    outstanding_balance: string;
  }>
}

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#f1f1f1",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 22px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdf",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));
const Header: React.FC<Props> = ({ Header }) => {
  const [data, setData] = useState<string | null>("");
  const navigate = useNavigate();
  const [background, setBackground] = useState<string>("");
  const [businessName, setBusinessName] = useState<string>("");
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [fontcolor, setFontColor] = useState<string>("");
  const [profileDropdownVisible, setProfileDropdownVisible] = useState(false);
  const [businessDropdownVisible, setBusinessDropdownVisible] = useState(false);
  const [addBusinessDialogOpen, setAddBusinessDialogOpen] = useState(false);
  const [businessType, setBusinessType] = useState<string>("");
  const [customersData, setCustomersData] = useState<Customerdata[]>([]);
  const [filteredData, setFilteredData] = useState<Customerdata[]>([]);
  const profileDropdownRef = useRef<HTMLDivElement>(null);
  const businessDropdownRef = useRef<HTMLDivElement>(null);
  const [error, setError] = React.useState<string>("");
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [page, setPage] = React.useState(0);
  const [searchClicked, setSearchClicked] = React.useState(false);
  const [employeesData, setEmployeesData] = useState(false);

  const openEmailCompose = () => {
    const name = data;
    const email = 'metanic1234@gmail.com';
    const subject = 'Customer Feedback';
    const body = `Business Name: ${name}\nMy Feedback: `;
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    const newTab = window.open(mailtoLink, '_blank');
    if (newTab) {
      newTab.focus();
    } else {
      window.location.href = mailtoLink;
    }
  };

  useEffect(() => {
    document.title = 'Ledgers - iMetanic';
    api.get(`/pos/ledger`)
      .then((response) => {
        console.log(response);
        const customersdata: Customerdata[] = response.data.result;
        setCustomersData(customersdata);
        setFilteredData(customersdata);
        console.log('data', customersdata);
      });
    const employee = () => {
      const employeeData = sessionStorage.getItem('employee');
      setEmployeesData(Boolean(employeeData));
    };
    employee();
  }, []);

  const opencustomer_data = useCallback((id: string) => {
    if (employeesData) {
      navigate(`/v1/employee/ledger/${id}`);
    } else {
      navigate(`/v1/ledger/${id}`);
    }
  }, [employeesData, navigate]);

  useEffect(() => {
    const business = sessionStorage.getItem("organization_name");
    setBusinessName(String(business));

    const profileName = sessionStorage.getItem("Profilename");
    setData(profileName);

    const storedColor = sessionStorage.getItem(`background_color${business}`);
    if (storedColor) {
      setBackground(storedColor);
      document.documentElement.style.setProperty('--background-color', storedColor);
    }
    const fontcolor = sessionStorage.getItem(`font_color${business}`);
    if (fontcolor) {
      setFontColor(fontcolor);
      document.documentElement.style.setProperty('--font-color', fontcolor);
    }
    const fontfamily = sessionStorage.getItem(`font_family${business}`);
    if (fontfamily) {
      document.documentElement.style.setProperty('--fontfamily', fontfamily);
    }

    function handleClickOutside(event: MouseEvent) {
      if (event.target) {
        if (profileDropdownRef.current && !profileDropdownRef.current.contains(event.target as Node)) {
          setProfileDropdownVisible(false);
        }
      }
      if (businessDropdownRef.current && !businessDropdownRef.current.contains(event.target as Node)) {
        setBusinessDropdownVisible(false);
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, []);
  const Logout = () => {
    api.get(`/pos/logout`)
      .then((res) => {
        console.log(res.data);
        sessionStorage.removeItem(`Logo${businessName}`);
        sessionStorage.removeItem("jsonwebtoken");
        navigate('/');
      });
  };

  const handleAddBusinessClick = () => {
    setAddBusinessDialogOpen(true);
  };

  const handleDialogClose = () => {
    setAddBusinessDialogOpen(false);
    setBusinessType("");
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/[!@#$%^&?*<>|/{}():_+=";'.,[\]`-]/g, '');
    setSearchTerm(value);
    if (value.trim() === "") {
      setFilteredData(customersData);
      setError("");
      return;
    }
    const formattedValue = value.toLowerCase().trim();
    const filtered = customersData.filter((item) => {
      const nameMatch = item.customer.Name && item.customer.Name.toLowerCase().includes(formattedValue);
      const phoneMatch = item.customer.phone_number && item.customer.phone_number.toLowerCase().includes(formattedValue);
      return nameMatch || phoneMatch;
    });

    if (filtered.length === 0) {
      setError("No results found.");
    } else {
      setError("");
    }
    setFilteredData(filtered);
  };;

  const handleSearchClick = () => {
    setSearchClicked(true);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setSearchClicked(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const roundValue = (value: number | undefined) => {
    if (value === undefined) return 0;
    const num = Number(value);
    const thirdDecimal = Math.floor(num * 1000) % 10;
    return thirdDecimal >= 5
      ? (Math.ceil(num * 100) / 100).toFixed(2)
      : num.toFixed(2);
  };

  const tableformet = () => {
    if (filteredData === null) {
      return <h3>No data available</h3>;
    }
    return (
      <div >
        {/* <Paper sx={{ width: "90vw", marginLeft: "3px", marginTop: "5px" }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{ color: "white", fontSize: "large" }}
                  >S.No</TableCell>
                  <TableCell align="center"
                    sx={{ color: "white", fontSize: "large" }}>Name</TableCell>
                  <TableCell align="center"
                    sx={{ color: "white", fontSize: "large" }}>Phone number</TableCell>
                  <TableCell align="center"
                    sx={{ color: "white", fontSize: "large " }}>Outstanding balance</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className='table_body'>
                {filteredData.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={10} sx={{ textAlign: "center", padding: "16px" }}>
                      <p>No data available</p>
                    </TableCell>
                  </TableRow>
                ) : filteredData && filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                  <TableRow key={index} onClick={() => opencustomer_data(item.customer._id.$oid)}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{item.customer.Name}</TableCell>
                    <TableCell align="center">{item.customer.phone_number}</TableCell>
                    <TableCell align="center">{roundValue(item.ledger.reduce((sum, i) => sum + (parseFloat(i.total_outstanding_balance ?? '0') || 0), 0))}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            slotProps={{
              select: {
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              },
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Paper> */}
        <div style={{ width: "21.7vw", marginTop:"20px",overflowY:"auto",height:"250px"}}>
          {filteredData.length === 0 ? (
            <p>No data available</p>
          ) : (
            filteredData.map((item, index) => (
              <div key={index} onClick={() => opencustomer_data(item.customer._id.$oid)} className="inside_element">
                <p style={{width:"150px",cursor:"pointer",display:"flex",alignItems:"start",padding:"5px"}}>{item.customer.Name}</p>
                <p style={{width:"130px",cursor:"pointer",display:"flex",alignItems:"end",padding:"5px"}}>{item.customer.phone_number}</p>
              </div>
            ))
          )}
        </div>
      </div>
    )
  }

  // const handleAddBusinessSubmit = () => {
  //   console.log("Business Type Submitted: ", businessType);
  //   if (businessType) {
  //     toast.success("Business change successfully", {
  //       position: "top-center",
  //       autoClose: 2000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "colored",
  //       transition: Flip,
  //     });
  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 2000);

  //     handleDialogClose();
  //   } else {
  //     toast.error("Please select a business type.", {
  //       position: "top-center",
  //       autoClose: 2000,
  //     });
  //   }
  // };


  // const handleDropdownSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   const selectedType = event.target.value;
  //   setBusinessType(selectedType);
  //   setBusinessDropdownVisible(false);
  // };

  const toggleProfileDropdown = () => {
    setProfileDropdownVisible(!profileDropdownVisible);
    setBusinessDropdownVisible(false);
  };
  // const toggleBusinessDropdown = () => {
  //   setBusinessDropdownVisible(!businessDropdownVisible);
  //   setProfileDropdownVisible(false);
  // };

  return (
    <header className="head-name" id="head_name">
      <div className="com-header" id="com_header">
        <h2>{Header}</h2>
      </div>
      {/* <div className="heder_business_dropdown">
        <span className="input-label">Select business type</span>
        <input
          type="text"
          className="business-input"
          value={businessType}
          onChange={(e) => setBusinessType(e.target.value)}
        />
        <Button
          variant="contained"
          startIcon={<AddBusinessIcon />}
          style={{ cursor: "pointer", position: "absolute", marginLeft: "230px", backgroundColor: "transparent",color: "black", height: "28px"}}
          onClick={handleAddBusinessClick}
        ></Button>
      </div> */}
      <div className="grid-container">
        <div className='search_header'>
          <div className='search_box'>
            <TextField
              value={searchTerm}
              id="search_phone_number"
              placeholder="Ledger search by Name or Phone number"
              variant="outlined"
              style={{ width: '100%', height: "58px", background: "none" }}
              type="text"
              onChange={handleSearch}
              onClick={handleSearchClick}
              onFocus={handleSearchClick}
            />
          </div>
          {searchClicked && (
            <div ref={dropdownRef} className='table_container'>
              {tableformet()}
            </div>
          )}
        </div>
        <div className="heder_profiles">
          <div className="heder_profile" onClick={toggleProfileDropdown}>
            <IconButton>
              <AccountCircleIcon style={{ fontSize: "30px", color: background }} />
              <h4 style={{ width: '170px', color: 'black', display: 'flex' }}>{data}</h4>
              {profileDropdownVisible ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </IconButton>
            {profileDropdownVisible && (
              <div className="dropdown-content" ref={dropdownRef}>
                <Link to={"/v1/profile"} id="Profile">Profile </Link>
                <Link to='' onClick={openEmailCompose} id="feedback">Feedback</Link>
                <Link onClick={Logout} to={"/"} style={{ color: '#F14545' }} id="logout">Logout</Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
