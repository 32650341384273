import React, { useEffect, useState } from "react";
import "./CarSetting.css";
import { Box, Button, CircularProgress, IconButton, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { ToastContainer, toast } from "react-toastify";
import CarSettingMenu from "./CarSettingMenu";
import api from "../../../axiosConfig";
import Tables from "../../../common-component/Table";
// import CarHeaderBox from "../../../components/car-wash/CarHeaderBox";
import CarMenuBox from "../../../components/car-wash/CarMenuBox";
import CarHeaderBox from "../../../components/car-wash/CarHeaderBox";

interface CategoryButton {
  _id: { $oid: string };
  CategoryName: string;
}

interface Productbtn {
  _id: { $oid: string };
  Amount: string;
  ProductName: string;
  category_id: string;
}

function CarProducts() {
  const [loading, setLoading] = useState<boolean>(false);
  const [productName, setProductName] = useState<string>("");
  const [amount, setAmount] = useState<string>("");
  const [categoryId, setCategoryId] = useState<string>("");
  const [products, setProducts] = useState<Productbtn[]>([]);
  const [categories, setCategories] = useState<CategoryButton[]>([]);
  const [edit, setEdit] = useState<boolean>(false);
  const [id, setId] = useState<string>("");
  const [modal, setModal] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [modalAction, setModalAction] = useState<"add" | "edit" | "delete">("add");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [imageFile, setImageFile] = useState<File | null>(null);

  const fetchCategories = () => {
    setLoading(true);
    api.get("/app/v1/pos/category")
      .then((res) => {
        setCategories(res.data.result);
      })
      .catch(() => {
        toast.error("Failed to fetch categories", { theme: "colored" });
      })
      .finally(() => setLoading(false));
  };

  const fetchProducts = () => {
    setLoading(true);
    api.get("/app/v1/pos/product")
      .then((res) => {
        setProducts(res.data.result);
      })
      .catch(() => {
        toast.error("Failed to fetch products", { theme: "colored" });
      })
      .finally(() => setLoading(false));
  };

  const openModal = (action: "add" | "edit" | "delete", id?: string, name?: string, amount?: string, categoryId?: string) => {
    setModalAction(action);
    if (action === "edit") {
      setEdit(true);
      setId(id!);
      setProductName(name!);
      setAmount(amount!);
      setCategoryId(categoryId!);
    } else if (action === "delete") {
      setId(id!);
    } else {
      setProductName("");
      setEdit(false);
    }
    setModal(true);
  };

  const handleProductUpdate = () => {
    if (!productName.trim() || !amount.trim() || !categoryId.trim()) {
      toast.error("Please fill in all required fields", { theme: "colored" });
      return;
    }
    const isDuplicateName = products.some(
      (product) =>
        product.ProductName.trim().toLowerCase() === productName.trim().toLowerCase() &&
        (!edit || product._id.$oid !== id)
    );

    if (isDuplicateName) {
      toast.error("Product name already exists. Please choose a different name.", { theme: "colored" });
      return;
    }
    setLoading(true);
    const productData = { ProductName: productName, Amount: amount, category_id: categoryId };
    if (edit) {
      api.put(`/app/v1/pos/product/${id}`, productData)
        .then(() => {
          toast.success("Product updated successfully", { theme: "colored" });
          fetchProducts();
          setProducts(products.map(item =>
            item._id.$oid === id ? { ...item, ...productData } : item
          ));
        })
        .catch((error) => {
          console.error("Error updating product:", error);
          if (error.response) {
            toast.error(`${error.response.data.message || error.response.data}`, { theme: "colored" });
          } else {
            toast.error("An unexpected error occurred", { theme: "colored" });
          }
        })
        .finally(() => {
          setLoading(false);
          setModal(false);
          setProductName("");
          setAmount("");
          setCategoryId("");
          setEdit(false);
        });
    } else {
      api.post("/app/v1/pos/product", productData)
        .then((res) => {
          toast.success("Product added successfully", { theme: "colored" });
          fetchProducts();
          setProducts([...products, res.data]);
        })
        .catch((error) => {
          console.error("Error adding product:", error);
          if (error.response) {
            toast.error(`${error.response.data.message || error.response.data}`, { theme: "colored" });
          } else {
            toast.error("An unexpected error occurred", { theme: "colored" });
          }
        })
        .finally(() => {
          setLoading(false);
          setModal(false);
          setProductName("");
          setAmount("");
          setCategoryId("");
        });
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setImageFile(event.target.files[0]);
    }
  };

  const handleDelete = () => {
    const confirmDelete = window.confirm("Are you sure you want to delete this product?");
    if (confirmDelete) {
      setLoading(true);
      api.delete(`/app/v1/pos/product/${id}`)
        .then(() => {
          toast.success("Product deleted successfully", { theme: "colored" });
          fetchProducts();
        })
        .catch(() => {
          toast.error("Failed to delete product", { theme: "colored" });
        })
        .finally(() => setLoading(false));
      setModal(false);
    }
  };
  const Products = {
    titlesno: "S.No",
    titlename1: "Product Name",
    titlename2: "Amount",
    titlename3: "Category",
    titlename4: "Actions",
  
    S_no: products.map((_, index) => index + 1),
    bodyname1: products.map((product) => product.ProductName),
    bodyname2: products.map((product) => product.Amount),
    bodyname3: products.map((product) => {
      const category_id = product.category_id;
      const category = categories.find((cat) => cat._id.$oid === category_id);
      return category ? category.CategoryName : "Unknown";
    }),
    bodyname4: products.map((product) => (
      <>
        <Tooltip title="Edit">
          <IconButton
            onClick={() =>
              openModal(
                "edit",
                product._id.$oid,
                product.ProductName,
                product.Amount,
                product.category_id
              )
            }
          >
            <EditIcon className="action-icon" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            onClick={() => openModal("delete", product._id.$oid)}
            sx={{ color: "red" }}
          >
            <DeleteOutlineIcon className="action-icon" />
          </IconButton>
        </Tooltip>
      </>
    )),
  };


  // const Products = {
  //   titlesno: "S.No",
  //   titlename1: "Product name",
  //   titlename3: "Product price (QAR)",
  //   titlename4: "Category type",
  //   titlename5: "Actions",

  //   S_no: products.map((item, index) => index + 1),
  //   bodyname1: products.map((item, index) => item.ProductName),
  //   bodyname2: products.map((item, index) => item.Amount),
  //   bodyname3: products.map((item) => {
  //     const category_id = item.category_id;
  //     const category = buttonNames.find(
  //       (button) => button._id.$oid === category_id
  //     );
  //     return category ? category.CategoryName : "Unknown";
  //   }),
  //   bodyname5: products.map((item, index) => (
  //     <>
  //       <Tooltip title="Edit">
  //         <IconButton
  //           onClick={() =>
  //             openModal("edit", product._id.$oid, product.ProductName, product.Amount, product.category_id)
  //           }
  //         >
  //           <EditIcon className="action-icon" />
  //         </IconButton>
  //       </Tooltip>
  //       <Tooltip title="Delete">
  //         <IconButton onClick={() => openModal("delete", product._id.$oid)}>
  //           <DeleteOutlineIcon className="action-icon" sx={{ color: 'red' }} />
  //         </IconButton>
  //       </Tooltip>
  //     </>
  //   )),
  // };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const resetForm = () => {
    setProductName("");
    setAmount("");
    setCategoryId("");
  };

  useEffect(() => {
    document.title = "Products - Settings - iMetanic";
    fetchCategories();
    fetchProducts();
  }, []);

  return (
    <div className="home-container">
      <CarHeaderBox Carheader="Settings - Products" />
      <div style={{ display: 'flex' }}>
        <CarMenuBox />
        <div className="content-container">
          <CarSettingMenu carMenu={undefined} />
          <div className="user-container">
            <div>
              <Button
                variant="contained"
                color="primary"
                className="add-product-btn"
                onClick={() => {
                  setEdit(false);
                  setModal(true);
                }}
              >
                Add Product
              </Button>
            </div>
            {products.length === 0 ? (
            <p>No data available</p>
          ) : (
            <div className="product-tables">
              <Tables product={Products} />
            </div>
          )}
            {/* <TableContainer component={Paper} className="product-tables">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="table-header">S.No</TableCell>
                    <TableCell className="table-header">Product Name</TableCell>
                    <TableCell className="table-header">Amount</TableCell>
                    <TableCell className="table-header">Category</TableCell>
                    <TableCell className="table-header">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No products available
                      </TableCell>
                    </TableRow>
                  ) : (
                    (rowsPerPage === -1 ? products : products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
                      .map((product: Productbtn, index: number) => (
                        <TableRow key={product._id.$oid}>
                          <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                          <TableCell>{product.ProductName}</TableCell>
                          <TableCell>{product.Amount}</TableCell>
                          <TableCell>{categories.find(cat => cat._id.$oid === product.category_id)?.CategoryName}</TableCell>
                          <TableCell>
                            <Tooltip title="Edit">
                              <IconButton
                                onClick={() =>
                                  openModal("edit", product._id.$oid, product.ProductName, product.Amount, product.category_id)
                                }
                              >
                                <EditIcon className="action-icon" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <IconButton onClick={() => openModal("delete", product._id.$oid)}>
                                <DeleteOutlineIcon className="action-icon" sx={{ color: 'red' }} />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15, { label: "All", value: -1 }]}
                component="div"
                count={products.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer> */}
          </div>

          {modal && (
            <div className="carmodals">
              <div className="caroverlays">
                <div className="carmodal-contents">
                  <Box>
                    {modalAction === "delete" ? (
                      <>
                        <h3 style={{ margin: "0px 15px 22px", marginBottom: "50px" }}>
                          Are you sure you want to delete this product?
                        </h3>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <Button variant="outlined" onClick={() => setModal(false)} style={{ width: "45%" }}>Cancel</Button>
                          <Button variant="contained" onClick={handleDelete} style={{ width: "45%" }} disabled={loading}>
                            {loading ? <CircularProgress size={24} /> : "Confirm"}
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <h3 style={{ margin: "0px 150px 20px" }}>
                          {edit ? "Edit Product" : "Add Product"}
                        </h3>
                        <div style={{ display: "flex", flexDirection: "column", marginBottom: "30px" }}>
                          <TextField
                            label="Product Name"
                            variant="outlined"
                            fullWidth
                            value={productName}
                            onChange={(e) => setProductName(e.target.value)}
                            className="wide-textfield"
                            style={{ marginBottom: '16px', width: '420px' }}
                          />
                          <TextField
                            label="Amount"
                            variant="outlined"
                            fullWidth
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            className="wide-textfield"
                            style={{ marginBottom: '16px' }}
                          />
                          <TextField
                            select
                            label="Category"
                            value={categoryId}
                            onChange={(e) => setCategoryId(e.target.value)}
                            className="wide-textfield"
                            style={{ marginBottom: '16px' }}
                            fullWidth
                          >
                            {categories.map((category) => (
                              <MenuItem key={category._id.$oid} value={category._id.$oid}>
                                {category.CategoryName}
                              </MenuItem>
                            ))}
                          </TextField>
                          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                            <Button
                              variant="outlined"
                              onClick={() => {
                                resetForm();
                                setModal(false);
                                fetchProducts();
                              }}
                              style={{ width: "45%" }}
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleProductUpdate}
                              style={{ width: "45%" }}
                              disabled={loading}
                            >
                              {loading ? <CircularProgress size={24} /> : modalAction === "edit" ? "Update" : "Confirm"}
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                  </Box>
                </div>
              </div>
            </div>
          )}
          <ToastContainer position="top-center" autoClose={1500} />
        </div>
      </div>
    </div>
  );
}

export default CarProducts;
