import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Cart from "./pages/sub-page/cart-display/Cart";
import Dashboard from "./pages/sub-page/dashboard/Dashboard";
import Print from "./pages/components/laundry/Print";
import MasterHome from "./pages/components/registration/MasterHome"
import Ledger from "./pages/sub-page/ledger/Ledger";
import Stock from "./pages/sub-page/stock/Stock";
import BillofMaterial from "./pages/common-component/BillofMaterial";
import CartData from "./pages/sub-page/cart-display/CartData";
import LedgerData from "./pages/sub-page/ledger/LedgerData";
import CarProducts from "./pages/sub-page/settings/car-wash/CarProducts";
import CarCategorys from "./pages/sub-page/settings/car-wash/CarCategorys";
import CarCustomer from "./pages/sub-page/settings/car-wash/CarCustomer";
// import CarBranch from "./pages/sub-page/settings/car-wash/CarBranch";
import CarUsers from "./pages/sub-page/settings/car-wash/CarUsers";
import CarProfile from "./pages/sub-page/profile/car-wash/CarProfile";
import Home from "./pages/components/laundry/Home";
import OrderSearch from "./pages/sub-page/orders/laundry/OrderSearch";
import Profile from "./pages/sub-page/profile/laundry/Profile";
import OrderStatus from "./pages/sub-page/order-status/laundry/OrderStatus";
import OrderDisplay from "./pages/sub-page/orders/laundry/OrderDisplay";
import NotFound from "./pages/components/registration/NotFound";
import Demosheet from "./pages/sub-page/orders/laundry/Demosheet";
import CarWashHome from "./pages/components/car-wash/CarWashHome";
import CarOrder from "./pages/components/car-wash/CarOrder";
import CarOrderList from "./pages/sub-page/orders/car-wash/CarOrderList";
import CarOrderDetails from "./pages/sub-page/orders/car-wash/CarOrderDetails";
import CarWash from "./pages/components/car-wash/CarWash";
import CarPaymentDetails from "./pages/components/car-wash/CarPaymentDetails";
import CarPaymentType from "./pages/components/car-wash/CarPaymentType";
import CarPrinter from "./pages/components/car-wash/CarPrinter";
import CustomerData from "./pages/sub-page/settings/laundry/CustomerData";
import AddCategory from "./pages/sub-page/settings/laundry/AddCategory";
import AddProduct from "./pages/sub-page/settings/laundry/AddProduct";
import AddAgent from "./pages/sub-page/settings/laundry/AddAgent";
import SetPasswordNew from "./pages/components/registration/SetPasswordNew";
import Login from "./pages/components/registration/Login";
import Signup from "./pages/components/registration/Signup";
// import ForgotPasswordNew from "./pages/components/registration/ForgotPasswordNew";
import ResetPasswordNew from "./pages/components/registration/ResetPasswordNew";
import EditBranch from "./pages/sub-page/settings/laundry/EditBranch";
import Edit from "./pages/sub-page/settings/laundry/Edit";
import CarStatus from "./pages/sub-page/order-status/car-wash/CarStatus";
import CarBranch from "./pages/sub-page/settings/car-wash/CarBranch";
import ForgotPasswordNew from "./pages/components/registration/ForgotPasswordNew";



const isTokenValid = (): boolean => {
  const token = sessionStorage.getItem("jsonwebtoken");
  return !!token;
};
const busness_name = (): boolean => {
  const businessName = (sessionStorage.getItem("organization_name")) === "iMetanic";
  return !!businessName;
}
const ProtectedRoutesemployee: React.FC = () => {
  const tokenValid = isTokenValid();
  // const businessName = busness_name()
  if (!tokenValid) {
    return <Navigate to="/" />;
  } else {
    return (
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/cart/:cartid" element={<CartData />} />
        <Route path="/order" element={<OrderSearch />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/print/:orderid" element={<Print />} />
        <Route path="/order-status" element={<OrderStatus />} />
        <Route path="/order-details/:orderid" element={<OrderDisplay />} />
        <Route path="/ledger" element={<Ledger />} />
        <Route path="/ledger/:CustomerId" element={<LedgerData />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    )
  }
}
const ProtectedRoutes: React.FC = () => {
  const tokenValid = isTokenValid();
  const businessName = busness_name()
  if (!tokenValid) {
    return <Navigate to="/" />;
  } else {
    if (businessName) {
      return <Routes>
        <Route path="/home" element={<MasterHome />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    }
    else {
      return (
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/demo-sheet" element={<Demosheet />} />
          <Route path="/cart/:cartid" element={<CartData />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/order" element={<OrderSearch />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/print/:orderid" element={<Print />} />
          <Route path="/order-status" element={<OrderStatus />} />
          <Route path="/order-details/:orderid" element={<OrderDisplay />} />
          <Route path="/bill-of-material" element={<BillofMaterial />} />
          <Route path="/edit/*" element={<SettingRoutes />} />
          <Route path="/ledger" element={<Ledger />} /> 
          <Route path="/ledger/:CustomerId" element={<LedgerData />} />
          <Route path="/stock" element={<Stock />} />
          <Route path="/car-wash-home" element={<CarWashHome />} />
          <Route path="/new-order" element={<CarOrder/>} />
          <Route path="/order-list" element={<CarOrderList/>} />
          <Route path="/car-order-details/:orderid" element={<CarOrderDetails />} />
          <Route path="/car-status-list" element={<CarStatus/>} />
          <Route path="/car-setting/*" element={<CarsettingRoutes />} />
          <Route path="/car-profile" element={<CarProfile/>} />
          <Route path="/car-wash/:categoryId" element={<CarWash/>} />
          <Route path="/car-payment-cash" element={<CarPaymentDetails/>} />
          <Route path="/car-Payment" element={<CarPaymentType/>}/>
          <Route path="/car-message-conformation" element={<CarPrinter/>} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      );
    }
  }
};
const SettingRoutes: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path="/location" element={<Edit />} />
        <Route path="/add-agent" element={<AddAgent />} />
        <Route path="/add-product" element={<AddProduct />} />
        <Route path="/add-category" element={<AddCategory />} />
        <Route path="/customer-data" element={<CustomerData />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};
const CarsettingRoutes: React.FC = () => {
  return (
  <>
  <Routes>
    <Route path="/car-products" element={<CarProducts />}/>
    <Route path="/car-categorys" element={<CarCategorys />}/>
    <Route path="/car-customer" element={<CarCustomer />} />
    <Route path="/car-branch" element={<CarBranch />} />
    <Route path="/car-users" element={<CarUsers />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
  </>
  );
}
const App: React.FC = () => {
  const [employee, setemployee] = useState<boolean>(false)
  useEffect(() => {
    const check_on = () => {
      const employee_login = sessionStorage.getItem('employee')
      const employee_data = sessionStorage.getItem('Noemployee')
      if (employee_login) {
        setemployee(true)
      } else if(employee_data) {
        setemployee(false)
      }
    }
    check_on() 
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/imetanic/setpassword/:userid/:expiryTime/2580" element={<SetPasswordNew />} />
        <Route path="/" element={<Login />} />
        <Route path="/sign-up" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPasswordNew />} />
        <Route path="/reset-password/:token" element={<ResetPasswordNew />} />
        <Route path='/v1/employee/*' element={<ProtectedRoutesemployee />} /> 
         <Route path="/v1/*" element={<ProtectedRoutes />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/master-home" element={<MasterHome />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;