import { Padding } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
interface Props {
  Buttondata: any | undefined;
}
const Buttons: React.FC<Props> = ({ Buttondata }) => {
  const [background, setbackground] = useState<any>("");
  const [fontcolor, setfontcolor] = useState<any>("");
  const [fontfamily, setfontfamily] = useState<any>("");
  useEffect(() => {
    const bussness = sessionStorage.getItem("organization_name")
    const storedColor = sessionStorage.getItem(`background_color${bussness}`);
    const fontcolor = sessionStorage.getItem(`font_color${bussness}`);
    const fontfamily = sessionStorage.getItem(`font_family${bussness}`);
    if (storedColor ) {setbackground(storedColor);}
    if (fontcolor) {setfontcolor(fontcolor);}
    if (fontfamily) {setfontfamily(fontfamily);}
  }, [])
  return (
    <div>
      {Buttondata?.Cancel && (
        <Button
          style={{
            background: "none",
            border: `2px solid ${background}`,
            borderRadius: "40px",
            height: "40px",
            width: "190px",
            color: background,
            fontSize: "large",
          }}
        >
          <h3 style={{fontFamily:fontfamily}}>{Buttondata?.name}</h3>
        </Button>
      )}
      {Buttondata?.Confirm && (
        <Button
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: background,
            borderRadius: "40px",
            height: "40px",
            width: "auto",
            color: fontcolor,
            fontSize: "large",
          }}
        >
          <h3 style={{fontFamily:fontfamily}}>{Buttondata?.name}</h3>
        </Button>
      )}
      {Buttondata?.Blue && (
        <Button
          style={{
            backgroundColor: background,
            borderRadius: "40px",
            height: "40px",
            width: "auto",
            minWidth: "190px",
            color: fontcolor,
            fontWeight: "bold",
            fontSize: "large",
          }}
        >
          <h3 style={{fontFamily:fontfamily}}>{Buttondata?.name}</h3>
        </Button>
      )}
      {Buttondata?.error && (
        <Button
          disabled
          style={{
            backgroundColor: "#CDBDD3",
            borderRadius: "40px",
            borderWidth: "2px",
            height: "40px",
            width: "190px",
            color: "white",
            fontSize: "large",
            cursor: "unset",
            animation: "normal",
            fontFamily: fontfamily,
          }}
        >
          <h3 style={{fontFamily:fontfamily}}>{Buttondata?.name}</h3>
        </Button>
      )}
      {Buttondata?.nsuccess && (
        <button
        style={{
          fontFamily:fontfamily,
          backgroundColor: background,
          borderRadius: "40px",
          padding:'2px 20px',
          color:fontcolor,
          borderColor: background

        }}
        >
          <h3 style={{fontFamily:fontfamily}}>{Buttondata?.name}</h3>
        </button>
      )}
      {Buttondata?.ncancel && (
        <button
        style={{
          fontFamily:fontfamily,
          background:'none',
          borderRadius: "40px",
          padding:'2px 20px',
          color:background,
          borderColor: background
        }}
        >
          <h3 style={{fontFamily:fontfamily}}>{Buttondata?.name}</h3>
        </button>
      )}
    </div>
  );
};

export default Buttons;
