import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../axiosConfig";
import { Checkbox, FormControlLabel, IconButton, TextField } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Buttons from "../../common-component/Buttons";
import { Flip, ToastContainer, toast } from "react-toastify";
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from '@mui/icons-material/Check';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import money from "../../../assets/Images/1635d86b32b60583e7f022b9c3324b3d.png"
// import money from '../../../assets/Images/cash.png';
// import payimg from "../../../assets/Images/pay.png"
import payimg from '../../../assets/Images/pay.png';
import Header from "../../components/laundry/Header";
import Menu from "../../components/laundry/Menu";

interface Getdata {
  _id: {
    $oid: string;
  };
  Finalamount: number;
  products: Array<{
    productId: string;
    productName: string;
    amount: number;
    quantity: string;
    totalPrice: string;
  }>;
  Customerid: string;
  totalAmount: string;
  vat: string;
  discount: string;
  totalQuantity: string;
  cartId: string;
}
interface Customerdata {
  _id: {
    $oid: string;
  };
  Name: string;
  phone_number: string;
  Email: string;
  Out_standing_balance: string;
  Order_Details: Array<{
    ordernaviid: string;
    Orderdate: string;
    ReceivedAmount: string;
    RemainingAmount: string;
    orderid: string;
    Totalamount: string;
    Orderamount: string;
  }>;
  Address: {
    customeraddress1: string;
    customeraddress2: string;
    customeraddress3: string;
  };
}

interface Product {
  _id: {
    $oid: string;
  };
  Products: Array<{
    productName: string;
    amount: number;
    quantity: number;
    totalprice: number;
  }>;
  Orderdate: string;
  Ordertime: string;
  ReceivedAmount: number;
  RemainingAmount: number;
  Paymentmode: string;
  OrderStatus: string;
  discount: number;
  Finalamount: number;
  totalAmount: number;
  totalQuantity: number;
  Email: string;
  CustomerName: string;
  PhoneNumber: number;
  Orderid: string;
}
const CartData: React.FC = () => {
  const { cartid } = useParams<{ cartid: string }>();
  const [getdata, setGetdata] = useState<Getdata[]>([]);
  const [quantities, setQuantities] = useState<{ [productId: string]: string }>({});
  const [selectedProducts, setSelectedProducts] = useState<{ [productId: string]: boolean }>({});
  const [customerData, setCustomerData] = useState<Customerdata[]>([]);
  const [cust, setCust] = useState<Customerdata>();
  const [filteredData, setFilteredData] = useState<Customerdata[]>([]);
  const [customer, setcustomer] = useState<boolean>(true);
  const [payment, setPayment] = useState<boolean>(true);
  const [paylate, setPaylate] = useState<boolean>(false);
  const [paycard, setPaycard] = useState<boolean>(false);
  const [cashpay, setCashpay] = useState<boolean>(false);
  const [openpayment, setOpenpayment] = useState<boolean>(true);
  const [customerdata, setCustomerdata] = useState<boolean>(false);
  const [phonenumber, setPhonenumber] = useState<string>("");
  const [addcustomer, setAddcustomer] = useState<boolean>(false);
  const [background, setbackground] = useState<any>("");
  const [customername, setCustomername] = useState<string>("");
  const [customerphone, setCustomerphone] = useState<string>("");
  const [customeremail, setCustomeremail] = useState<string>("");
  const [confirmcemail, setconfirmcemail] = useState<string>("");
  const [emailerror, setemailerror] = useState<boolean>(false);
  const [customeraddress1, setCustomeraddress1] = useState<string>("");
  const [customeraddress2, setCustomeraddress2] = useState<string>("");
  const [customeraddress3, setCustomeraddress3] = useState<string>("");
  const [DeliveryDate, setDeliveryDate] = useState<string>();
  const [paylaterconfirm, setPaylaterconfirm] = useState<boolean>(false);
  const [paymentsuccess, setPaymentsuccess] = useState<boolean>(false);
  const [ordersucess, setOrdersucess] = useState<Product>();
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [orderconfirm, setorderconfirm] = useState<string>("")
  const [, setPaycardconfirm] = useState<number>();
  const [paycashconfirm, setcashconfirm] = useState<boolean>(false);
  const [receivedAmount, setreceivedAmount] = useState<number>()
  const [errorphonenumber, seterrorphonenumber] = useState<boolean>(false);
  const [ffamilys, setffamily] = useState<string>("")
  const [discount, setdiscount] = useState<number>()
  const [Delete_cart, setDelete_cart] = useState<boolean>(false);
  const [showBalance, setShowBalance] = useState<boolean>(false);
  const [Overlay, setOverlay] = useState<boolean>(false);
  useEffect(() => {
    const bussness = sessionStorage.getItem("organization_name")
    const storedColor = sessionStorage.getItem(`background_color${bussness}`);
    const ffamily = sessionStorage.getItem(`font_family${bussness}`);
    if (ffamily) {
      setffamily(ffamily)
      document.documentElement.style.setProperty('--fontfamily', ffamily);
    }

    if (storedColor) {
      setbackground(storedColor);
      document.documentElement.style.setProperty('--colors', storedColor);
    }
    setPaycardconfirm(30)
    if (cartid) {
      api
        .get(`/app/v1/pos/shopping_cart/${cartid}`)
        .then((res) => {
          const get: Getdata[] = res.data.result;
          setGetdata(get);
          const dis = get.map(item => item.discount)
          setdiscount(Number(dis));
          const cus = get.map(item => item.Customerid)
          if (cus) {
            api
              .get(`/app/v1/pos/customer/${cus}`)
              .then((res) => {
                const customer: Customerdata = res.data.result;
                console.log(res.data.result);
                setCust(customer)
              })
              .catch((error) => {
                console.error("Error fetching customer data:", error);
              });
          }
          const initialQuantities = get.reduce((acc, item) => {
            item.products.forEach(product => {
              acc[product.productId] = product.quantity;
            });
            return acc;
          }, {} as { [productId: string]: string });
          setQuantities(initialQuantities);
        })
        .catch((error) => {
          console.error("Error fetching cart data:", error);
        });
    }
    if (cashpay || paycard || paylate) {
      setOpenpayment(false);
    } else { setOpenpayment(true); }
    api.get(`/app/v1/pos/customer`)
      .then((res) => {
        const customerdatas: Customerdata[] = res.data.result;
        setCustomerData(customerdatas);
        setFilteredData(customerdatas);
      }).catch((error) => { });
    const currentDate = new Date();
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() + 5);
    const formatDate = (date: Date) => {
      const year = (date.getFullYear() + 1).toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    };
    setDeliveryDate(formatDate(newDate));
    setreceivedAmount(0)
  }, [cartid, cashpay, paycard, paylate]);
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setPaylaterconfirm(false)
        setcashconfirm(false)
        setCustomerdata(false)
        setCustomeraddress1("")
        setCustomeraddress2("")
        setCustomeraddress1("")
        setconfirmcemail("")
        setCustomeremail("")
        setCustomerphone("")
        setCustomername("")
        setAddcustomer(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };

  })
  const handleQuantityChange = (productId: string, amount: number) => (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (Number(value) >= 0 || value === "") {
      setQuantities({
        ...quantities,
        [productId]: value,
      });
    }
    const quantity = Number(value)
    update_shoppingcart(productId, quantity, amount);
  };
  const handleIncrement = (productId: string, amount: number) => () => {
    const newValue = Number(quantities[productId] || 0) + 1;
    setQuantities({
      ...quantities,
      [productId]: (Number(quantities[productId] || 0) + 1).toString(),
    });
    update_shoppingcart(productId, newValue, amount)
  };
  const handleDecrement = (productId: string, amount: number) => () => {
    const newValue = Number(quantities[productId] || 0) - 1;
    if (newValue > 0) {
      const qty = {
        ...quantities,
        [productId]: newValue.toString(),
      }
      setQuantities(qty);
    }
    update_shoppingcart(productId, newValue, amount)
  };
  const toggleProductSelection = (productId: string) => () => {
    setSelectedProducts({
      ...selectedProducts,
      [productId]: !selectedProducts[productId],
    });
  };
  const calculateTotalPrice = () => {
    return getdata.reduce((total, item) => {
      return total + item.products.reduce((subTotal, product) => {
        const quantity = Number(quantities[product.productId] || product.quantity);
        return subTotal + product.amount * quantity;
      }, 0);
    }, 0);
  };
  const pTotal = calculateTotalPrice()
  const Tqty = Object.values(quantities).reduce((sum, qty) => sum + Number(qty), 0)
  const Button = {
    Blue: "blue",
    name: "Select Customer",
  };
  const pay = {
    Blue: "blue",
    name: "Confirm",
  };
  const payerror = {
    error: "blue",
    name: "Confirm",
  };
  const renderPaymentOptions = () => {
    if (openpayment) {
      return (
        <ul>
          <li> <input type="radio" name="payment" value="card" onClick={() => setPaylate(true)} />
            <p style={{ padding: "3px",marginTop:"-30px",marginLeft: "12px" }}>Buy on credit</p></li>
          <li><input type="radio" name="payment" value="card" />
          <p style={{ padding: "3px", marginTop: "-30px", marginLeft: "12px" }}>Card</p></li>
          <li> <input type="radio" name="payment" value="card" onClick={() => setCashpay(true)} />
            <p style={{ padding: "3px",marginTop: "-30px",marginLeft: "12px" }}>Cash</p></li>
        </ul>
      );
    }
    if (paylate) {
      return (
        <ul>
          <li> <input type="radio" name="payment" value="card" onClick={() => setPaylate(false)} />
            <p style={{padding: "3px",marginTop: "-30px",marginLeft: "12px" }}>Buy on credit</p></li>
          <li><input type="radio" name="payment" value="card" />
          <p style= {{padding: "3px",marginTop: "-30px",marginLeft: "12px" }}>Card</p></li>
          <li> <input type="radio" name="payment" value="card" />
            <p style= {{padding: "3px",marginTop: "-30px",marginLeft: "12px" }}>Cash</p></li>
        </ul>
      );
    }
    if (paycard) {
      return (
        <ul>
          <li> <input type="radio" name="payment" value="card" />
            <p style={{padding: "3px",marginTop: "-30px",marginLeft: "12px" }}>Buy on credit</p></li>
          <li><FormControlLabel control={<Checkbox defaultChecked />} onClick={() => setPaycard(false)} label="Card" /></li>
          {/* <li><input type ="radio" name ="payment" value="card"onClick={() => setPaycard(false)}/><label>card</label></li> */}
          <li> <input type="radio" name="payment" value="card" />
            <p style={{padding: "3px",marginTop: "-30px",marginLeft: "12px" }}>Cash</p></li>
        </ul>
      );
    }
    if (cashpay) {
      return (
        <ul>
          <li> <input type="radio" name="payment" value="card" />
            <p style={{padding: "3px",marginTop: "-30px",marginLeft: "12px" }}>Buy on credit</p></li>
          {/* <li><FormControlLabel disabled control={<Checkbox />} label="Card" /></li> */}
          <li><input type="radio" name="payment" value="card" disabled />
          <p style={{padding: "3px",marginTop: "-30px",marginLeft: "12px" }}>Card</p></li>
          <li> <input type="radio" name="payment" value="card" onClick={() => setCashpay(false)} />
            <p style={{padding: "3px",marginTop: "-30px",marginLeft: "12px" }}>Cash</p></li>
        </ul>
      );
    }
    return null;
  };
  const customers = () => {
    setCustomerdata(true);
  }
  const phone_no = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value
    value = value.replace(/\D/g, '');
    if (value.length > 10) {
      value = value.slice(0, 10);
    }
    if (value.length > 5) {
      value = value.slice(0, 5) + ' ' + value.slice(5);
    }
    event.target.value = value;
    setPhonenumber(value);
    const filter = customerData.filter(
      (item) =>
        item.phone_number === value || item.phone_number.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filter);
  }
  const customerphonenumber = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value
    value = value.replace(/\D/g, '');
    if (value.length > 10) {
      value = value.slice(0, 10);
    }
    if (value.length > 5) {
      value = value.slice(0, 5) + ' ' + value.slice(5);
    }
    event.target.value = value;
    setCustomerphone(value);
  }
  const customeremailid = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const isValidEmail = (value: string): boolean => {
      if (value.trim() === "") return false;
      if (!value.includes("@")) return false;
      const validDomains = ['.com', '.in', '.co'];
      const hasValidDomain = validDomains.some(domain => value.endsWith(domain));
      return hasValidDomain;
    };
    const emails = isValidEmail(value);
    if (emails) {
      setCustomeremail(value);
      setconfirmcemail(value)
      setemailerror(false)
    } else {
      setCustomeremail(value);
      setemailerror(true)
    }
  }
  const updatecustomer = () => {
    const customer = {
      Name: customername,
      phone_number: customerphone,
      Email: confirmcemail,
      Out_standing_balance: 0,
      Order_Details: [],
      Address: {
        customeraddress1, customeraddress2, customeraddress3
      }
    }
    if (customername && customerphone) {
      api.post(`/app/v1/pos/customer`, customer).then((res) => {
        console.log(res);
        const customerid = res.data._id

        toast.success("Customer added successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Flip,
        })
        setTimeout(() => {
          api.put(`/app/v1/pos/shopping_cart/${cartid}`, { Customerid: customerid })
            .then((res) => { window.location.reload(); })
        }, 2000);
      }).catch((err) => {
        console.log(err);
        if (err.message === 'Request failed with status code 301') {
          seterrorphonenumber(true)
        }
      });
    } else {
      toast.error("Please fill in all required fields.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Flip,
      });
    }
  }
  const select_customer_data = (id: string) => {
    api.put(`/app/v1/pos/shopping_cart/${cartid}`, { Customerid: id })
      .then((response) => {
        window.location.reload();
      })
  }
  const paymentconfirm = () => {
    setcustomer(false);
    const name = getdata.map((item) => item.Customerid).join(' ');
    if (name) {
      if (paylate) {
        setPaylaterconfirm(true);
      }
      if (paycard) {
        console.log("paycash");
      }
      if (cashpay) {
        setcashconfirm(true);
      }
    } else {
      setPayment(false);
      setCustomerdata(true)
    }
  }
  const clickdata = () => {
    setreceivedAmount(0)
    setShowBalance(!showBalance)
  }
  const paylater = () => {
    const updatedCart = {
      totalAmount: pTotal,
      totalQuantity: Object.values(quantities).reduce((sum, qty) => sum + Number(qty), 0),
    }
    api.put(`/app/v1/pos/shopping_cart/${cartid}`, updatedCart)
      .then((res) => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");
        const hours = String(currentDate.getHours()).padStart(2, "0");
        const minutes = String(currentDate.getMinutes()).padStart(2, "0");
        const seconds = String(currentDate.getSeconds()).padStart(2, "0");
        const orderconfirm = {
          discount: discount,
          Finalamount: Number(getdata.map((item) => item.totalAmount)) - Number(discount) - Number(receivedAmount),
          totalAmount: getdata.map((item) => item.totalAmount)[0],
          Products: getdata.map(getdata => getdata.products)[0],
          Customerid: getdata.map(getdata => getdata.Customerid)[0],
          ReceivedAmount: "0",
          RemainingAmount: Number(getdata.map((item) => item.totalAmount)),
          Orderdate: `${year}-${month}-${day}`,
          Ordertime: `${hours}:${minutes}:${seconds}`,
          Orderid: `${month}${day}${hours}${minutes}${seconds}`,
          Paymentmode: "PayLater",
          OrderStatus: "Confirm",
        }
        api.post(`/app/v1/pos/order`, orderconfirm)
          .then((res) => {
            setorderconfirm(orderconfirm.Orderid);
            const iddata = orderconfirm.Orderid
            api.get(`/app/v1/pos/order/${orderconfirm.Orderid}`)
              .then((res) => {
                const order = res.data.result
                setOrdersucess(order)
                update_customer(iddata, Number(getdata.map((item) => item.totalAmount)))
              })
            setPaylaterconfirm(false)
            setPaymentsuccess(true)
            toast("Time out", { autoClose: 10000 })
            setTimeout(() => {
              api.delete(`/app/v1/pos/shopping_cart/${cartid}`)
                .then((res) => {
                  navigate(`/v1/order-details/${iddata}`)
                })
            }, 10000);
          })
      })
  }
  const paycash = () => {
    const updatedCart = {
      totalAmount: pTotal,
      totalQuantity: Object.values(quantities).reduce((sum, qty) => sum + Number(qty), 0),
    }
    api.put(`/app/v1/pos/shopping_cart/${cartid}`, updatedCart)
      .then((res) => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");
        const hours = String(currentDate.getHours()).padStart(2, "0");
        const minutes = String(currentDate.getMinutes()).padStart(2, "0");
        const seconds = String(currentDate.getSeconds()).padStart(2, "0");
        const remindingamount = Number(getdata.map((item) => item.totalAmount)) - Number(discount) - Number(receivedAmount)
        if (showBalance === true && Number(receivedAmount) > pTotal) {
          const orderconfirm = {
            totalAmount: getdata.map((item) => item.totalAmount)[0],
            Products: getdata.map(getdata => getdata.products)[0],
            Customerid: getdata.map(getdata => getdata.Customerid)[0],
            discount: discount,
            ReceivedAmount: pTotal,
            RemainingAmount: 0,
            Orderdate: `${year}-${month}-${day}`,
            Ordertime: `${hours}:${minutes}:${seconds}`,
            Orderid: `${month}${day}${hours}${minutes}${seconds}`,
            Paymentmode: "CASH",
            OrderStatus: "Confirm",
            Finalamount: 0,
            // sum: Number(finalvalueout()) - Number(receivedAmount)
          }
          api.post(`/app/v1/pos/order`, orderconfirm).then((res) => {
            setorderconfirm(orderconfirm.Orderid);
            const iddata = orderconfirm.Orderid
            api.get(`/app/v1/pos/order/${orderconfirm.Orderid}`)
              .then((res) => {
                const order: Product = res.data.result
                setOrdersucess(order)
                update_customer(iddata, remindingamount)
              })
            setPaylaterconfirm(false)
            setPaymentsuccess(true)
            toast("Time out", { autoClose: 10000 })
            setTimeout(() => {
              api.delete(`/app/v1/pos/shopping_cart/${cartid}`)
                .then((res) => {
                  navigate(`/v1/order-details/${iddata}`)
                })
            }, 10000);
          })
        } else {
          const orderconfirm = {
            totalAmount: getdata.map((item) => item.totalAmount)[0],
            Products: getdata.map(getdata => getdata.products)[0],
            Customerid: getdata.map(getdata => getdata.Customerid)[0],
            discount: discount,
            ReceivedAmount: receivedAmount,
            RemainingAmount: remindingamount,
            Orderdate: `${year}-${month}-${day}`,
            Ordertime: `${hours}:${minutes}:${seconds}`,
            Orderid: `${month}${day}${hours}${minutes}${seconds}`,
            Paymentmode: "CASH",
            OrderStatus: "Confirm",
            Finalamount: Number(getdata.map((item) => item.totalAmount)) - Number(discount) - Number(receivedAmount),
            // sum: Number(finalvalueout()) - Number(receivedAmount)
          }
          api.post(`/app/v1/pos/order`, orderconfirm).then((res) => {
            setorderconfirm(orderconfirm.Orderid);
            const iddata = orderconfirm.Orderid
            api.get(`/app/v1/pos/order/${orderconfirm.Orderid}`)
              .then((res) => {
                const order: Product = res.data.result
                setOrdersucess(order)
                update_customer(iddata, remindingamount)
              })
            setPaylaterconfirm(false)
            setPaymentsuccess(true)
            toast("Time out", { autoClose: 10000 })
            setTimeout(() => {
              api.delete(`/app/v1/pos/shopping_cart/${cartid}`)
                .then((res) => {
                  navigate(`/v1/order-details/${iddata}`)
                })
            }, 10000);
          })
        }
      })
  }
  const update_customer = (id: string, remainingAmount: number) => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    const updatedOutstandingBalance = (cust?.Out_standing_balance === null || "") ? remainingAmount : (Number(cust?.Out_standing_balance) + remainingAmount);
    const updatedOrderDetails = [
      ...(cust?.Order_Details || []),
      {
        Totalamount: Number(cust?.Out_standing_balance) + Number(getdata.map(product => product.Finalamount)),
        ReceivedAmount: receivedAmount,
        RemainingAmount: updatedOutstandingBalance,
        Orderamount: getdata.map(product => product.Finalamount),
        Orderdate: `${year}-${month}-${day}`,
        orderid: `${month}${day}${hours}${minutes}${seconds}`,
        ordernaviid: id,
      }
    ];
    const customerUpdate = {
      Out_standing_balance: updatedOutstandingBalance,
      Order_Details: updatedOrderDetails
    };
    api.put(`/app/v1/pos/customer/${cust?._id.$oid}`, customerUpdate)
      .then(res => {
        api.get(`/app/v1/pos/customer/${cust?._id.$oid}`)
          .then((res) => {
            const customer: Customerdata = res.data.result
            setCust(customer)
            console.log('customer', customer);

          })
      }
      ).catch(err => { console.log(err) })
  }
  const update_shoppingcart = (productId: string, newValue: number, amount: number) => {
    const Qty = newValue;
    if (cartid) {
      if (Array.isArray(getdata)) {
        const updatedCart = getdata.map(cart => {
          if (cart._id.$oid === cartid) {
            if (Array.isArray(cart.products)) {
              return {
                products: cart.products.map(product => {
                  if (product.productId === productId) {
                    return { ...product, quantity: Number(Qty), totalPrice: product.amount * Qty };
                  }
                  return product;
                }),
              };
            } else {
              console.error("Expected cart.products to be an array:", cart.products);
              return cart;
            }
          }
          return JSON.stringify(cart);
        });
        console.log(updatedCart);
        api.put(`/app/v1/pos/shopping_cart/${cartid}`, updatedCart)
          .then((res) => {
            console.log("Cart updated successfully");
          })
          .catch((error) => {
            console.error("Error updating cart:", error);
          });
      } else {
        console.error("Expected getdata to be an array:", getdata);
      }
    }
  };
  const OutTotal = Number(calculateTotalPrice()) + Number(cust?.Out_standing_balance)

  const roundAmount = (value: number): number => {
    if (isNaN(value)) {
      return 0;
    }
    return Math.round(value * 100) / 100;
  };

  const handleReceivedAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    if (value.startsWith("0") && value.length > 1 && !value.startsWith("0.")) {
      value = value.replace(/^0+/, "");
    }
    const numericValue = parseFloat(value);
    if (!isNaN(numericValue)) {
      const roundedValue = roundAmount(numericValue);
      if (showBalance && roundedValue >= 0 && roundedValue <= OutTotal) {
        setreceivedAmount(roundedValue);
      } else if (roundedValue >= 0 && roundedValue <= pTotal) {
        setreceivedAmount(roundedValue);
      }
    } else {
      setreceivedAmount(0);
    }
  };
  const Delete_cart_data = () => {
    api.delete(`/app/v1/pos/shopping_cart/${cartid}`)
      .then((res) => {
        console.log(res);
        toast.success("Cart deleted successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Flip,
        })
        setTimeout(() => {
          navigate("/v1/cart");
        }, 1500);
      })
  }
  const orderemailmsg = () => {
    const toastId = toast.loading("Waiting...");
    const sendmail = {
      receiver_email: ordersucess?.Email,
      subject: "Payment Confirmations",
      body: ` Hi ${ordersucess?.CustomerName} Welcome to iMetanic
      your order, confirmed Thank you for your payment! Your order has been successfully processed,
      your order id:${ordersucess?.Orderid},
      Order amount :${ordersucess?.totalAmount}Qar,
      Received Amount:${ordersucess?.ReceivedAmount}Qar,
      RemainingAmount:${ordersucess?.RemainingAmount}Qar`,
    };
    console.log("sendmail", sendmail);
    api
      .post(`/app/v1/pos/send_email`, sendmail)
      .then((res) => {
        console.log(res);
        toast.dismiss(toastId);
        toast("Mail sent sucessfully", { autoClose: 2000,theme: "colored",position:"top-right" });
        setTimeout(() => {
          navigate(`/v1/home`)
        }, 2000);
      })
  };
  const finalvalueout = () => {
    if (showBalance === true) {
      const getamount = cust?.Out_standing_balance;
      const orderamount = calculateTotalPrice()
      const amount_in = Number(getamount) + Number(orderamount)
      return (amount_in)
    } else {
      const orderamount = calculateTotalPrice()
      return (orderamount)
    }
  }
  let finalvalue = Number(calculateTotalPrice()) - Number(discount)

  let finalvalues = Number(calculateTotalPrice()) - Number(discount) - Number(receivedAmount)

  const navigationprint = () => {
    navigate(`/v1/print/${orderconfirm}`)
  }
  const homepage = () => {
    navigate(`/v1/home`);
  }
  const buttonsn = {
    name: "CONFIRM",
    Blue: "Blue"
  }
  const buttonsns = {
    name: "CANCEL",
    Cancel: "red",
  }
  const WButton = {
    Blue: "blue",
    name: "Confirm",
  };
  const PButton = {
    Cancel: "red",
    name: "Cancel",
  };
  const buttonsdata = {
    name: "ADD CUSTOMER",
    Blue: "Blue"
  }

  const roundValue = (value: number | undefined) => {
    if (value === undefined) return 0;
    const num = Number(value);
    const thirdDecimal = Math.floor(num * 1000) % 10;
    return thirdDecimal >= 5
      ? (Math.ceil(num * 100) / 100).toFixed(2)
      : num.toFixed(2);
  };

  return (
    <div style={{ width: "100%", height: '80%', overflow: 'hidden', fontFamily: ffamilys, }} id="Cartdisplay">
      <Header Header="Cart details - iMetanic" />
      <div style={{ display: 'flex', }}>
        <Menu />
        <div className="Cartdisplayoverall">
          <div className="Product_list" id="Product_details">
            <ul style={{ fontWeight: 'bold' }} className="Porducts" id="Cartdisplay_headding">
              <li>S.No</li>
              {/* <li>Actions</li> */}
              <li>Product name</li>
              <li>Product price (QAR)</li>
              <li>Total quantity</li>
              <li>Total (QAR)</li>
            </ul>
            {getdata && getdata.map((item) =>
              <div>{item.products.map((map, index,) => (
                <ul className="Porducts" key={map.productId} id="Cartdisplay_Products">
                  <li>{index + 1}</li>
                  {/* <li onClick={toggleProductSelection(map.productId)}>
                    {selectedProducts[map.productId] ? <UnpublishedIcon /> : <TaskAltIcon style={{ color: 'green' }} />}
                  </li> */}
                  <li>{map.productName}</li>
                  <li>{Number(map.amount).toFixed(2)}</li>
                  <li style={{ width: '110px', display: 'flex' }}>
                    <IconButton onClick={handleDecrement(map.productId, map.amount)}>
                      <RemoveIcon />
                    </IconButton>
                    <input
                      accept="number"
                      className="inputqty"
                      value={quantities[map.productId] || ''}
                      onChange={handleQuantityChange(map.productId, map.amount)}
                    />
                    <IconButton onClick={handleIncrement(map.productId, map.amount)}>
                      <AddIcon />
                    </IconButton>
                  </li>
                  <li style={{ marginLeft: '20px' }}>{(Number(map.amount) * Number(quantities[map.productId] || map.quantity)).toFixed(2)}
                  </li>
                </ul>))}
              </div>)}
          </div>
          <div className="hide-on-small" style={{ position: 'fixed', right: '2%' }}>
            {getdata.map((item) => <div className="Cartid_delet" id="cartid"><h3>CART ID:{item.cartId}</h3><button onClick={() => setDelete_cart(true)} id="cartid_delete"><DeleteIcon /></button></div>)}
            <div className="Order_details">
              <h3> Price details</h3>
              <hr />
              <ul>
                <li><p>Price</p><p>{roundValue(calculateTotalPrice())}</p></li>
                <li><p>item(s)</p><p>{Tqty}</p></li>
                {getdata.map((item) => <li><p>Discount </p><p>{item.discount ?? 0}</p></li>)}
                <li><p>Delivery Charge</p><p>0</p></li>
              </ul>
              <hr />
              <div className="grand_total">
                <h2>Total price</h2>
                <h2>{roundValue(finalvalue)}</h2>
              </div>
            </div>
            {customer ?
              <div onClick={() => setcustomer(false)} className="Customer_Details"><h3>Customer details</h3><KeyboardArrowUpIcon /></div> :
              <div className="Customer_Details" onClick={() => setcustomer(true)}><h3>Customer details</h3><KeyboardArrowDownIcon /></div>}
            {customer &&
              <div className="customer_selection" id="customer_details_&_customer_sellection">
                {getdata.map((item) => item.Customerid ? <ul>
                  <li><p className="label">Name</p><p className="label">: {cust?.Name}</p></li>
                  <li><p className="label">Phone number</p><p className="label">: {cust?.phone_number}</p></li>
                  <li><p className="label">Outstanding balance</p><p className="label">: {roundValue(cust?.Out_standing_balance ? parseFloat(cust.Out_standing_balance) : undefined)}</p></li>
                </ul> :
                  <div className="customer_selection_cart">
                    <ul>
                      <li style={{ width: '20vw', display: 'flex', justifyContent: 'center' }} onClick={() => customers()}><Buttons Buttondata={Button} /></li>
                    </ul>
                  </div>)}
              </div>}
            {payment ?
              <div onClick={() => setPayment(false)} className="Customer_Details"><h3>Payment methods</h3><KeyboardArrowUpIcon /></div> :
              <div onClick={() => setPayment(true)} className="Customer_Details"><h3>Payment methods</h3><KeyboardArrowDownIcon /></div>}
            {payment && <div className="payment_selection" id="mode_of_payment">
              {renderPaymentOptions()}
              <div className="confirm" id="confirm_pay_button">{openpayment ? <Buttons Buttondata={payerror} /> : <div onClick={paymentconfirm}><Buttons Buttondata={pay} /></div>}</div>
            </div>}
            {customerdata && <div className="model" id="select_customer_popup">
              <div className="overlay">
                {addcustomer ?
                  <div className="Addcustomer" ref={dropdownRef} id="New_customer_add">
                    <ul>
                      <h2>Add Customer</h2>
                      <TextField
                        style={{ width: "480px", borderRadius: "5px" }}
                        fullWidth
                        label={
                          <span>
                            Name <span style={{ color: '#f04141', fontSize: "14px" }}>*</span>
                          </span>}
                        variant="outlined" value={customername} onChange={(e) => setCustomername(e.target.value)} sx={{ margin: '5px', width: '380px' }} id="customer_name" />
                      {errorphonenumber ?
                        <TextField
                          error
                          fullWidth
                          label={
                            <span>
                              Phone number <span style={{ color: '#f04141', fontSize: "15px", backgroundColor: "green" }}>*</span>
                            </span>}
                          variant="outlined" value={""} onClick={() => { seterrorphonenumber(false); setCustomerphone("") }} sx={{ margin: '5px', width: '380px' }} id="customer_phone" helperText="Phone Number already Exists." /> :
                        <TextField
                          style={{ width: "480px", borderRadius: "5px" }} label="Phone number" variant="outlined" value={customerphone} onChange={customerphonenumber} sx={{ margin: '5px', width: '380px' }} id="customer_phone" />}
                      <TextField
                        fullWidth
                        style={{ width: "480px", borderRadius: "5px" }}
                        error={!!emailerror} label="Email Id" variant="outlined" value={customeremail} onChange={customeremailid} sx={{ margin: '5px', width: '380px' }} id="customer_email_Id" />
                      <div className="Aaddres-cart">
                        <div className="input-address-cart">
                          <h4>Address(Optional):</h4>
                          <ul>
                            <TextField style={{marginLeft:"10px" ,width: "460px", borderRadius: "5px" }} id="Customer_Address_line_1" label="Street/Village" variant="standard" value={customeraddress1} onChange={(e) => setCustomeraddress1(e.target.value)} />
                            <TextField style={{ marginLeft:"10px" ,width: "460px", borderRadius: "5px" }} id="Customer_Address_line_2" label="District/State" variant="standard" value={customeraddress2} onChange={(e) => setCustomeraddress2(e.target.value)} />
                            <TextField style={{ marginLeft:"10px" ,width: "460px", borderRadius: "5px" }} id="Customer_Address_line_3" label="Pincode/Zipcode" variant="standard" value={customeraddress3} onChange={(e) => setCustomeraddress3(e.target.value)} />
                          </ul>
                        </div>
                      </div>
                    </ul>
                    <div className="button_class" >
                      <div onClick={() => setAddcustomer(false)} id="addcustomer_cancel"><Buttons Buttondata={buttonsns} /></div>
                      <div onClick={updatecustomer} id="addcustomer_confirm">
                        <Buttons Buttondata={buttonsn} /></div>
                    </div>
                  </div> :
                  <div className="modals">
                    <div className="overlays">
                      <div className="customer_selection_pop" ref={dropdownRef} id="">
                        <div className="popup-custmer">
                          <div className="aeros">
                            <h2 className="cart-select-customer">Select Customer</h2>
                            <div style={{ marginLeft: "50px" }} onClick={() => setAddcustomer(true)} >
                              <Buttons Buttondata={buttonsdata} /></div>
                          </div>
                          <TextField
                            label="Search Phone number"
                            variant="outlined" value={phonenumber}
                            onChange={phone_no}
                            sx={{ width: '500px', bottom: "20px" }} />
                          <div className="customer-title">
                            <h2>Name</h2>
                            <h2>Phone Number</h2>
                          </div>
                          <div className="customer_data">
                            {filteredData && filteredData.map((item) =>
                              <div className="cart-cus" key={item._id.$oid} onClick={() => select_customer_data(item._id.$oid)}>
                                <div className="cart-cus-name">{item.Name}</div>
                                <div className="cart-cus-ph">{item.phone_number}</div>
                              </div>)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="note-style">
                      <b>
                        <i>
                          Note:Go to the settings page if you need to modify any customer information.
                        </i>
                      </b>
                    </div>
                  </div>
                }
              </div>
            </div>}
            {paylaterconfirm && <div className="model">
              <div className="overlay"></div>
              <div className="payment_popup" ref={dropdownRef}>
                <header className="confirmhead">
                  <div className="Payconfirm_Icon">
                    <PriorityHighRoundedIcon className="icons_pay" sx={{ fontSize: '50px' }} />
                  </div>
                  <h1>Confirm Payment</h1>
                  <p>Please confirm for following Transactions</p>
                  <div className="mode_of_payment">
                    <h2>Paylater</h2>
                  </div>
                </header>
                <body className="confirmbody">
                  {getdata.map(item =>
                    <div>
                      <ul>
                        <li>
                          <h4>Billing:</h4>
                          <p>{cust?.Name}</p>
                          <p>{cust?.phone_number}</p>
                        </li>
                        <li>
                          <h4>Delivery Date:</h4>
                          <p>{DeliveryDate}</p>
                        </li>
                      </ul>
                      <dl className="payment_amount">
                        <dt><h5>Price:</h5><p>{roundValue(pTotal)}</p></dt>
                        <dt><h5>Additional charges:</h5><p>0</p></dt>
                        <dt><h5>Advance:</h5><p>0</p></dt>
                        <dt style={{ color: background }}><h2 style={{ marginLeft: '16px' }}>Total:</h2><h2>{roundValue(finalvalue)}</h2></dt>
                      </dl>
                    </div>
                  )}
                  <button onClick={() => paylater()}>Confirm</button>
                </body>
              </div>
            </div>}
            {paycashconfirm &&
             <div className="model">
              <div className="overlay"></div>
              <div className="payment_popup" ref={dropdownRef}>
                {/* <header className="confirmhead">
                  <div className="Payconfirm_Icon">
                    <PriorityHighRoundedIcon className="icons_pay" sx={{ fontSize: '50px' }} />
                  </div>
                  <h1>Confirm Payment</h1>
                  <p>Please confirm for following Transactions</p>
                  <div className="mode_of_payment">
                    <h2>Pay Cash</h2>
                  </div>
                </header> */}
                <div className="confirmbody">
                <div className="popup-cash">
                <div className="popup-bill-cash">
                  <h1>Payment summary</h1>
                  <img
                    src={payimg}
                    alt=""
                    style={{ width: "45px", height: "45px", marginTop: "10px" }}
                  />
                  <h3>Selected customer</h3>
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    color: "#035bc9",
                    marginRight: '120px',
                    fontSize: "20px"
                  }}>
                    <p>Payment method</p>
                    <p>Customer name</p>
                  </div>
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    marginLeft: '120px',
                    position: 'relative',
                    color: "#035bc9",
                    bottom: '56px',
                    fontSize: "20px"
                  }}>
                    <p>: Cash</p>
                    <p>: {cust?.Name} </p>
                  </div>
                </div>
              </div>
                  {getdata.map(item =>
                    <div>
                      {/* <ul>
                        <li>
                          <h4>Billing:</h4>
                          <p>{cust?.Name}</p>
                          <p>{cust?.phone_number}</p>
                        </li>
                        <li>
                          <h4>Delivery Date:</h4>
                          <p>{DeliveryDate}</p>
                        </li>
                      </ul> */}
                      <div style={{ display: "grid", marginTop: '-330px', marginLeft: '40px' }}>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      maxWidth: "220px",
                      minWidth: "190px",
                      flexDirection: "column",
                      height: "240px",
                      justifyContent: "space-around",
                    }}
                  >
                    <div style={{ display: 'flex', marginLeft: '-42px', marginTop: '-10px' }} >
                      <Checkbox onClick={() => clickdata()} />
                      <p style={{ marginTop: '10px', fontSize: '18px' }}>Outstanding Balance:</p></div>
                    <p style={{ fontSize: '18px', }}>Remaining order price      :</p>
                    <p style={{ fontSize: '18px', }}>Total order price     :</p>
                    <p style={{ fontSize: '18px', }}>Received price   :</p>
                    <p style={{ fontSize: '18px', }}>Total price (QAR) :</p>
                  </div>
                  <div style={{
                    display: "flex",
                    width: "",
                    flexDirection: "column",
                    height: "240px",
                    justifyContent: "space-around",
                  }}>
                    <img
                      src={money}
                      alt=""
                      style={{ height: "50px", width: "50px" }}
                    />
                    <img
                      src={money}
                      alt=""
                      style={{ height: "50px", width: "50px" }}
                    />
                    <img
                      src={money}
                      alt=""
                      style={{ height: "50px", width: "50px" }}
                    />
                    <img
                      src={money}
                      alt=""
                      style={{ height: "50px", width: "50px" }}
                    />
                    <img
                      src={money}
                      alt=""
                      style={{ height: "50px", width: "50px" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "250px",
                      justifyContent: "space-around",
                    }}>
                    <p style={{ color: "#D70015", fontSize: "20px", fontWeight: "bold" }}>
                    {roundValue(cust?.Out_standing_balance ? parseFloat(cust.Out_standing_balance) : undefined)} QAR</p>
                    <p style={{ color: "#D70015", fontSize: "20px", fontWeight: "bold" }}>
                    {roundValue(finalvalue)} QAR
                    </p>
                    <p style={{ color: "#D70015", fontSize: "20px", fontWeight: "bold" }}>
                      {/* {formatNumbers(Outamount() ?? 0)} QAR */}
                    </p>
                    {/* <p style={{ color: "#D70015",fontSize: "20px", fontWeight: "bold" }}>
                          value={discount}
                        </p> */}
                    <p style={{ color: "#D70015", fontSize: "20px", fontWeight: "bold" }}>
                    <input 
                    value={receivedAmount} type="number" step="any" onChange={handleReceivedAmountChange} /> QAR
                    </p>
                    <p style={{ color: "#D70015", fontSize: "20px", fontWeight: "bold" }}>
                    {roundValue(Number(finalvalueout()) - Number(receivedAmount))}
                    </p>
                  </div>
                </div>
              </div>
                      {/* <dl className="payment_amount_cash">
                        <dt><Checkbox style={{ height: "40px", position: "relative", right: "15px", top: '6px' }} onClick={() => clickdata()} />
                          <h5 style={{ marginLeft: '-35px', height: "20px", position: "relative", top: "16px", }}>Outstanding balance</h5>
                          <p style={{ height: "20px", position: "relative", top: '15px' }}>{roundValue(cust?.Out_standing_balance ? parseFloat(cust.Out_standing_balance) : undefined)}</p></dt>
                        <dt><h5>Amount</h5><p> {roundValue(finalvalue)}</p></dt>
                        <dt><h5>Additional charges </h5><p>0</p></dt>
                        <dt><h5>Final Amount</h5><p> {roundValue(finalvalueout())}</p></dt>
                        <dt><h5>Advance</h5><p> <input value={receivedAmount} type="number" step="any" onChange={handleReceivedAmountChange} /></p></dt>
                        <dt style={{ color: background }}><h2 style={{ marginLeft: '18px' }}>Total Price </h2><h2> {roundValue(Number(finalvalueout()) - Number(receivedAmount))}</h2></dt>
                      </dl> */}
                    </div>
                  )}
                  <button onClick={() => paycash()}>Confirm</button>
                </div>
              </div>
            </div>}
            {paymentsuccess && <div className="model">
              <div className="overlay"></div>
              <div className="payment_popup">
                <header className="confirmhead">
                  <div className="Paysuccess_Icon">
                    <CheckIcon className="icons_success" sx={{ fontSize: '50px' }} />
                  </div>
                  <h1>Payment Successfully</h1>
                  <h2>Your order has been placed</h2>
                </header>
                <body className="confirmbodys">
                  <ul>
                    <li><h4>Order ID:</h4><h4>{ordersucess?.Orderid}</h4></li>
                    <li><h4>Name:</h4><h4>{ordersucess?.CustomerName}</h4></li>
                    <li><h4>Phone Number:</h4><h4>{ordersucess?.PhoneNumber}</h4></li>
                    {/* <li><h4>DeliveryDate:</h4><h4>{item.DeliveryDate}</h4></li> */}
                  </ul>
                  <ul>
                    {paylate && <li><h3>Payment mode:</h3><h3>Pay Later</h3></li>}
                    {paycard && <li><h3>Payment mode:</h3><h3>Card </h3></li>}
                    {cashpay && <li><h3>Payment mode:</h3><h3>Cash</h3></li>}
                    {paylate ? <li><h3>Reserved price:</h3><h3>Pay Later</h3></li> : <li><h3>Reserved amount</h3><h3>{receivedAmount}</h3></li>}
                    <li><h2>Order Balance:</h2><h2>{roundValue(ordersucess?.RemainingAmount)}</h2></li>
                    <li><h3>Outstanding Balance:</h3><h3>{cust?.Out_standing_balance}</h3></li>
                  </ul>
                  <div className="Confirm_Print_mail">
                    <button onClick={() => orderemailmsg()}>Send Mail</button>
                    <button>Send Message</button>
                    <button onClick={() => navigationprint()}>Print</button>
                  </div>
                  <button onClick={homepage} className="buttonon">Go to home page</button>
                </body>
              </div>
            </div>}
            {Delete_cart &&
              <div className="model">
                <div className="overlay"></div>
                <div className="modal-content">

                  {/* <div className="confirm_delete"> */}
                  <div style={{ display: "flex" }}>
                    <h2 style={{ marginLeft: "120px" }}>Confirm to Delete</h2>
                    {/* <h2>Are you sure to delete this cart?</h2> */}
                    <CloseIcon style={{ cursor: "pointer", marginLeft: "100px" }} onClick={() => window.location.reload()} />
                  </div>
                  <h2 style={{ marginLeft: "60px", marginTop: "10px" }}>Product Name:{getdata?.map((item) => item.products.map((product) => product.productName))}</h2>
                  <div className="shop_delete">
                    <div onClick={() => window.location.reload()} style={{ marginRight: "40px" }}>
                      <Buttons Buttondata={PButton} />
                    </div>
                    <div onClick={() => Delete_cart_data()}><Buttons Buttondata={WButton} /></div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            }
          </div>
          <button className="datashown overlay_vissible" onClick={() => setOverlay(true)}><ArrowBackIosNewIcon /></button>
        </div>
      </div>
      {Overlay && <div className="modelcart ondisplay">
        <div className="hidden_overlay">
          <button className="overlay_hidden" onClick={() => setOverlay(false)}><ArrowForwardIosIcon /></button>
          {getdata.map((item) => <div className="Cartid_delet" id="cartid"><h3>CART ID:{item.cartId}</h3><button onClick={() => setDelete_cart(true)} id="cartid_delete"><DeleteIcon /></button></div>)}
          <div className="Order_details">
            <h3> Price details</h3>
            <hr />
            <ul>
              <li><p>Price</p><p>{calculateTotalPrice()}</p></li>
              <li><p>item(s)</p><p>{Tqty}</p></li>
              {getdata.map((item) => <li><p>Discount </p><p>{item.discount ?? 0}</p></li>)}
              <li><p>Delivery Charges</p><p>0</p></li>
            </ul>
            <hr />
            <div className="grand_total">
              <h2>Total Price</h2>
              <h2>{finalvalue}</h2>
            </div>
          </div>
          {customer ?
            <div onClick={() => setcustomer(false)} className="Customer_Details"><h3>Customer details</h3><KeyboardArrowUpIcon /></div> :
            <div className="Customer_Details" onClick={() => setcustomer(true)}><h3>Customer details</h3><KeyboardArrowDownIcon /></div>}
          {customer &&
            <div className="customer_selection" id="customer_details_&_customer_sellection">
              {getdata.map((item) => item.Customerid ? <ul>
                <li><p className="label">Name</p><p className="label">: {cust?.Name}</p></li>
                <li><p className="label">Phone number</p><p className="label">: {cust?.phone_number}</p></li>
                <li><p className="label">Outstanding balance</p><p className="label">: {cust?.Out_standing_balance}</p></li>
              </ul> :
                <div className="customer_selection_cart">
                  <ul>
                    <li onClick={() => customers()}><Buttons Buttondata={Button} /></li>
                  </ul>
                </div>)}
            </div>}
          {payment ?
            <div onClick={() => setPayment(false)} className="Customer_Details"><h3>Payment methods</h3><KeyboardArrowUpIcon /></div> :
            <div onClick={() => setPayment(true)} className="Customer_Details"><h3>Payment methods</h3><KeyboardArrowDownIcon /></div>}
          {payment && <div className="payment_selection" id="mode_of_payment">
            {renderPaymentOptions()}
            <div className="confirm" id="confirm_pay_button">{openpayment ? <Buttons Buttondata={payerror} /> : <div onClick={paymentconfirm}><Buttons Buttondata={pay} /></div>}</div>
          </div>}
          {customerdata && <div className="model" id="select_customer_popup">
            <div className="overlay">
              {addcustomer ?
                <div className="Addcustomer" ref={dropdownRef} id="New_customer_add">
                  <ul>
                    <h2>Add Customer</h2>
                    <TextField label="Name" variant="outlined" value={customername} onChange={(e) => setCustomername(e.target.value)} sx={{ margin: '5px', width: '380px' }} id="customer_name" />
                    {errorphonenumber ? <TextField error label="Phone number" variant="outlined" value={""} onClick={() => { seterrorphonenumber(false); setCustomerphone("") }} sx={{ margin: '5px', width: '380px' }} id="customer_phone" helperText="Phone Number already Exists." /> : <TextField label="Phone number" variant="outlined" value={customerphone} onChange={customerphonenumber} sx={{ margin: '5px', width: '380px' }} id="customer_phone" />}
                    <TextField error={!!emailerror} label="Email Id" variant="outlined" value={customeremail} onChange={customeremailid} sx={{ margin: '5px', width: '380px' }} id="customer_email_Id" />
                    <li>
                      <h4>Address(Optional):</h4>
                      <TextField id="Customer_Address_line_1" label="Street/Village" variant="standard" value={customeraddress1} onChange={(e) => setCustomeraddress1(e.target.value)} />
                      <TextField id="Customer_Address_line_2" label="District/State" variant="standard" value={customeraddress2} onChange={(e) => setCustomeraddress2(e.target.value)} />
                      <TextField id="Customer_Address_line_3" label="Pincode/Zipcode" variant="standard" value={customeraddress3} onChange={(e) => setCustomeraddress3(e.target.value)} />
                    </li>
                  </ul>
                  <div className="button_class" >
                    <div onClick={() => setAddcustomer(false)} id="addcustomer_cancel"><Buttons Buttondata={buttonsns} /></div>
                    <div
                      onClick={updatecustomer}
                      id="addcustomer_confirm"
                    ><Buttons Buttondata={buttonsn} /></div>
                  </div>
                </div> :
                <div className="customer_selection_pop" ref={dropdownRef} id="">
                  <ul>
                    <h3>Select Customer</h3>
                    <div onClick={() => setAddcustomer(true)} >
                      <Buttons Buttondata={buttonsdata} /></div>
                  </ul>
                  <button
                    onClick={() => window.location.reload()}
                    className="cancel-icon"
                    style={{
                      justifyContent: "flex-start",
                      backgroundColor: "transparent",
                      border: "none",
                      cursor: "pointer",
                      marginLeft: "530px",
                      fontSize: "20px",
                      marginBottom: "-30px",
                      marginTop: "-40px",
                    }}
                  >
                    <CloseIcon />
                  </button>
                  <TextField label="Search Phone number" variant="outlined" value={phonenumber} onChange={phone_no} sx={{ width: '500px', top: '30px' }} />
                  <ul style={{ fontWeight: 'bold' }}>
                    <li className="names">Name</li>
                    <li className="phones">Phone Number</li>
                  </ul>
                  <div className="customer_data">
                    {filteredData && filteredData.map((item) =>
                      <ul key={item._id.$oid} onClick={() => select_customer_data(item._id.$oid)}>
                        <li className="namess">{item.Name}</li>
                        <li className="phoness">{item.phone_number}</li>
                      </ul>)}
                  </div>
                  <div className="note-style">
                    <b>
                      <i>
                        Note:Go to the settings page if you need to modify any customer information.
                      </i>
                    </b>
                  </div>
                </div>
              }
            </div>
          </div>}
          {paylaterconfirm && <div className="model">
            <div className="overlay"></div>
            <div className="payment_popup" ref={dropdownRef}>
              <header className="confirmhead">
                <div className="Payconfirm_Icon">
                  <PriorityHighRoundedIcon className="icons_pay" sx={{ fontSize: '50px' }} />
                </div>
                <h1>Confirm Payment</h1>
                <p>Please confirm for following Transactions</p>
              </header>
              <body className="confirmbody">
                {getdata.map(item =>
                  <div>
                    <div className="mode_of_payment">
                      <h2>Paylater</h2>
                    </div>
                    <ul>
                      <li>
                        <h4>Billing:</h4>
                        <p>{cust?.Name}</p>
                        <p>{cust?.phone_number}</p>
                      </li>
                      <li>
                        <h4>Delivery Date:</h4>
                        <p>{DeliveryDate}</p>
                      </li>
                    </ul>
                    <dl className="payment_amount">
                      <dt><h5>Price:</h5><p>{pTotal}</p></dt>
                      <dt><h5>Additional charges:</h5><p>0</p></dt>
                      <dt><h5>Advance:</h5><p>0</p></dt>
                      <dt style={{ color: background }}><h2 style={{ marginLeft: '16px' }}>Total:</h2><h2>{finalvalue}</h2></dt>
                    </dl>
                  </div>
                )}
                <button onClick={() => paylater()}>Confirm</button>
              </body>
            </div>
          </div>}
          {paycashconfirm && <div className="model">
            <div className="overlay"></div>
            <div className="payment_popup" ref={dropdownRef}>
              <header className="confirmhead">
                <div className="Payconfirm_Icon">
                  <PriorityHighRoundedIcon className="icons_pay" sx={{ fontSize: '50px' }} />
                </div>
                <h1>Confirm Payment</h1>
                <p>Please confirm for following Transactions</p>
              </header>
              <body className="confirmbody">
                {getdata.map(item =>
                  <div>
                    <div className="mode_of_payment">
                      <h2>Pay Cash</h2>
                    </div>
                    <ul>
                      <li>
                        <h4>Billing:</h4>
                        <p>{cust?.Name}</p>
                        <p>{cust?.phone_number}</p>
                      </li>
                      <li>
                        <h4>Delivery Date:</h4>
                        <p>{DeliveryDate}</p>
                      </li>
                    </ul>
                    <dl className="payment_amount_cash">
                      <dt><Checkbox style={{ height: "40px", position: "relative", right: "15px", top: '6px' }} onClick={() => clickdata()} />
                        <h5 style={{ marginLeft: '-35px', height: "20px", position: "relative", top: "16px", }}>Outstanding balance</h5>
                        <p style={{ height: "20px", position: "relative", top: '15px' }}>{cust?.Out_standing_balance}</p></dt>
                      <dt><h5>price</h5><p> {finalvalue}</p></dt>
                      <dt><h5>Additional charges </h5><p>0</p></dt>
                      <dt><h5>Final price</h5><p> {finalvalueout()}</p></dt>
                      <dt><h5>Advance</h5><p> <input value={receivedAmount} onChange={handleReceivedAmountChange} /></p></dt>
                      <dt style={{ color: background }}><h2 style={{ marginLeft: '18px' }}>Total price (QAR) </h2><h2> {Number(finalvalueout()) - Number(receivedAmount)}</h2></dt>
                    </dl>
                  </div>
                )}
                <button onClick={() => paycash()}>Confirm</button>
              </body>
            </div>
          </div>}
          {paymentsuccess && <div className="model">
            <div className="overlay"></div>
            <div className="payment_popup">
              <header className="confirmhead">
                <div className="Paysuccess_Icon">
                  <CheckIcon className="icons_success" sx={{ fontSize: '50px' }} />
                </div>
                <h1>Payment Successfully</h1>
                <h2>Your order has been placed</h2>
              </header>
              <body className="confirmbodys">
                <ul>
                  <li><h4>Order ID:</h4><h4>{ordersucess?.Orderid}</h4></li>
                  <li><h4>Name:</h4><h4>{ordersucess?.CustomerName}</h4></li>
                  <li><h4>Phone Number:</h4><h4>{ordersucess?.PhoneNumber}</h4></li>
                  {/* <li><h4>DeliveryDate:</h4><h4>{item.DeliveryDate}</h4></li> */}
                </ul>
                <ul>
                  {paylate && <li><h3>Payment mode:</h3><h3>Pay Later</h3></li>}
                  {paycard && <li><h3>Payment mode:</h3><h3>Card </h3></li>}
                  {cashpay && <li><h3>Payment mode:</h3><h3>Cash</h3></li>}
                  {paylate ? <li><h3>Reserved price:</h3><h3>Pay Later</h3></li> : <li><h3>Reserved amount</h3><h3>{receivedAmount}</h3></li>}
                  <li><h2>Order Balance:</h2><h2>{ordersucess?.RemainingAmount}</h2></li>
                  <li><h3>Outstanding Balance:</h3><h3>{cust?.Out_standing_balance}</h3></li>
                </ul>

                <div className="Confirm_Print_mail">
                  <button onClick={() => orderemailmsg()}>Send Mail</button>
                  <button>Send Message</button>
                  <button onClick={() => navigationprint()}>Print</button>
                </div>
                <button onClick={homepage} className="buttonon">Go to home page</button>
              </body>
            </div>
          </div>}
          {Delete_cart &&
            <div className="model">
              <div className="overlay"></div>
              <div className="confirm_delete">
                <ul>
                  <li>Confirm to Delete</li>
                  <li>
                    <div onClick={() => window.location.reload()}><Buttons Buttondata={buttonsns} /></div>
                    <div onClick={() => Delete_cart_data()}><Buttons Buttondata={buttonsn} /></div>
                  </li>
                </ul>
              </div>
            </div>
          }
        </div>
      </div>}
      <ToastContainer />
    </div>
  );
};
export default CartData;
