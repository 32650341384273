import React from 'react'
import Header from '../../components/laundry/Header'
import Menu from '../../components/laundry/Menu'

function Stock() {
  return (
    <div>
      <Header Header='Stock Maintenance' />
      <div style={{ display: 'flex' }}>
      <Menu />
      stock
      </div>
    </div>
  )
} 

export default Stock