import React, { useEffect } from "react";
import "./Dashboard.css";
import Oredrhistory from "./OrderHistory";
// import SetBarchart from "./SetBarchart";
import SetBarChart from "./SetBarChart";
import Header from "../../components/laundry/Header";
import Menu from "../../components/laundry/Menu";

function Dashboard() {
  useEffect(() => {
    document.title = "Dashboard - iMetanic";
  });
  return (
    <div>
      <Header Header="Dashboard" />
      <div style={{ display: 'flex',}}>
        <Menu />
        <div className="overalls">
          <div style={{ marginLeft: "20px", marginTop: '30px'}}>
            <SetBarChart />
            <Oredrhistory />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
