import React, { useEffect, useState } from "react";
import "./CarSetting.css";
import { Box, Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, CircularProgress } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { ToastContainer, toast } from "react-toastify";
import CarSettingMenu from "./CarSettingMenu";
import api from "../../../axiosConfig";
import CarHeaderBox from "../../../components/car-wash/CarHeaderBox";
import CarMenuBox from "../../../components/car-wash/CarMenuBox";

interface Branch {
  _id: { $oid: string };
  Branch_name: string;
  Address: string;
}

function CarBranch() {
  const [loading, setLoading] = useState<boolean>(false);
  const [branchName, setBranchName] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [branches, setBranches] = useState<Branch[]>([]);
  const [edit, setEdit] = useState<boolean>(false);
  const [id, setId] = useState<string>("");
  const [modal, setModal] = useState<boolean>(false);
  const [modalAction, setModalAction] = useState<"add" | "edit" | "delete">("add");
  const [branchToDelete, setBranchToDelete] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const fetchBranches = () => {
    setLoading(true);
    api.get("/app/v1/pos/branch")
      .then((res) => {
        setBranches(res.data.result);
      })
      .catch(() => {
        toast.error("Failed to fetch branches", { theme: "colored" });
      })
      .finally(() => setLoading(false));
  };

  const openModal = (action: "add" | "edit" | "delete", id?: string, name?: string, address?: string) => {
    setModalAction(action);
    if (action === "edit") {
      setEdit(true);
      setId(id!);
      setBranchName(name!);
      setAddress(address!);
    } else if (action === "delete") {
      setBranchToDelete(id!);
    } else {
      setBranchName('');
      setAddress('');
      setEdit(false);
    }
    setModal(true);
  };

  const handleBranchUpdate = () => {
    if (!branchName.trim() || !address.trim()) {
      toast.error("Please fill in required fields", { theme: "colored" });
      return;
    }
    setLoading(true);

    if (edit) {
      api.put(`/app/v1/pos/branch/${id}`, { Branch_name: branchName, Address: address })
        .then(() => {
          toast.success("Branch updated successfully", { theme: "colored" });
          fetchBranches();
        })
        .catch(() => {
          toast.error("Failed to update branch", { theme: "colored" });
        })
        .finally(() => {
          setLoading(false);
          setModal(false);
          setBranchName("");
          setAddress("");
          setEdit(false);
        });
    } else {
      api.post("/app/v1/pos/branch", { Branch_name: branchName, Address: address })
        .then(() => {
          toast.success("Branch added successfully", { theme: "colored" });
          fetchBranches();
        })
        .catch(() => {
          toast.error("Failed to add branch", { theme: "colored" });
        })
        .finally(() => {
          setLoading(false);
          setModal(false);
          setBranchName("");
          setAddress("");
        });
    }
  };

  const handleDeleteConfirm = () => {
    if (branchToDelete) {
      setLoading(true);
      api.delete(`/app/v1/pos/branch/${branchToDelete}`)
        .then(() => {
          toast.success("Branch deleted successfully", { theme: "colored" });
          fetchBranches();
        })
        .catch(() => {
          toast.error("Failed to delete branch", { theme: "colored" });
        })
        .finally(() => {
          setLoading(false);
          setModal(false);
          setBranchToDelete(null);
        });
    }
  };

  const resetForm = () => {
    setBranchName(""); 
    setAddress("");     
  };

  const handleChangePage = (event: unknown, newPage : number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    document.title = "Branches - Settings - iMetanic";
    fetchBranches();
  }, []);

  return (
    <div className="home-container">
      <CarHeaderBox Carheader="Settings - Branches" />
      <div style={{ display: 'flex' }}>
        <CarMenuBox />
        <div className="content-container">
          <CarSettingMenu carMenu={undefined} />
          <div className="user-container">
            <div>
              <Button
                variant="contained"
                color="primary"
                className="add-user-btn"
                onClick={() => openModal("add")}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : "Add Branch"}
              </Button>
            </div>
            <TableContainer component={Paper} className="product-tables">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="table-header">Branch Name</TableCell>
                    <TableCell className="table-header">Address</TableCell>
                    <TableCell className="table-header">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {branches.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        No branches available
                      </TableCell>
                    </TableRow>
                  ) : (
                    branches.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((branch) => (
                      <TableRow key={branch._id.$oid}>
                        <TableCell>{branch.Branch_name}</TableCell>
                        <TableCell>{branch.Address}</TableCell>
                        <TableCell>
                          <Tooltip title="Edit">
                            <IconButton onClick={() => openModal("edit", branch._id.$oid, branch.Branch_name, branch.Address)}>
                              <EditIcon className="action-icon" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton onClick={() => openModal("delete", branch._id.$oid)}>
                              <DeleteOutlineIcon className="action-icon" style={{ color: 'red' }} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15, { label: "All", value: -1 }]}
                component="div"
                count={branches.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </div>

          {modal && (
            <div className="carmodals">
              <div className="caroverlays">
                <div className="carmodal-contents">
                  <div style={{ width: "350px" }}>
                    <Box>
                      {modalAction === "delete" ? (
                        <>
                          <h3 style={{ margin: "0px 15px 22px", marginBottom: "50px" }}>
                            Are you sure you want to delete this branch?
                          </h3>
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Button variant="outlined" onClick={() => setModal(false)} style={{ width: "45%" }}>Cancel</Button>
                            <Button variant="contained" onClick={handleDeleteConfirm} style={{ width: "45%" }} disabled={loading}>
                              {loading ? <CircularProgress size={24} /> : "Confirm"}
                            </Button>
                          </div>
                        </>
                      ) : (
                        <>
                          <h3 style={{ margin: "0px 110px 20px" }}>
                            {edit ? "Edit Branch" : "Add Branch"}
                          </h3>
                          <TextField
                            label="Branch Name *"
                            variant="outlined"
                            fullWidth
                            value={branchName}
                            onChange={(e) => setBranchName(e.target.value)}
                            style={{ marginBottom: '16px' }}
                          />
                          <TextField
                            label="Address *"
                            variant="outlined"
                            fullWidth
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            style={{ marginBottom: '16px' }}
                          />
                          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                            <Button
                              variant="outlined"
                              onClick={() => {
                                resetForm();
                                setModal(false);
                                fetchBranches();
                              }}
                              style={{ width: "45%" }}
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="contained"
                              onClick={handleBranchUpdate}
                              style={{ width: "45%" }}
                              disabled={loading}
                            >
                              {loading ? <CircularProgress size={24} /> : modalAction === "edit" ? "Update" : "Confirm"}
                            </Button>
                          </div>
                        </>
                      )}
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default CarBranch;
