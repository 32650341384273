import React from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

function Demosheet() {
    const generatePdf = async (): Promise<Blob> => {
        const htmlcontent = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Pdf Generate</title>
            <style>
                /* General container styling */
                div {
                    font-family: Arial, sans-serif;
                    color: #333;
                }
                h1 {
                    text-align: center;
                    margin-bottom: 20px;
                    color: #4CAF50;
                }
                h3 {
                    margin: 10px 0;
                }
                .overview_pdf {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 20px;
                    padding: 10px;
                    background-color: #f9f9f9;
                    border: 1px solid #ddd;
                }
                table {
                    width: 100%;
                    border-collapse: collapse;
                    margin-top: 20px;
                }
                thead {
                    background-color: #4CAF50;
                    color: white;
                }
                th, td {
                    padding: 12px;
                    text-align: left;
                    border-bottom: 1px solid #ddd;
                }
                tbody tr:nth-child(odd) {
                    background-color: #f3f3f3; 
                }
                tbody tr:nth-child(even) {
                    background-color: #ffffff; 
                }
                @media (max-width: 600px) {
                    .overview_pdf {
                        flex-direction: column;
                    }
                    table, thead, tbody, th, td, tr {
                        display: block;
                        width: 100%;
                    }
                    tr {
                        margin-bottom: 10px;
                    }
                    th, td {
                        text-align: right;
                        padding: 8px;
                    }
                    th {
                        background-color: #4CAF50;
                        color: white;
                    }
                    td {
                        background-color: #f9f9f9;
                        border-bottom: 1px solid #ddd;
                    }
                }
            </style>
        </head>
        <body>
            <div>
                <div>
                    <h1>Statement</h1>
                </div>
                <div className='overview_pdf'>
                   <div>
                    <h3>Company Details:</h3>
                   </div>
                   <div>
                    <h3>Date</h3>
                    <h3>12/12/2022</h3>
                   </div>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Order Id</th>
                            <th>Customer Name</th>
                            <th>Phone Number</th>
                            <th>Order Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>Product A</td>
                            <td>10</td>
                            <td>100</td>
                            <td>1000</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Product B</td>
                            <td>20</td>
                            <td>200</td>
                            <td>4000</td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>Product C</td>
                            <td>30</td>
                            <td>300</td>
                            <td>9000</td>
                        </tr>
                    </tbody>
                    <tfoot></tfoot>
                </table>
            </div>
        </body>
        </html>
        `;
     
        const container = document.createElement('div');
        container.style.position = 'fixed'; // Prevent layout shift
        container.style.top = '0';
        container.style.left = '0';
        container.style.zIndex = '-9999';
        container.innerHTML = htmlcontent;
        document.body.appendChild(container);

        const canvas = await html2canvas(container);
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgWidth = 210; 
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

        const pdfBlob = pdf.output('blob');
        document.body.removeChild(container); 
        return pdfBlob;
    };

    const sendPdfViaEmail = async (download = false) => {
        const pdfBlob = await generatePdf();
        if (download) {
            const url = window.URL.createObjectURL(pdfBlob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'sample.pdf';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        } else {
            // Implement email sending logic here
        }
    };

    return (
        <div>
            <button onClick={() => sendPdfViaEmail(true)}>
                Generate PDF
            </button>
        </div>
    );
}

export default Demosheet;
