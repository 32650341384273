import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Typography,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import { Print, Cancel } from "@mui/icons-material";
import api from "../../../axiosConfig";
// import CarHeaderBox from "../../../components/car-wash/CarHeaderBox";
import CarMenuBox from "../../../components/car-wash/CarMenuBox";
import CarHeaderBox from "../../../components/car-wash/CarHeaderBox";

interface Item {
  amount: string;
  category: string;
  itemId: number;
  itemName: string;
  tax: string;
}
interface product {
  product: string;
  CarproductId: number;
  category: string;
  cName: string;
  price: number;
  tax: number;
}

interface Order {
  CustomerEmail: string;
  CustomerName: string;
  CustomerPhoneNo: string;
  car_details_id: string;
  Paymentmode: string;
  Orderdate: string;
  Ordertime: string;
  product_Details: product[];
  order: {
    items: Item[];
    total: string;
    totaltax: string;
  };
  subtotal: number;
  discount: number;
  totalQuantity: number;
  Orderid: number;
}

const CarOrderDetails: React.FC = () => {
  const navigate = useNavigate();
  const { orderid } = useParams<{ orderid: string }>();
  const [order, setOrder] = useState<Order | null>(null);
  const [status, setStatus] = useState<string>("Confirm");
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        setLoading(true);
        const response = await api.get(`/car_wash/car_wash_order/${orderid}`);
        setOrder(response.data.result || response.data);
      } catch (err) {
        console.error(err);
        setError("Failed to fetch order details");
      } finally {
        setLoading(false);
      }
    };
    fetchOrder();
  }, [orderid]);

  const handleStatusChange = (event: any) => {
    setStatus(event.target.value);
  };

  return (
    <div className="home-container">
      <CarHeaderBox Carheader="Order List" />
      <div style={{ display: "flex" }}>
        <CarMenuBox />
        <div style={{ margin: "20px", width: "100%" }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Grid container spacing={4}>
              {/* Order Details Table */}
              <Grid item xs={12} md={8}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Product Details
                    </Typography>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>S.No</TableCell>
                            <TableCell>Product Name</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>Price (QAR)</TableCell>
                            <TableCell>Tax (QAR)</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {order?.product_Details && order.product_Details.length > 0 ? (
                            order.product_Details.map((product, index) => (
                              <TableRow key={index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{product.product}</TableCell>
                                <TableCell>{product.cName}</TableCell>
                                <TableCell>{product.price}</TableCell>
                                <TableCell>{product.tax}</TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={5}>No products available</TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
              </Grid>
               {/* Order Summary Section */}
               <Grid item xs={12} md={4}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Order Summary
                    </Typography>
                    <Typography><strong>Order ID:</strong> {orderid}</Typography>
                    <Typography><strong>Name:</strong> {order?.CustomerName || "N/A"}</Typography>
                    <Typography><strong>Subtotal:</strong> {order?.subtotal  || "0.00"} QAR</Typography>
                    <Typography><strong>Discount:</strong> {order?.discount || "0.00"} QAR</Typography>
                    <Typography><strong>Payment Mode:</strong> {order?.Paymentmode}</Typography>
                    <Typography><strong>Order Date:</strong> {order?.Orderdate}</Typography>
                    <Typography><strong>Order Time:</strong> {order?.Ordertime}</Typography>
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Order Status</InputLabel>
                      <Select value={status} onChange={handleStatusChange}>
                        <MenuItem value="Confirm">Confirm</MenuItem>
                        <MenuItem value="Pending">Pending</MenuItem>
                        <MenuItem value="Delivered">Delivered</MenuItem>
                      </Select>
                    </FormControl>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "16px",
                      }}
                    >
                      <Button
                        variant="contained"
                        startIcon={<Print />}
                        color="primary"
                      >
                        Print Receipt
                      </Button>
                      <Button
                        variant="outlined"
                        startIcon={<Cancel />}
                        color="secondary"
                        onClick={() => navigate("/v1/Carorderlist")}
                      >
                        Cancel
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
        </div>
      </div>
      {error && (
        <Snackbar
          open={Boolean(error)}
          autoHideDuration={6000}
          onClose={() => setError(null)}
          message={error}
        />
      )}
    </div>
  );
};

export default CarOrderDetails;
