import React, { useEffect, useState } from "react";
import "../laundry/Home.css";
// import errorimg from "../../assets/Images/smiley-4832484_1280.png";
import errorimg from '../../../assets/Images/smiley-4832484_1280.png';
import { useNavigate } from "react-router-dom";
import { RingLoader } from "react-spinners";

function NotFound() {
  const navigate = useNavigate();
  const [data,setdata]=useState<boolean>(false);
  useEffect(()=>{
    // const employee = sessionStorage.getItem('employee')
    // if(employee){
    //   navigate('/v1/employee/home')
    // }
    setTimeout(()=>{
      setdata(true)
    },3000)
  })
  return (
    <div className="Notfound">
      {(data === true)&& <div>
      <header>
        Page is Not Found--404 Error
      </header>
      <ul>
        <img src={errorimg}/>
        <li><RingLoader id="Spinner" color="#ebb569" size={100} speedMultiplier={1} /><h1>404 - Error</h1> <RingLoader id="Spinner" color="#ebb569" size={100} speedMultiplier={1} /></li>
        <li>
          The page you're looking for does not exist.
        </li>
        <li><button onClick={()=>navigate('/')}>Go to Home page</button></li>
      </ul>
        </div>}
    </div>
  );
}

export default NotFound;
