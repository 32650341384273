import React, { useEffect, useRef, useState } from "react";
import "./CarSetting.css";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { ToastContainer, toast } from "react-toastify";
import CarSettingMenu from "./CarSettingMenu";
import api from "../../../axiosConfig";
// import CarHeaderBox from "../../../components/car-wash/CarHeaderBox";
import CarMenuBox from "../../../components/car-wash/CarMenuBox";
import CarHeaderBox from "../../../components/car-wash/CarHeaderBox";

interface Customer {
  _id: { $oid: string };
  customername: string;
  customerphone: number;
  car_number: string;
  date: string;
}

function CarCustomer() {
  const [loading, setLoading] = useState<boolean>(false);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [page, setPage] = useState(0);
  const [customerName, setCustomerName] = useState<string>("");
  const [carNumber, setCarNumber] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [edit, setEdit] = useState<boolean>(false);
  const [id, setId] = useState<string>("");
  const [modalAction, setModalAction] = useState<"edit" | "delete">("edit");
  const [modal, setModal] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const contactPersonRef = useRef<HTMLInputElement>(null);
  const phoneNumberRef = useRef<HTMLInputElement>(null);
  const [customerToDelete, setCustomerToDelete] = useState<string | null>(null);

  const fetchCustomers = () => {
    setLoading(true);
    api
      .get("/car_wash/car_details")
      .then((res) => {
        setCustomers(res.data.result);
      })
      .catch(() => {
        toast.error("Failed to fetch customers", { theme: "colored" });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    document.title = "Customers - Settings - iMetanic";
    fetchCustomers();
  }, []);

  const filteredCustomers = customers.filter((customer) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return (
      customer.customername.toLowerCase().includes(lowerCaseSearchTerm) ||
      customer.customerphone.toString().includes(lowerCaseSearchTerm) ||
      customer.car_number.toLowerCase().includes(lowerCaseSearchTerm)
    );
  });

  const handleEdit = (customerId: string) => {
    const customerToEdit = customers.find((customer) => customer._id.$oid === customerId);
    if (customerToEdit) {
      setCustomerName(customerToEdit.customername);
      setCarNumber(customerToEdit.car_number);
      setPhoneNumber(customerToEdit.customerphone.toString());
      setId(customerId);
      setModalAction("edit");
      setModal(true);
    }
  };

  const handleDelete = (id: string) => {
    setLoading(true);
    api.delete(`/app/v1/pos/customer/${id}`)
      .then(() => {
        toast.success("Customer deleted successfully", { theme: "colored" });
        fetchCustomers(); 
      })
      .catch(() => {
        toast.error("Failed to delete customer", { theme: "colored" });
      })
      .finally(() => setLoading(false));
       setModal(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleKeyDown = (e: React.KeyboardEvent, nextField: React.RefObject<HTMLInputElement>) => {
    if (e.key === "Enter" && nextField.current) {
      nextField.current.focus();
    }
  };

  const handleUpdate = () => {
    const updatedCustomer = {
      customername: customerName,
      car_number: carNumber,
      customerphone: phoneNumber,
    };
    setLoading(true);
    api.put(`/app/v1/pos/customer/${id}`, updatedCustomer)
      .then(() => {
        toast.success("Customer updated successfully", { theme: "colored" });
        fetchCustomers();
        setModal(false);
        setEdit(false);
      })
      .catch(() => {
        toast.error("Failed to update customer", { theme: "colored" });
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="home-container">
      <CarHeaderBox Carheader="Settings - Customers" />
      <div style={{ display: "flex" }}>
        <CarMenuBox />
        <div className="content-container">
          <CarSettingMenu carMenu={undefined} />
          <div className="user-container">
            <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "16px" }}>
              <TextField
                label="Search Customer"
                variant="outlined"
                size="small"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ width: "400px" }}
              />
            </div>
            <TableContainer component={Paper} className="product-table">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="table-header">Customer Name</TableCell>
                    <TableCell className="table-header">Car Number</TableCell>
                    <TableCell className="table-header">Phone Number</TableCell>
                    <TableCell className="table-header">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredCustomers.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        No customers available
                      </TableCell>
                    </TableRow>
                  ) : (
                    filteredCustomers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((customer) => (
                        <TableRow key={customer._id.$oid}>
                          <TableCell>{customer.customername}</TableCell>
                          <TableCell>{customer.car_number}</TableCell>
                          <TableCell>{customer.customerphone}</TableCell>
                          <TableCell>
                            <Tooltip title="Edit">
                              <IconButton onClick={() => handleEdit(customer._id.$oid)}>
                                <EditIcon className="action-icon" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <IconButton onClick={() => {
                                setId(customer._id.$oid);
                                setModalAction("delete");
                                setModal(true);
                              }}>
                                <DeleteOutlineIcon className="action-icon" style={{ color: "red" }} />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15, { label: "All", value: -1 }]}
                component="div"
                count={filteredCustomers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </div>

          {modal && (
            <div className="carmodals">
              <div className="caroverlays">
                <div className="carmodal-contents">
                  <Box>
                    {modalAction === "delete" ? (
                      <>
                        <h3 style={{ margin: "0px 15px 22px", marginBottom: "50px" }}>
                          Are you sure you want to delete this Customer?
                        </h3>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <Button variant="outlined" onClick={() => setModal(false)} style={{ width: "45%" }}>Cancel</Button>
                          <Button variant="contained" onClick={() => handleDelete(id)} style={{ width: "45%" }} disabled={loading}>
                            {loading ? <CircularProgress size={24} /> : "Confirm"}
                          </Button>
                        </div>
                      </>
                    ) : (
                      <div style={{ display: "flex", flexDirection: "column", marginBottom : "30px" }}>
                        <TextField
                          label="Customer Name"
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          value={customerName}
                          onChange={(e) => setCustomerName(e.target.value)}
                          onKeyDown={(e) => handleKeyDown(e, contactPersonRef)}
                          style={{ marginBottom: '16px', width: '420px' }}
                        />
                        <TextField
                          label="Car Number"
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          value={carNumber}
                          onChange={(e) => setCarNumber(e.target.value)}
                          inputRef={contactPersonRef}
                          onKeyDown={(e) => handleKeyDown(e, phoneNumberRef)}
                          style={{ marginBottom: '16px', width: '420px' }}
                        />
                        <TextField
                          label="Phone Number"
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          inputRef={phoneNumberRef}
                          style={{ marginBottom: '16px', width: '420px' }}
                        />
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => setModal(false)}
                            style={{ width: "45%" }}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleUpdate}
                            disabled={loading}
                            style={{ width: "45%" }}
                          >
                            Update
                          </Button>
                        </div>
                      </div>
                    )}
                  </Box>
                </div>
              </div>
            </div>
          )}
          <ToastContainer position="top-center" autoClose={1000} />
        </div>
      </div>
    </div>
  );
}

export default CarCustomer;