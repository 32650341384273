import {
  Box,
  FormControl,
  InputBase,
  InputLabel,
  NativeSelect,
  TextField,
  styled,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Flip, ToastContainer, toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import api from "../../../axiosConfig";
import Buttons from "../../../common-component/Buttons";
import LoadingScreen from "../../../common-component/LoadingScreen";
// import LoadingScreen from "../../../common-component/LoadingScreen";

interface CategoryButton {
  _id: { $oid: string };
  CategoryName: string;
}

interface Props {
  id: string | undefined;
  Name: string;
  Amount: string;
  Category: string;
  arabic:string
}

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));
const EditProduct: React.FC<Props> = ({ id, Name, Amount, Category,arabic }) => {
  const [Loading, setLoading] = useState<boolean>(false);
  const [buttonNames, setButtonNames] = useState<CategoryButton[]>([]);
  const [editproductdata, setEditproductdata] = useState<any>("");
  const [editarabic,seteditarabic] = useState<any>("")
  const [editamount, setEditamount] = useState<any>("");
  const [selectedIndex, setSelectedIndex] = React.useState<any>();
  const [selectedcategort, setSelectedcategort] = React.useState<any>("");
  const [errormsg, setErrormsg] = useState<boolean>();
  const [visible, setvisible] = useState<boolean>(false);
  const [categoryname, setcategoryname] = useState<any>();
  const productamountref = useRef<HTMLInputElement>(null);
  const selectcategoryref = useRef<HTMLInputElement>(null);
  const [modal,setModal] = useState<boolean>(false);

  const handleproductnameskeyPress = (event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      productamountref.current?.focus();
    }
  }
  const handleproductamountsKeyPress = (event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      selectcategoryref.current?.focus();
    }
  }
  const handleselectcategoryKeyPress = (event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      if (
        editproductdata === Name && editamount === Amount && selectedIndex === categoryname
      ) {
      } else {
        if (Name && Amount && Category) {
          updateprofile();
        } else {
          toast.error("Please fill in required fields.", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Flip,
          });
        }
      }
    }
  }
  useEffect(() => {
    setLoading(true);
    setEditproductdata(Name);
    setEditamount(Amount);
    setSelectedcategort(Category);
    seteditarabic(arabic);
    api
      .get(`/app/v1/pos/category`)
      .then((res) => {
        const categoryData: CategoryButton[] = res.data.result;
        setButtonNames(categoryData);
        const categoryname = categoryData.find(
          (item) => item.CategoryName === selectedcategort
        );
        setSelectedIndex(categoryname?._id.$oid || "");
        setcategoryname(categoryname?._id.$oid || "");
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [Amount, Category, Name, arabic, selectedcategort]);

  useEffect(() => {
    console.log(selectedIndex, categoryname);
    if (
      editproductdata === Name &&
      editamount === Amount &&
      selectedIndex === categoryname &&
      editarabic === arabic
    ) {
      setvisible(false);
    } else {
      if (editproductdata.length > 0 && editamount.length > 0 && selectedIndex.length > 0 && editarabic.length >0) {
        setvisible(true);
      } else {
        setvisible(false);
      }
    }
  }, [Amount, Category, Name, arabic, categoryname, editamount, editarabic, editproductdata, selectedIndex, selectedcategort]);

  const updateprofile = () => {
    setLoading(true);
    const sendsata = {
      ProductName: editproductdata,
      Amount: editamount,
      category_id: selectedIndex,
      ArabicName:editarabic
    };
    if (editproductdata && editamount && selectedIndex &&editarabic) {
      api
        .put(`/app/v1/pos/product/${id}`, sendsata)
        .then((res) => {
          toast.success("Product updated successfully", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setModal(false);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
         // setLoading(false);
        })
        .catch((error) => {
          const errormsgs =
            error.message === "Request failed with status code 404";
          setErrormsg(errormsgs);
          if (errormsgs) {
            toast.error("Product name already exists", {
              position: "top-right",
              autoClose: 2000,
              theme: "colored",
            });
          }
        });
    } else {
      toast.error("Please fill in required fields.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    setLoading(false);
  };
  const GButton = {
    Blue: "blue",
    name: "update",
  };
  const FButton = {
    Cancel: "red",
    name: "Cancel",
  };
  const KButtonerr = {
    error: "blue",
    name: "update",
  };
  const confirm = () => {
    if (visible === true) {
      return (<div onClick={updateprofile}>
        <Buttons Buttondata={GButton} />
      </div>);
    } else {
      return (<div id="confirm_btn">
        <Buttons Buttondata={KButtonerr} />
      </div>);
    }
  }

  const generateCategoryAbbreviation = (categoryName: string): string => {
    const words = categoryName.split(' ');
    return words.map(word => word[0]).join('').toUpperCase();
  };
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const categoryId = event.target.value as string;
    setSelectedIndex(categoryId);
    const selectedCategory = buttonNames.find(cat => cat._id.$oid === categoryId);

    if (selectedCategory) {
      const categoryAbbreviation = generateCategoryAbbreviation(selectedCategory.CategoryName);
      setEditproductdata((prevName: string) => {
        const currentName = prevName.replace(/\[.*?\]$/, '').trim();
        return `${currentName} [${categoryAbbreviation}]`;
      });
      setcategoryname(selectedCategory._id.$oid);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditproductdata(e.target.value);
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    if (/^\d*\.?\d*$/.test(term)) {
      setEditamount(term);
    }
  };
  
  const formatAmount = (value: string) => { 
    if (!value) return '0.00';
    let [integerPart, decimalPart] = value.split('.');
    if (!integerPart) integerPart = '0';
    if (!decimalPart) decimalPart = '00';
    else if (decimalPart.length === 1) decimalPart = decimalPart + '0';
    else if (decimalPart.length > 2) decimalPart = decimalPart.slice(0, 2);
    return `${integerPart}.${decimalPart}`;
  };

  const handleBlur = () => {
    setEditamount(formatAmount(editamount));
  };

  return (
    <div className="modals">
      <div className="overlays"></div>
      <div className="modal-contenters">
        <div className="edit-popupscreens">
          <h2>Edit product list
            <button
              onClick={() => window.location.reload()}
              className="cancel-icon-EditProduct"
            >
              <CloseIcon />
            </button>
          </h2>
          <div className="edit_productsty">
            <Box
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "500px",
                marginTop: "20px",
                borderBlockWidth: "20px",
                marginLeft: "20px",
              }}
              noValidate
              autoComplete="off">
              {errormsg ? (<TextField
                label="Product Name *"
                error
                value={editproductdata}
                onChange={handleSearch}
                onKeyPress={handleproductnameskeyPress}
              />
              

            ) : (
                <TextField
                  label="Product name *"
                  value={editproductdata}
                  onChange={handleSearch}
                  onKeyPress={handleproductnameskeyPress}
                />
                
              )}
              <TextField
                  label="Arabic word *"
                  sx={{marginTop:"10px"}}
                  value={editarabic}
                  onChange={(e)=>seteditarabic(e.target.value)}
                  onKeyPress={handleproductnameskeyPress}
                />
              <TextField
                label="Product price *"
                value={editamount}
                onChange={handleAmountChange}
                onBlur={handleBlur}
                type="number"
                sx={{ marginTop: "20px" }}
                onKeyPress={handleproductamountsKeyPress}
                inputRef={productamountref}
              />
            </Box>
            <FormControl sx={{ m: 1 }} variant="standard">
              <InputLabel
                htmlFor="demo-customized-select-native"
                style={{ marginLeft: "10px" }}>
                Category type *
              </InputLabel>
              <NativeSelect
                style={{ marginLeft: "8px", width: "100%" }}
                id="SelectCategory"
                value={selectedIndex}
                onChange={handleChange}
                onKeyPress={handleselectcategoryKeyPress}
                inputRef={selectcategoryref}
                input={<BootstrapInput />}>
                {buttonNames.map((item) => (
                  <option key={item._id.$oid} value={item._id.$oid}>
                    {item.CategoryName}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </div>
          <div className="confirm_btn_edit">
            <div onClick={() => window.location.reload()}>
              <Buttons Buttondata={FButton} />
            </div>
            {confirm()}
          </div>
        </div>
      </div>
      <ToastContainer />
      {Loading ? <LoadingScreen /> : ""}
    </div>
  );
};

export default EditProduct;
