import React, { useEffect, useState } from "react";
import "./CarSetting.css";
import { Box, Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, CircularProgress } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { ToastContainer, toast } from "react-toastify";
import CarSettingMenu from "./CarSettingMenu";
import api from "../../../axiosConfig";
// import CarHeaderBox from "../../../components/car-wash/CarHeaderBox";
import CarMenuBox from "../../../components/car-wash/CarMenuBox";
import CarHeaderBox from "../../../components/car-wash/CarHeaderBox";

interface CategoryButton {
  _id: { $oid: string };
  CategoryName: string;
  ProductCount: number;
}

interface Productbtn {
  _id: { $oid: string };
  Amount: string;
  ProductName: string;
  category_id: string;
}

function CarCategorys() {
  const [errormsg, setErrormsg] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [categoryname, setCategoryname] = useState<string>("");
  const [products, setProducts] = useState<Productbtn[]>([]);
  const [edit, setEdit] = useState<boolean>(false);
  const [id, setId] = useState<string>("");
  const [modal, setModal] = useState<boolean>(false);
  const [buttonNames, setButtonNames] = useState<CategoryButton[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [modalAction, setModalAction] = useState<"add" | "edit" | "delete">("add");
  const [categoryToDelete, setCategoryToDelete] = useState<string | null>(null);

  useEffect(() => {
    document.title = "Categories - Settings - iMetanic";
    fetchCategories();
    fetchProducts();
  }, []);

  const fetchCategories = () => {
    setLoading(true);
    api.get("/app/v1/pos/category")
      .then((res) => {
        setButtonNames(res.data.result);
      })
      .catch(() => {
        toast.error("Failed to fetch categories", { theme: "colored" });
      })
      .finally(() => setLoading(false));
  };

  const fetchProducts = () => {
    setLoading(true);
    api.get(`/app/v1/pos/product`)
      .then((res) => {
        setProducts(res.data.result);
      })
      .catch(() => {
        toast.error("Failed to fetch products", { theme: "colored" });
      })
      .finally(() => setLoading(false));
  };

  const openModal = (action: "add" | "edit" | "delete", id?: string, name?: string) => {
    setModalAction(action);
    if (action === "edit") {
      setEdit(true);
      setId(id!);
      setCategoryname(name!);
    } else if (action === "delete") {
      setCategoryToDelete(id!);
    } else {
      setCategoryname('');
      setEdit(false);
    }
    setModal(true);
  };

  const handleCarcategoryUpdate = () => {
    if (!categoryname.trim()) {
      toast.error("Please fill in required fields", { theme: "colored" });
      return;
    }
    const isDuplicateName = buttonNames.some(
      (category) =>
        category.CategoryName.trim().toLowerCase() === categoryname.trim().toLowerCase() &&
        (!edit || category._id.$oid !== id)
    );
    if (isDuplicateName) {
      toast.error("Category name already exists. Please choose a different name.", { theme: "colored" });
      return;
    }
    setLoading(true);

    if (edit) {
      api.put(`/app/v1/pos/category/${id}`, { CategoryName: categoryname })
        .then(() => {
          toast.success("Category updated successfully", { theme: "colored" });
          fetchCategories();
        })
        .catch((error) => {
          if (error.response?.status === 403) {
            toast.error("Category name already exists", { theme: "colored" });
          } else {
            toast.error("Failed to update category", { theme: "colored" });
          }
        })
        .finally(() => {
          setLoading(false);
          setModal(false);
          setCategoryname("");
          setEdit(false);
        });
    } else {
      api.post("/app/v1/pos/category", { CategoryName: categoryname })
        .then((res) => {
          toast.success("Category added successfully", { theme: "colored" });
          fetchCategories();
        })
        .catch((error) => {
          if (error.response?.status === 403) {
            toast.error("Category name already exists", { theme: "colored" });
          } else {
            toast.error("Failed to add category", { theme: "colored" });
          }
        })
        .finally(() => {
          setLoading(false);
          setModal(false);
          setCategoryname("");
        });
    }
  };

  const Products = {
    titlesno: "S.No",
    titlename1: "Category Name",
    titlename2: "Actions",

    S_no: buttonNames.map((_, index) => index + 1),
    bodyname1: buttonNames.map((buttonNames) => buttonNames.CategoryName),
    bodyname4: buttonNames.map((buttonNames) => (
      <>
        <Tooltip title="Edit">
          <IconButton onClick={() => openModal("edit", buttonNames._id.$oid, buttonNames.CategoryName)}>
            <EditIcon className="action-icon" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton onClick={() => openModal("delete", buttonNames._id.$oid)}>
            <DeleteOutlineIcon className="action-icon" style={{ color: 'red' }} />
          </IconButton>
        </Tooltip>
      </>
    )),
  };

  const handleDeleteConfirm = () => {
    if (categoryToDelete) {
      setLoading(true);
      api.delete(`/app/v1/pos/category/${categoryToDelete}`)
        .then(() => {
          toast.success("Category deleted successfully", { theme: "colored" });
          fetchCategories();
        })
        .catch(() => {
          toast.error("Failed to delete category", { theme: "colored" });
        })
        .finally(() => {
          setLoading(false);
          setModal(false);
          setCategoryToDelete(null);
        });
    }
  };

  const resetForm = () => {
    setCategoryname("");
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  return (
    <div className="home-container">
      <CarHeaderBox Carheader="Settings - Categories" />
      <div style={{ display: 'flex' }}>
        <CarMenuBox />
        <div className="content-container">
          <CarSettingMenu carMenu={undefined} />
          <div className="user-container">
            <div>
              <Button
                variant="contained"
                color="primary"
                className="add-category-btn"
                onClick={() => {
                  setEdit(false);
                  setModalAction("add");
                  setModal(true);
                }}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : "Add Category"}
              </Button>
            </div>
            {/* {products.length === 0 ? (
            <p>No data available</p>
          ) : (
            <div className="product-tables">
              <Tables product={Products} />
            </div>
          )} */}
            <TableContainer component={Paper} className="product-tables">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="table-header">S.No</TableCell>
                    <TableCell className="table-header">Category Name</TableCell>
                    <TableCell className="table-header">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {buttonNames.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        No categories available
                      </TableCell>
                    </TableRow>
                  ) : (
                    buttonNames.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((category: CategoryButton, index: number) => (
                      <TableRow key={category._id.$oid}>
                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                        <TableCell>{category.CategoryName}</TableCell>
                        <TableCell>
                          <Tooltip title="Edit">
                            <IconButton onClick={() => openModal("edit", category._id.$oid, category.CategoryName)}>
                              <EditIcon className="action-icon" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton onClick={() => openModal("delete", category._id.$oid)}>
                              <DeleteOutlineIcon className="action-icon" style={{ color: 'red' }} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15, { label: "All", value: -1 }]}
                component="div"
                count={buttonNames.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </div>

          {modal && (
            <div className="carmodals">
              <div className="caroverlays">
                <div className="carmodal-contents">
                  <div style={{ width: "350px" }}>
                    <Box>
                      {modalAction === "delete" ? (
                        <>
                          <h3 style={{ margin: "0px 15px 22px", marginBottom: "50px" }}>
                            Are you sure you want to delete this category?
                          </h3>
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Button variant="outlined" onClick={() => setModal(false)} style={{ width: "45%" }}>Cancel</Button>
                            <Button variant="contained" onClick={handleDeleteConfirm} style={{ width: "45%" }} disabled={loading}>
                              {loading ? <CircularProgress size={24} /> : "Confirm"}
                            </Button>
                          </div>
                        </>
                      ) : (
                        <>
                          <h3 style={{ margin: "0px 110px 20px" }}>
                            {edit ? "Edit Category" : "Add Category"}
                          </h3>
                          <div style={{ display: "flex", flexDirection: "column", marginBottom: "30px" }}>
                            <TextField
                              error={errormsg}
                              label="Category Name *"
                              variant="outlined"
                              fullWidth
                              value={categoryname}
                              onChange={(e) => setCategoryname(e.target.value)}
                              className="wide-textfield"
                              style={{ marginBottom: '1px', width: '350px' }}
                            />
                          </div>
                          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                            <Button
                              variant="outlined"
                              onClick={() => {
                                resetForm();
                                setModal(false);
                                fetchCategories();
                              }}
                              style={{ width: "45%" }}
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="contained"
                              onClick={handleCarcategoryUpdate}
                              style={{ width: "45%" }}
                              disabled={loading}
                            >
                              {loading ? <CircularProgress size={24} /> : edit ? "Update" : "Confirm"}
                            </Button>
                          </div>
                        </>
                      )}
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          )}
          <ToastContainer autoClose={1000} />
        </div>
      </div>
    </div>
  );
}

export default CarCategorys;
