import React, { useEffect, useState } from 'react';
import './CarHome.css';
// import iMetanic from '../../assets/Imetaniclogo.png'
import iMetanic from '../../../assets/Images/imetanic-nameing-logo.png';
import Logo from '../../common-component/Logo';

interface Props {
  Carheader: string;
}

const CarHeaderBox: React.FC<Props> = ({ Carheader }) => {
  const [data, setData] = useState<string | null>("");
  const [background, setBackground] = useState<string>("");
  const [businessName, setBusinessName] = useState<string>("");
  const [fontcolor, setFontColor] = useState<string>("");

  useEffect(() => {
    const business = sessionStorage.getItem("organization_name");
    setBusinessName(String(business));

    const profileName = sessionStorage.getItem("Profilename");
    setData(profileName);

    const storedColor = sessionStorage.getItem(`background_color${business}`);
    if (storedColor) {
      setBackground(storedColor);
      document.documentElement.style.setProperty('--background-color', storedColor);
    }
    const fontcolor = sessionStorage.getItem(`font_color${business}`);
    if (fontcolor) {
      setFontColor(fontcolor);
      document.documentElement.style.setProperty('--font-color', fontcolor);
    }
    const fontfamily = sessionStorage.getItem(`font_family${business}`);
    if (fontfamily) {
      document.documentElement.style.setProperty('--fontfamily', fontfamily);
    }
  }, [])

  return (
    <header className="car-header">
      <div className="left-section">
        <div className="business-logo">
          <Logo />
        </div>
        <span className="business-name">{Carheader}</span>
      </div>
      <div className="right-section">
        <div>
          <img src={iMetanic} alt="iMetanic logo" />
        </div>
      </div>
    </header>
  );
};

export default CarHeaderBox;