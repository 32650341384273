import React, { useEffect, useState } from "react";
import "./Edit.css";
import { Box, IconButton, Table, TableBody, TableCell, TableRow, TextField, Tooltip } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import Editcategory from "./EditCategory";
import Deletedcat from "./DeletedCategory";
import { Flip, ToastContainer, toast } from "react-toastify";
import Editmenu from "./EditMenu";
import CloseIcon from "@mui/icons-material/Close";
import api from "../../../axiosConfig";
import Buttons from "../../../common-component/Buttons";
import Header from "../../../components/laundry/Header";
import Menu from "../../../components/laundry/Menu";
import Tables from "../../../common-component/Table";
import LoadingScreen from "../../../common-component/LoadingScreen";
// import LoadingScreen from "../../../common-component/LoadingScreen";
interface CategoryButton {
  _id: { $oid: string };
  CategoryName: string;
}
interface Productbtn {
  _id: { $oid: string };
  Amount: string;
  ProductName: string;
  category_id: string;
}

function AddCategory() {
  const [errormsg, setErrormsg] = useState<boolean>();
  const [Loading, setLoading] = useState<boolean>(false);
  const [categoryname, setCategoryname] = useState<any>();
  const [products, setProducts] = useState<Productbtn[]>([]);
  const [remove, setRemove] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [id, setId] = useState<string>("");
  const [Name, setName] = useState<string>("");
  const [modal, setModal] = useState(false);
  const [empty, setEmpty] = useState<boolean>(false);
  const [visible, setvisible] = useState<boolean>(false);
  const [buttonNames, setButtonNames] = useState<CategoryButton[]>([]);
  useEffect(() => {
    document.title = "Categories - Settings - iMetanic";
  });
  const category = () => {
    api.get(`/app/v1/pos/category`).then((res) => {
      const categoryData: CategoryButton[] = res.data.result;
      setButtonNames(categoryData);
    });
  };
  const product = () => {
    api
      .get(`${process.env.REACT_APP_BACKEND_URL}/app/v1/pos/product`)
      .then((res) => {
        const productdata: Productbtn[] = res.data.result;
        setProducts(productdata);
      })
      .catch((res) => {
        console.log(res);
      });
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      if (categoryname) {
        categoryupdate();
      } else {
        toast.error("Please fill in required fields", {
          position: "top-right",
          autoClose: 2000,
          theme: "colored",
        });
      }
    }
  };
  useEffect(() => {
    setLoading(true);
    if (empty === false) {
      category();
      product();
    }
    setLoading(false);
    if (categoryname) {
      setvisible(true);
    } else {
      setvisible(false);
    }
  }, [categoryname, empty]);
  const categoryupdate = () => {
    const updatename = {
      CategoryName: categoryname,
    };
    if (categoryname) {
      setLoading(true);

      api
        .post(`/app/v1/pos/category`, updatename)
        .then((res) => {
          console.log(res);
          toast.success("Category added successfully", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setCategoryname("");
          // setModal(false);
          category();
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          const errormsgs =
            error.message === "Request failed with status code 403";
          setErrormsg(errormsgs);
          if (errormsgs) {
            toast.error("Category name already exist", {
              position: "top-right",
              autoClose: 2000,
              theme: "colored",
            });
          }
        });
      setLoading(false);
    } else {
      toast.error("Please fill in all required fields.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  const editname = (id: string, Name: string) => {
    setEdit(!edit);
    setId(id);
    setName(Name);
  };
  const deletename = (id: string, Name: string) => {
    setRemove(!edit);
    setId(id);
    setName(Name);
  };
  const Products = {
    titlesno: "S.No",
    titlename1: "Categories",
    titlename2: "Number of products",
    titlename3: "Actions",
    S_no: buttonNames.map((item, index) => index + 1),
    bodyname1: buttonNames.map((item) => item.CategoryName),
    bodyname2: buttonNames.map(
      (item) =>
        products.filter((product) => product.category_id === item._id.$oid)
          .length
    ),
    bodyname3: buttonNames.map((item) => (
      <>
        <button
          className="icon-btn-edit"
          onClick={() => editname(item._id.$oid, item.CategoryName)}
        >
          <Tooltip title="Edit" placement="bottom" arrow>
            <IconButton>
              <EditIcon style={{ color: "black" }} />
            </IconButton>
          </Tooltip>
        </button>
        <button
          className="icon-btn-edit"
          onClick={() => deletename(item._id.$oid, item.CategoryName)}
        >
          <Tooltip title="Delete" placement="bottom" arrow>
            <IconButton>
              <DeleteOutlineIcon style={{ color: "red" }} />
            </IconButton>
          </Tooltip>
        </button>
      </>
    )),
  };

  const BButton = {
    Blue: "blue",
    name: "Add Category",
  };
  const KButton = {
    Blue: "blue",
    name: "Confirm",
  };
  const KButtonerr = {
    error: "blue",
    name: "Confirm",
  };
  const LButton = {
    Cancel: "red",
    name: "Cancel",
  };
  const confirm = () => {
    if (visible === true) {
      return (
        <div id="confirm_btn" onClick={categoryupdate}>
          <Buttons Buttondata={KButton} />
        </div>
      );
    } else {
      return (
        <div id="confirm_btn">
          <Buttons Buttondata={KButtonerr} />
        </div>
      );
    }
  };
  const categoryrename = () => {
    setCategoryname("");
    setErrormsg(false);
  };
  const menu = {
    Category: "Category",
  };

  return (
    <div>
      <Header Header="Settings - Categories" />
      <div style={{ display: 'flex' }}>
        <Menu />
        <div className="Edit-overall">
          <Editmenu editmenu={menu} />
          <div className="category-add">
            <div onClick={() => { setModal(true); setEmpty(true) }} id="Addcategory">
              <Buttons Buttondata={BButton} />
            </div>
          </div>
          <Table sx={{marginLeft:"70px"}}>
            <TableBody>
              {buttonNames.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={3}
                    align="center"
                    sx={{ color: "black", fontSize: "large" }}
                  >
                    No data available
                  </TableCell>
                </TableRow>
              ) : (
                <Tables product={Products} />
              )}
            </TableBody>
          </Table>

          {modal && (
            <div className="modals">
              <div className="overlays"></div>
              <div className="modal-contents">
                <div className="edit-popupscreen">
                  <div className="addcategory-editadd">
                    <h2>Add category
                      <button
                        onClick={() => window.location.reload()}
                        className="cancel-icon-Addcategory"
                      >
                        <CloseIcon />
                      </button>
                    </h2>
                    <Box
                      component="form"
                      sx={{
                        "& > :not(style)": {
                          m: 1,
                          width: "50ch",
                          marginTop: "40px",
                          borderBlockWidth: "20px",
                        },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      {errormsg ? (
                        <TextField
                          id="Category_Name"
                          error
                          label={
                            <span>
                              Category name <span style={{ color: '#f04141', fontSize: "15px" }}>*</span>
                            </span>
                          }
                          value={categoryname}
                          onChange={(e) => setCategoryname(e.target.value)}
                          onClick={() => categoryrename()}
                        />
                      ) : (
                        <TextField
                          id="Category_Name"
                          label={
                            <span>
                              Category name <span style={{ color: '#f04141', fontSize: "15px" }}>*</span>
                            </span>
                          }
                          value={categoryname}
                          onKeyPress={handleKeyPress}
                          onChange={(e) =>
                            setCategoryname(e.target.value.toUpperCase())
                          }
                        />
                      )}
                    </Box>
                    <div className="btnconfirm-edit">
                      <div id="cancelbtn" onClick={() => setModal(false)}>
                        <Buttons Buttondata={LButton} />
                      </div>
                      {confirm()}
                    </div>
                  </div>
                </div>
                <ToastContainer />
              </div>
            </div>
          )}
          {edit && (
            <div className="modals">
              <div className="overlays"></div>
              <div className="modal-contents">
                <div className="edit-popupscreen">
                  <Editcategory id={id} Name={Name} />
                </div>
              </div>
            </div>
          )}
          {remove && <Deletedcat id={id} Name={Name} />}

          {Loading ? <LoadingScreen /> : ""}
        </div>
      </div>
    </div>
  );
}

export default AddCategory;
