import React, { useEffect, useState } from "react";
import "./CarSetting.css";
import { Card, CardActions, Button, CardContent, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Link } from "react-router-dom";

interface Props {
  carMenu?: { [key: string]: boolean };
}

const CarSettingMenu: React.FC<Props> = ({ carMenu }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  useEffect(() => {
    const business = sessionStorage.getItem("organization_name");
    const color1 = sessionStorage.getItem(`background_color${business}`);
    const color2 = sessionStorage.getItem(`font_color${business}`);
    const fontfamily = sessionStorage.getItem(`font_family${business}`);

    if (color1) document.documentElement.style.setProperty("--background-color", color1);
    if (color2) document.documentElement.style.setProperty("--color", color2);
    if (fontfamily) document.documentElement.style.setProperty("--ffamily", fontfamily);
  }, []);

  const toggleMenu = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Card className="carmenu-container">
      <CardActions>
        <div className="carmenu-title">
          Settings Menu
        </div>
        <Button onClick={toggleMenu} className="toggle-button">
          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
      </CardActions>

      {isExpanded && (
        <CardContent>
          <div className="carsettingmenu-list">
            {[
              { name: "Products", key: "Products", path: "/v1/car-setting/car-products" },
              { name: "Categories", key: "Categorys", path: "/v1/car-setting/car-categorys" },
              { name: "Customers", key: "Customer", path: "/v1/car-setting/car-customer" },
              { name: "Branches", key: "Branch", path: "/v1/car-setting/car-branch" },
              { name: "Users", key: "Users", path: "/v1/car-setting/car-users" },
            ].map((option) => (
              <div key={option.key} className="carmenusetting-item">
                {carMenu?.[option.key] ? (
                  <div>{option.name}</div>
                ) : (
                  <Link to={option.path} className="btn-edit">
                    <Typography variant="body1">{option.name}</Typography>
                  </Link>
                )}
              </div>
            ))}
          </div>
        </CardContent>
      )}
    </Card>
  );
};

export default CarSettingMenu;
