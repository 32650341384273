import React,{ useState } from "react";
import "./Edit.css";
import { Flip, toast, ToastContainer } from "react-toastify";
import api from "../../../axiosConfig";
import Buttons from "../../../common-component/Buttons";
interface Props {
  id: string | undefined;
  Name: string;
}
const DeleteProducts: React.FC<Props> = ({ id, Name }) => {
  const [isModalVisible, setIsModalVisible] = useState(true);
  const Deleteproduct = () => {
    api.delete(`/app/v1/pos/product/${id}`).then((res) => {
      toast.success("Product deleted successfully", { 
        position: "top-right",
        autoClose: 2100,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Flip,
      });
      // setIsModalVisible(false);
      setTimeout(() => {
        window.location.reload();
      }, 100);
    });
  };
  const WButton = {
    Blue: "blue",
    name: "Confirm",
  };
  const PButton = {
    Cancel: "red",
    name: "Cancel",
  };
  return (
    <div className="modals">
      <div className="overlays"></div>
      <div className="modal-contents">
        <div className="edit-popupscreen">
          <div className="addcategory-edit-product" style={{ marginTop: "-20px" }}>
            <h1 style={{marginTop:"20px"}}>Confirm to delete</h1>
            <p style={{fontWeight:'bold',marginTop:"15px"}}>Product name : {Name}</p>
            <p style={{marginTop:"20px"}}>Are you sure you want to delete this product?</p>
            <div className="btnconfirm-edit">
              <div onClick={() => window.location.reload()}>
                <Buttons Buttondata={PButton} />
              </div>
              <div onClick={() => Deleteproduct()}>
                <Buttons Buttondata={WButton} />
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default DeleteProducts;
