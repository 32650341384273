import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination,
  TextField,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './CarOrder.css';
import api from '../../../axiosConfig';
// import CarHeaderBox from '../../../components/car-wash/CarHeaderBox';
import CarMenuBox from '../../../components/car-wash/CarMenuBox';
import CarHeaderBox from '../../../components/car-wash/CarHeaderBox';

interface Item {
  amount: string;
  category: string;
  itemId: number;
  itemName: string;
  tax: string;
}

interface Order {
  CustomerEmail: string;
  CustomerName: string;
  CustomerPhoneNo: string;
  car_details_id: string;
  order: {
    discount: string;
    items: Item[];
    OrderId: number;
    subtotal: string;
    total: string;
    totaltax: string;
  };
  totalAmount: number;
  totalQuantity: number;
  Orderid: number;
}

const CarOrderList: React.FC = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      setLoading(true);
      const response = await api.get('/car_wash/car_wash_order');
      const order: Order[] = response.data.result;
      setOrders(order);
    } catch (err) {
      setError('Failed to fetch orders. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleCustomerClick = (orderid: any) => {
    navigate(`/v1/car-order-details/${orderid}`);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value.toLowerCase());
    setPage(0);
  };

  const filteredData = Array.isArray(orders)
  ? orders.filter(
      (order) =>
        order.CustomerName.toLowerCase().includes(searchQuery) ||
        order.Orderid.toString().toLowerCase().includes(searchQuery) // Convert Orderid to string before applying toLowerCase
    )
  : [];

  return (
    <div className="home-container">
      <CarHeaderBox Carheader="Order List" />
      <div style={{ display: 'flex' }}>
        <CarMenuBox />
        <div className="table-container-wrapper">
          {loading ? (
            <div style={{ textAlign: 'center', padding: '20px' }}>
              <CircularProgress />
            </div>
          ) : error ? (
            <div style={{ textAlign: 'center', color: 'red' }}>{error}</div>
          ) : (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '16px',
                }}
              >
                <div></div>
                <TextField
                  label="Search Orders"
                  variant="outlined"
                  size="small"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  style={{
                    marginRight: '16px',
                    width: '300px',
                    backgroundColor: 'white',
                  }}
                />
              </div>
              <TableContainer component={Paper} className="order-table">
                <Table>
                  <TableHead>
                    <TableRow className="table-header">
                      <TableCell  align="left">S.No</TableCell>
                      <TableCell align="left">Customer Name</TableCell>
                      <TableCell align="left">Order ID</TableCell>
                      <TableCell align="left">Order Status</TableCell>
                      <TableCell align="center">Details</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((order, index) => (
                        <TableRow key={order?.Orderid} className="table-row">
                          <TableCell align="left">
                            {page * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell align="left">{order?.CustomerName}</TableCell>
                          <TableCell align="left">{order?.Orderid}</TableCell>
                          <TableCell align="left">Pending</TableCell>
                          <TableCell align="center">
                            <Button
                              variant="contained"
                              className="view-details-button"
                              onClick={() => handleCustomerClick(order?.Orderid)}
                            >
                              View Details
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15]}
                  component="div"
                  count={filteredData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CarOrderList;
