import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputBase,
  InputLabel,
  NativeSelect,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Flip, ToastContainer, toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import api from "../../../axiosConfig";
import Buttons from "../../../common-component/Buttons";

interface Branchlocation {
  _id: { $oid: string };
  Branch_name: string;
  Address: {
    Pincode: string;
    address1: string;
    selectedDistrict: string;
    selectedState: string;
    street: string;
  };
}
interface Props {
  id: string;
}
interface Employee_data {
  [x: string]: any;
  _id: {
    $oid: string;
  };
  user_name: string;
  employee_name: string;
  phone_number: string;
  email_id: string;
  address: string;
  password: string;
  Delete: string;
  date_of_joined: string;
  date_of_leaving: string;
}
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#f1f1f1",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 22px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdf",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const RoleSelect: React.FC<Props> = ({ id }) => {
  const [user_id, setuser_id] = useState<Employee_data | null>();
  const [location, setLocation] = useState<Branchlocation[]>([]);
  const [empid, setEmpid] = useState<Branchlocation[]>([]);
  const [admin, setadmin] = useState<boolean>(false);
  const [employee, setemployee] = useState<boolean>(false);
  const [visible, setvisible] = useState<boolean>(false);
  const [study, setstudy] = useState<string>("");
  useEffect(() => {
    api.get(`/pos/get_user`)
      .then((res) => {
        const users: Employee_data[] = res.data.result;
        const userdata = users.find(user => user._id.$oid === id)
        setuser_id(userdata)
      });
    api.get(`/app/v1/pos/branch`).then((res) => {
      const branch: Branchlocation[] = res.data.result;
      setLocation(branch);
      if ((admin || employee) && empid.length > 0) {
        setvisible(true);
      } else {
        setvisible(false);
      }
    });
  }, [admin, empid, employee, id]);
  const confirmname = () => {
    if (empid.length > 0) {
      if (admin) {
        const branchname = {
          employee: empid,
          role: "Admin",
        }
        console.log('admin', branchname);

        api.put(`/pos/add/${id}`, branchname).then((res) => {
          console.log(res);
          if (admin) {
            const branch = {
              contact_person: user_id?.employee_name,
              branch_number: user_id?.phone_number,
            };
            api.put(`/app/v1/pos/branch/${study}`, branch).then((res) => {
              console.log("update");
            });
          }
        });
        toast.success("Branch added successfully.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Flip,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        const branchname = {
          employee: empid,
          role: "Employee",
        }
        api.put(`/pos/add/${id}`, branchname).then((res) => {
          console.log(res);
        });
        toast.success("Branch added successfully.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Flip,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } else {
      toast.error("Please fill in required fields.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Flip,
      });
    }
  };
  const pinfilter = (event: { target: { value: string } }) => {
    const branchloc: Branchlocation[] = location.filter(
      (item) => item._id.$oid === event.target.value
    );
    setEmpid(branchloc);
    setstudy(event.target.value);
  };
  const Bbutton = {
    Blue: "b",
    name: "Confirm",
  };
  const Button = {
    Cancel: "blue",
    name: "cancel",
  };
  const KButtonerr = {
    error: "blue",
    name: "Confirm",
  };
  const confirm = () => {
    if (visible === true) {
      return (<div className="roleconfirm" onClick={confirmname}>
        <Buttons Buttondata={Bbutton} />
      </div>)
    } else {
      return (<div id="confirm_btn">
        <Buttons Buttondata={KButtonerr} />
      </div>)
    }
  }
  const selectworkers_Type = () => {

    const handleAdminChange = () => {
      if (!admin) {
        setadmin(true);
        setemployee(false);
      } else {
        setadmin(false);
      }
    };

    const handleEmployeeChange = () => {
      if (!employee) {
        setemployee(true);
        setadmin(false);
      } else {
        setemployee(false);
      }
    };

    return (
      <>
        <FormControlLabel
          control={
            <Checkbox
              id="Admin_checkbox"
              checked={admin}
              onChange={() => setadmin((prev) => !prev)}
            />
          }
          label="Admin"
        />
        <FormControlLabel
          control={
            <Checkbox
              id="Emp_checkbox"
              checked={employee}
              onChange={() => setemployee((prev) => !prev)}
            />
          }
          label="Employee"
        />
      </>
    );
  };

  return (
    <div>
      {empid?.some(i => i.Branch_name)?(<div className="modals">
        <div className="overlays">
          <div className="modal-contents">
            <div className="edit-popupscreen">
              <div>
                <h1>Edit Branch
                  <button
                    onClick={() => window.location.reload()}
                    className="cancel-icon-SelectBranch"
                  >
                    <CloseIcon />
                  </button>
                </h1>
              </div>
              <div>
                <div
                  style={{
                    display: "grid",
                    width: "350px",
                    alignItems: "center",
                    marginRight: "140px",
                    marginTop: "30px",
                  }}
                >
                  <div className="Role_select">
                    <p style={{ fontSize: "18px", marginRight: "5px" }}>
                      Role
                    </p>
                    <p style={{ color: "red" }}>
                      *
                    </p>
                  </div>
                  {selectworkers_Type()}
                </div>
              </div>
              <div
                style={{
                  width: "90px",
                  marginRight: "420px",
                  marginBottom: "20px",
                }}
              >
                <FormControl sx={{ m: 1 }} variant="standard">
                  <InputLabel htmlFor="demo-customized-select-native">
                    Branch<span style={{ color: '#f04141', fontSize: "15px" }}>*</span>
                  </InputLabel>
                  <NativeSelect
                    style={{ width: "690%" }}
                    id="demo-customized-select-native"
                    onChange={pinfilter}
                    input={<BootstrapInput />}
                  >
                    <option value={""}>Select branch</option>
                    {location.map((item) => (
                      <option key={item._id.$oid} value={item._id.$oid}>
                        {item.Branch_name}
                      </option>
                    ))}
                  </NativeSelect>
                </FormControl>
              </div>
              <div className="Address-name" style={{ marginLeft: "-425px" }}>
                <p>Address:</p>
              </div>
              <div className="Address-textbox">
                <p>
                  <ul>
                    {empid && empid.map((item, index) => (
                      <li key={index}>
                        {item.Address.address1}, {item.Address.street}<br />
                        {item.Address.selectedDistrict}, {item.Address.selectedState}<br />
                        {item.Address.Pincode}
                      </li>
                    ))}
                  </ul>
                </p>
              </div>
              <div className="rolebutton">
                <div
                  className="rolecancel"
                  onClick={() => window.location.reload()}
                >
                  <Buttons Buttondata={Button} />
                </div>
                {confirm()}
              </div>
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>):(
        <div className="modals">
        <div className="overlays">
          <div className="modal-contents">
            <div className="edit-popupscreen">
              <div>
                <h1>Select Branch
                  <button
                    onClick={() => window.location.reload()}
                    className="cancel-icon-SelectBranch"
                  >
                    <CloseIcon />
                  </button>
                </h1>
              </div>
              <div>
                <div
                  style={{
                    display: "grid",
                    width: "350px",
                    alignItems: "center",
                    marginRight: "140px",
                    marginTop: "30px",
                  }}
                >
                  <div className="Role_select">
                    <p style={{ fontSize: "18px", marginRight: "5px" }}>
                      Role
                    </p>
                    <p style={{ color: "red" }}>
                      *
                    </p>
                  </div>
                  {selectworkers_Type()}
                </div>
              </div>
              <div
                style={{
                  width: "90px",
                  marginRight: "420px",
                  marginBottom: "20px",
                }}
              >
                <FormControl sx={{ m: 1 }} variant="standard">
                  <InputLabel htmlFor="demo-customized-select-native">
                    Branch<span style={{ color: '#f04141', fontSize: "15px" }}>*</span>
                  </InputLabel>
                  <NativeSelect
                    style={{ width: "690%" }}
                    id="demo-customized-select-native"
                    onChange={pinfilter}
                    input={<BootstrapInput />}
                  >
                    <option value={""}>Select branch</option>
                    {location.map((item) => (
                      <option key={item._id.$oid} value={item._id.$oid}>
                        {item.Branch_name}
                      </option>
                    ))}
                  </NativeSelect>
                </FormControl>
              </div>
              <div className="Address-name" style={{ marginLeft: "-425px" }}>
                <p>Address:</p>
              </div>
              <div className="Address-textbox">
                <p>
                  <ul>
                    {empid && empid.map((item, index) => (
                      <li key={index}>
                        {item.Address.address1}, {item.Address.street}<br />
                        {item.Address.selectedDistrict}, {item.Address.selectedState}<br />
                        {item.Address.Pincode}
                      </li>
                    ))}
                  </ul>
                </p>
              </div>
              <div className="rolebutton">
                <div
                  className="rolecancel"
                  onClick={() => window.location.reload()}
                >
                  <Buttons Buttondata={Button} />
                </div>
                {confirm()}
              </div>
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
      )}
    </div>
  );
};

export default RoleSelect;
