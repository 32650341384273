import React, { useState } from "react";
import cash from "../../../assets/Images/1635d86b32b60583e7f022b9c3324b3d.png";
// import cash from '../../../assets/Images/cash.png';
// import card from "../../assets/Images/797fc01d4f9a82a498c18bd80db5608e.png";
import late from "../../../assets/Images/5d48ac6d612c437a9078a93e10f21d9a.png";
// import late from "../../../assets/Images/persion-icon.png";
import "./Model.css";
import CloseIcon from "@mui/icons-material/Close";
// import Bom from "./Bom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "@mui/material";
import Paylate from "./Paylate";
import CardPay from "./Cardpay";
import PayCash from "./Paycash";
import Buttons from "../../common-component/Buttons";

interface Props {
  cartId: string | null;
  backHome: any;
  backHomepay: any;
}
const Payment: React.FC<Props> = ({ cartId, backHome, backHomepay }) => {
  const [openpayment, setOpenpayment] = useState<boolean>(false);
  const [cards, ] = useState<boolean>(false);
  const [cashpay, setCashpay] = useState<boolean>(false);
  const [paylaters, setPaylate] = useState<boolean>(false);
  // const [backpage, setBackpage] = useState<boolean>(false);
  const paylater = () => {
    setOpenpayment(!openpayment);
    setPaylate(!paylaters);
  };
  // const paycard = () => {
  //   setCard(!cards);
  //   setOpenpayment(!openpayment);
  // };
  const paycash = () => {
    setCashpay(!cashpay);
    setOpenpayment(!openpayment);
  };
  const Mbutton = {
    Blue: "blue",
    name: "Add Product",
  }

  return (
    <div>
        <div>
          {openpayment ? (
            <div>
              {cards ? <CardPay cartId={cartId} /> : ""}
              {cashpay ? <PayCash cartId={cartId} backHome={backHome} backHomepay={backHomepay} /> : ""}
              {paylaters ? <Paylate cartId={cartId} backHomepay={backHomepay} backHome={backHome} /> : ""}
            </div>
          ) : (
            <div className="paymentview1">
              <Button
                onClick={() => backHomepay('onlyfalse')}
                style={{ border: "none",color:"black" }}>
                <ArrowBackIcon style={{ cursor: "pointer",position:"relative",marginRight:"430px",right:"20px", marginBottom:"-20px"}} />
              </Button>
              <h1>Choose the payment method
                <button
                  onClick={() => window.location.reload()}
                  className="cancel-icon-payment"
                >
                  <CloseIcon style={{marginLeft:"-80px",position:"relative",top:"30px", }} />
                </button>
              </h1>
              <div onClick={() => backHome('false')}>
                    <Buttons Buttondata={Mbutton} />
                    </div>
              <div className="payview">
                <div className="paymentview" onClick={() => paylater()}>
                  <img src={late} className="paymentmode" id="buy_on_credit_payment" alt="" />
                  <p>Buy on credit</p>
                </div>
                {/* <div className="paymentview" onClick={() => paycard()}>
              <img src={card} className="paymentmode" id="credit_cart_payment" alt="" />
              <p>Credit card</p>
            </div> */}
                <div className="paymentview" onClick={() => paycash()}>
                  <img src={cash} className="paymentmode" id="cash_payment" alt="" style={{ marginRight: "8px" }} />
                  <p>Cash</p>
                </div>
              </div>
            </div>
          )}
        </div>
    </div>
  );
};

export default Payment;