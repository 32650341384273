import React, { useEffect, useRef, useState } from "react";
import "./Edit.css";
import { Flip, toast, ToastContainer } from "react-toastify";
import { Grid, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import api from "../../../axiosConfig";
import Buttons from "../../../common-component/Buttons";
import LoadingScreen from "../../../common-component/LoadingScreen";
// import LoadingScreen from "../../../common-component/LoadingScreen";

interface Props {
  id: string;
}

interface Branch {
  _id: { $oid: string };
  Branch_name: string;
  Branch_Opening_date: string;
  Address: {
    address1: string;
    street: string;
    selectedCountry: string;
    selectedState: string;
    selectedCity: string;
    Pincode: string;
  };
}

const EditBranch: React.FC<Props> = ({ id }) => {
  const [Loading, setLoading] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [selectedState, setSelectedState] = useState<string>('');
  const [selectedCity, setSelectedCity] = useState<string>('');
  const [editAddress1, setEditAddress1] = useState("");
  const [editStreet, setEditStreet] = useState("");
  const [editPincode, setEditPincode] = useState("");
  const [editBranchname, setEditBranchname] = useState("");
  const [getdata, setgetdata] = useState<Branch>();
  const [visible, setvisible] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [editbranchopeningdate, seteditBranchopeningdate] = useState("");
  const [inputValue, setInputValue] = useState<string>('');
  const [doberror, setdobError] = useState<string | null>(null);
  const streetnames = useRef<HTMLInputElement>(null);
  const pincodes = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState<string>("");
  const [modalOpen, setModalOpen] = useState<boolean>(true);
  const handleaddressline1KeyPress = (event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      streetnames.current?.focus();
    }
  }
  const handlestreetnamesKeyPress = (event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      pincodes.current?.focus();
    }
  }
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (getdata?.Address.address1 === editAddress1 &&
        getdata?.Address.street === editStreet &&
        getdata?.Address.Pincode === editPincode) {
        toast.error("No changes")
      } else {
        updateBranch();
      }
    }
  };

  useEffect(() => {
    api
      .get(`/app/v1/pos/branch/${id}`)
      .then((res) => {
        const branchData: Branch = res.data.result;
        console.log("branchData", branchData);

        setgetdata(branchData)
        seteditBranchopeningdate(branchData.Branch_Opening_date);
        setEditAddress1(branchData.Address.address1);
        setEditStreet(branchData.Address.street);
        setEditPincode(branchData.Address.Pincode);
        setEditBranchname(branchData.Branch_name);
        setSelectedState(branchData.Address.selectedState);
        setSelectedCountry(branchData.Address.selectedCountry);
        setSelectedCity(branchData.Address.selectedCity);
      })
  }, [id]);
  useEffect(() => {
    if (
      getdata?.Address.address1 !== editAddress1 ||
      getdata?.Address.street !== editStreet ||
      getdata?.Address.Pincode !== editPincode ||
      getdata?.Branch_Opening_date !== editbranchopeningdate
    ) {
      console.log("work");
      setvisible(true);
    }
    else if (
      getdata?.Address.address1 === editAddress1 &&
      getdata?.Address.street === editStreet &&
      getdata?.Address.Pincode === editPincode &&
      getdata?.Branch_Opening_date === editbranchopeningdate
    ) {
      console.log("working");
      setvisible(false);
    } 
  }, [
    editAddress1,
    editPincode,
    editStreet,
    editbranchopeningdate,
    getdata?.Address.Pincode,
    getdata?.Address.address1,
    getdata?.Address.street,
    getdata?.Branch_Opening_date
  ]);

  const handlePincodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let formattedPincode = e.target.value.replace(/\D/g, "");
    formattedPincode = formattedPincode.slice(0, 5); 
    setEditPincode(formattedPincode);
  
    if (formattedPincode.length < 5 && formattedPincode.length > 0) {
      setError("Please enter a valid ZIP or postal code");
    } else {
      setError("");
    }
  };

  const GButton = {
    Blue: "blue",
    name: "update",
  };
  const FButton = {
    Cancel: "red",
    name: "Cancel",
  };
  const KButtonerr = {
    error: "blue",
    name: "update",
  };
  const updateBranch = () => {
    if (
      editAddress1.length > 0 &&
      editStreet.length > 0 &&
      editbranchopeningdate 
    ) {
      toast.error("Please fill in all required fields.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Flip,
      });
    }
      const data = {
        Branch_name: editBranchname,
        Branch_Opening_date: editbranchopeningdate,
        Address: {
          address1: editAddress1,
          street: editStreet,
          Pincode: editPincode.length > 0 ? editPincode : undefined,
          // selectedCountry: selectedCountry,
          // selectedState: selectedState,
          // selectedCity: selectedCity,
        },
      };

      api.put(`/app/v1/pos/branch/${id}`, data)
        .then((res) => {
          console.log(res);
          setLoading(false);
        });
      toast.success("Branch Updated successfully", {
        position: "top-right",
        autoClose: 1500,
        theme: "colored",
        transition: Flip,
      });
       setModalOpen(false);
       setTimeout(() => {
        window.location.reload();
      });
  };

  const handleDobChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const isValid = /^[\d-]*$/.test(value);
    if (isValid) {
      const parts = value.split('-');
      if (parts.length === 3) {
        const day = parseInt(parts[0]);
        const month = parseInt(parts[1]);
        const year = parseInt(parts[2]);
        if (isValidDate(day, month, year)) {
          seteditBranchopeningdate(value);
          setdobError(null);
        } else {
          setdobError("Invalid date. Please enter a valid date in dd-mm-yyyy format.");
        }
      } else {
        seteditBranchopeningdate(value);
        setdobError(null);
      }
    } else {
      setdobError("Invalid characters. Only numbers and dashes are allowed.");
    }
  };
  const isValidDate = (day: number, month: number, year: number): boolean => {
    const isLeap = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
    const daysInMonth = [
      31, // January
      isLeap ? 29 : 28, // February
      31, // March
      30, // April
      31, // May
      30, // June
      31, // July
      31, // August
      30, // September
      31, // October
      30, // November
      31  // December
    ];
    return (
      month > 0 &&
      month <= 12 &&
      day > 0 &&
      day <= daysInMonth[month - 1]
    );
  };

  // const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { value } = e.target;
  //   const isValid = /^\d{2}-\d{2}-\d{4}$/.test(value); 
  //   if (isValid) {
  //     setBranchopeningdate(value);
  //     setErrors("");
  //   } else {
  //     setErrors("");
  //   }
  // };
  const update = () => {
    if (visible) {
      return (
        <div onClick={updateBranch}>
          <Buttons Buttondata={GButton} />
        </div>
      );
    } else {
      return (
        <div id="confirm_btn">
          <Buttons Buttondata={KButtonerr} />
        </div>
      );
    }
  };
  return (
    <div className="modals">
      <div className="overlays">
        <div style={{ marginTop: "30px" }} className="modal-contents">
          <div className="edit-Branch_popupscreen">
            <h2>Edit Branch
              <button
                onClick={() => window.location.reload()}
                className="cancel-icon-EditBranch"
              >
                <CloseIcon />
              </button>
            </h2>
            <div className="user_input" id="user_input">
              <div style={{ width: "540px" }} className="Address">
                <div className="edit-branch">
                  <TextField
                    className="Inputuser"
                    id="Branch"
                    fullWidth
                    label={
                      <span>
                        Branch name <span style={{ color: '#f04141', fontSize: "15px" }}>*</span>
                      </span>
                    }
                    variant="outlined"
                    value={editBranchname}
                    // onChange={(e) => setEditBranchname(e.target.value)}
                    disabled
                  />
                  <TextField
                    className="Inputuser"
                    id="Branch opening date"
                    fullWidth
                    label="Branch opening date (dd-mm-yyyy)"
                    variant="outlined"
                    value={editbranchopeningdate}
                    onChange={handleDobChange}
                    error={!!doberror}
                    helperText={doberror ? 'Invalid date format, Please enter in dd-mm-yyyy format.' : ''}
                    InputLabelProps={{ style: { backgroundColor: '#f1f1f1' } }}
                    disabled
                  />

                  <TextField
                    className="Inputuser"
                    id="address"
                    onKeyPress={handleaddressline1KeyPress}
                    fullWidth
                    label="Flat /House no. /Building /Company /Apartment"
                    variant="outlined"
                    value={editAddress1}
                    onChange={(e) => setEditAddress1(e.target.value)}
                  />
                  <TextField
                    className="Inputuser"
                    id="street"
                    onKeyPress={handlestreetnamesKeyPress}
                    fullWidth
                    label="Area /Street /Sector /Town"
                    variant="outlined"
                    value={editStreet}
                    onChange={(e) => setEditStreet(e.target.value)}
                    inputRef={streetnames}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        className="Inputuser"
                        id="country"
                        fullWidth
                        label="Country"
                        variant="outlined"
                        value={selectedCountry}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        className="Inputuser"
                        id="state"
                        fullWidth
                        label="State"
                        variant="outlined"
                        value={selectedState}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        className="Inputuser"
                        id="city"
                        fullWidth
                        label="City"
                        variant="outlined"
                        value={selectedCity}
                        // onChange={(e)=>setSelectedCity(e.target.value)}
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <TextField
                    className="Inputuser"
                    id="pincode"
                    onKeyPress={handleKeyPress}
                    fullWidth
                    label="Postal code"
                    margin="normal"
                    type="text"
                    error={!!error}
                    helperText={error}
                    inputProps={{ maxLength: 7 }}
                    value={editPincode}
                    onChange={handlePincodeChange}
                    inputRef={pincodes}
                  />
                </div>
                <div className="confirm_btn_edit" style={{ marginLeft: "60px" }}>
                  <div onClick={() => window.location.reload()}>
                    <Buttons Buttondata={FButton} />
                  </div>
                  {update()}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        {Loading ? <LoadingScreen /> : ""}
      </div>
    </div>
  );
};

export default EditBranch;

