import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { List, ListItem, ListItemIcon, ListItemText, Box, Divider, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import EventNoteIcon from '@mui/icons-material/EventNote';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import LogoutIcon from '@mui/icons-material/Logout';
import './Carmenu.css';
import api from '../../axiosConfig';

const CarMenuBox: React.FC = () => {
  const navigate = useNavigate();
  const CarmenuItems = [
    { path: "/v1/new-order", icon: <HomeIcon />,},
    // { path: "/v1/new-order", icon: <EventNoteIcon />,},
    { path: "/v1/order-list", icon: <FormatListNumberedIcon />, },
    // { path: "/v1/car-status-list", icon: <Brightness7Icon />, },
    { path: "/v1/car-setting/car-products", icon: <SettingsIcon />,  },
    { path: "/v1/car-profile", icon: <PersonIcon />,},
  ];
const Logout = () => {
  api.get('/pos/logout').then(() => {
    sessionStorage.clear();
    navigate('/');
  })
};

  return (
    <Box className="carmenumain-container">
    <List className="carmenu-list" >
      {CarmenuItems.map((item, index) => (
        <Link to={item.path} key={index} className="menu-link">
          <ListItem  className="carmenu-item">
            <ListItemIcon className="carmenu-icon">{item.icon}</ListItemIcon>
            {/* <ListItemText primary={item.label} /> */}
          </ListItem>
        </Link>
      ))}
    </List>
    <Divider className="divider" />
    <Link to="/logout" className="menu-link logout-section">
      <ListItem button className="logout-button">
        <ListItemIcon className="logout-icon">
          <LogoutIcon onClick={Logout} />
        </ListItemIcon>
      </ListItem>
    </Link>
  </Box>
  );
};

export default CarMenuBox;
