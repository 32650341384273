import React, { useState } from "react";
import {
  CardElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
interface Props {
  cartId: string | null;
}
// interface Product {
//   _id: {
//     $oid: string;
//   };
//   products: Array<{
//     amount: string;
//     productId: string;
//     productName: string;
//     quantity: string;
//     totalPrice: string;
//   }>;
//   totalAmount: string;
//   vat: string;
//   Name: string;
//   phone_number: string;
//   Email: string;
//   Address: string;
// }
const CardPay: React.FC<Props> = ({ cartId }) => {
  // const [bproducts, setBproducts] = useState<Product[]>([]);
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const handleSubmit = async (event: React.FocusEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setLoading(true);
    const cardElement = elements.getElement(CardElement);
    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement!,
      });
      if (error) {
        throw new Error(error.message);
      }
      console.log("PaymentMethod", paymentMethod);
    } catch (error) {
      setError(
        (error as Error).message ||
        "An error occurred while processing your payment."
      );
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <button type="submit" disabled={!stripe || loading}>
        Pay
      </button>
      {error && <div>{error}</div>}
    </form>
  );
};

const CardpayWrapper: React.FC<Props> = (props) => {
  return (
    <Elements stripe={null}>
      <CardPay {...props} />
    </Elements>
  );
};

export default CardPay;
